/*
 * Created by Sina Khelil on 25/05/18.
 */

import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import numeral from 'numeral'
import Select from 'react-select'
import BSField from '../shared_components/bs_field'
import RouteButton from '../shared_components/route_button'
import { Table, Switch } from 'antd'
import moment from 'moment'
import '../shared_components/shared.css'
import { useParams } from 'react-router-dom'

const valSchema = yup.object().shape({
    initDeathBenefit: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .required('This field is required'),
    premiumCharge: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .required('This field is required'),
    incomeAmount: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .required('This field is required'),
    incomeYearStart: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .max(100)
        .required('This field is required'),
    startAge: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .max(100)
        .required('This field is required'),
    startYear: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .max(3000)
        .required('This field is required'),
    qualified: yup.mixed().oneOf([true, false]),
    loanRate: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .max(100)
        .required('This field is required'),
    taxRate: yup
        .number()
        .typeError('Must be a number')
        .min(0)
        .max(100)
        .required('This field is required'),
    ml: yup.mixed().oneOf([true, false]),
})

const columns = [
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        align: 'right',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        align: 'right',
    },
    {
        title: 'Index',
        dataIndex: 'index',
        key: 'index',
        render: (index) => numeral(index).format('0.00'),
        align: 'right',
    },
    {
        title: 'Annual Premium',
        dataIndex: 'premium',
        key: 'premium',
        render: (premium) => numeral(premium).format('0,0'),
        align: 'right',
    },
    {
        title: 'Premium Charge',
        dataIndex: 'premiumCharge',
        key: 'premiumCharge',
        render: (premiumCharge) => numeral(premiumCharge).format('0,0'),
        align: 'right',
    },
    {
        title: 'COI',
        dataIndex: 'coi',
        key: 'coi',
        render: (coi) => numeral(coi).format('0,0'),
        align: 'right',
    },
    {
        title: 'PIC',
        dataIndex: 'pic',
        key: 'pic',
        render: (pic) => numeral(pic).format('0,0'),
        align: 'right',
    },
    {
        title: 'Admin',
        dataIndex: 'admin',
        key: 'admin',
        render: (admin) => numeral(admin).format('0,0'),
        align: 'right',
    },
    {
        title: 'Remaining Value',
        dataIndex: 'remainingValue',
        key: 'remainingValue',
        render: (remainingValue) => numeral(remainingValue).format('0,0'),
        align: 'right',
    },
    {
        title: 'Interest Credited',
        dataIndex: 'interestEarned',
        key: 'interestEarned',
        render: (interestEarned) => numeral(interestEarned).format('0,0'),
        align: 'right',
    },
    {
        title: 'Bonus Interest Credit',
        dataIndex: 'bonusInterestCredit',
        key: 'bonusInterestCredit',
        render: (bonusInterestCredit) =>
            numeral(bonusInterestCredit).format('0,0'),
        align: 'right',
    },
    {
        title: 'Annual Policy Credit',
        dataIndex: 'reimbursement',
        key: 'reimbursement',
        render: (reimbursement) => numeral(reimbursement).format('0,0'),
        align: 'right',
    },
    {
        title: 'Accumulation Value',
        dataIndex: 'taxFree',
        key: 'taxFree',
        render: (taxFree) => numeral(taxFree).format('0,0'),
        align: 'right',
    },
    {
        title: 'Loan',
        dataIndex: 'loan',
        key: 'loan',
        render: (loan) => numeral(loan).format('0,0'),
        align: 'right',
    },
    {
        title: 'Loan Interest',
        dataIndex: 'loanInterest',
        key: 'loanInterest',
        render: (loanInterest) => numeral(loanInterest).format('0,0'),
        align: 'right',
    },
    {
        title: 'Total Loan',
        dataIndex: 'loanBalance',
        key: 'loanBalance',
        render: (loanBalance) => numeral(loanBalance).format('0,0'),
        align: 'right',
    },
    {
        title: 'Surrender Value',
        dataIndex: 'surrenderValue',
        key: 'surrenderValue',
        render: (surrenderValue) => numeral(surrenderValue).format('0,0'),
        align: 'right',
    },
    {
        title: 'Death Benefit',
        dataIndex: 'deathBenefit',
        key: 'deathBenefit',
        render: (deathBenefit) => numeral(deathBenefit).format('0,0'),
        align: 'right',
    },
]

const IllustrationForm = (props) => {
    const { data, indexes, submitHandler, loading, clients } = props
    const { illustrationId } = useParams()

    if (loading || clients.loading || indexes.loading) {
        return <div>Loading...</div>
    }
    const clientOptions = clients.data.map((client) => ({
        value: client.id,
        label: `${client.fullName} - ${client.agencyName} - ${client.agentName}`,
    }))
    const selectedClient = data.customer
        ? clientOptions.find((item) => item.value === data.customer.id)
        : undefined
    const display_data = data.lines
        ? data.lines.filter((item) => item.age <= 100)
        : []

    return (
        <div>
            <Formik
                validationSchema={valSchema}
                isInitialValid={() =>
                    valSchema.isValid({
                        ...data,
                    })
                }
                initialValues={{
                    selectedClient: selectedClient || undefined,
                    initDeathBenefit: data.initDeathBenefit || undefined,
                    qualified: data.qualified || undefined,
                    marketIndex: data.marketIndex || 1,
                    addlY1Premium: data.addlY1Premium || 0,
                    premiumCharge: data.premiumCharge || 0,
                    incomeYearStart: data.incomeYearStart || 65,
                    startAge: data.startAge || 60,
                    startYear: data.startYear || moment.year(),
                    incomeAmount: data.incomeAmount || 0,
                    loanRate: data.loanRate || 0,
                    taxRate: data.taxRate || 0,
                    ml: data.ml || false,
                }}
                onSubmit={(values) => {
                    submitHandler(values)
                }}
            >
                {({ values, touched, errors, isValid, setFieldValue }) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-4">
                                <BSField
                                    touched={touched.selectedClient}
                                    error={errors.selectedClient}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="client"
                                    >
                                        Client
                                    </label>
                                    <Select
                                        name="client"
                                        value={values.selectedClient}
                                        options={clientOptions}
                                        onChange={(selected) => {
                                            setFieldValue(
                                                'selectedClient',
                                                selected
                                            )
                                        }}
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.marketIndex}
                                    error={errors.marketIndex}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="marketIndex"
                                    >
                                        Market Index
                                    </label>
                                    <Field
                                        name="marketIndex"
                                        className="form-control"
                                        component="select"
                                    >
                                        {!values.marketIndex && (
                                            <option key={0} value="0">
                                                ---
                                            </option>
                                        )}
                                        {indexes.data.map((item, idx) => (
                                            <option key={idx} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Field>
                                </BSField>
                                <BSField
                                    touched={touched.initDeathBenefit}
                                    error={errors.initDeathBenefit}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="initDeathBenefit"
                                    >
                                        Initial Death Benefit
                                    </label>
                                    <Field
                                        name="initDeathBenefit"
                                        className="form-control"
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.qualified}
                                    error={errors.qualified}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="qualified"
                                    >
                                        Is Qualified?
                                    </label>
                                    <Field
                                        name="qualified"
                                        className="form-control"
                                    >
                                        {({ field }) => (
                                            <div>
                                                <Switch
                                                    checked={field.value}
                                                    onChange={(checked) => {
                                                        setFieldValue(
                                                            'qualified',
                                                            checked
                                                        )
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </BSField>
                            </div>
                            <div className="col-md-4">
                                <BSField
                                    touched={touched.addlY1Premium}
                                    error={errors.addlY1Premium}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="addlY1Premium"
                                    >
                                        Year 1 Additional Premium
                                    </label>
                                    <Field
                                        name="addlY1Premium"
                                        className="form-control"
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.premiumCharge}
                                    error={errors.premiumCharge}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="premiumCharge"
                                    >
                                        Premium Charge
                                    </label>
                                    <Field
                                        name="premiumCharge"
                                        className="form-control"
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.incomeYearStart}
                                    error={errors.incomeYearStart}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="incomeYearStart"
                                    >
                                        Income Start Age
                                    </label>
                                    <Field
                                        name="incomeYearStart"
                                        className="form-control"
                                    />
                                </BSField>
                            </div>
                            <div className="col-md-4">
                                <BSField
                                    touched={touched.incomeAmount}
                                    error={errors.incomeAmount}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="incomeAmount"
                                    >
                                        Income Amount
                                    </label>
                                    <Field
                                        name="incomeAmount"
                                        className="form-control"
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.loanRate}
                                    error={errors.loanRate}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="loanRate"
                                    >
                                        Loan Rate %
                                    </label>
                                    <Field
                                        name="loanRate"
                                        className="form-control"
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.taxRate}
                                    error={errors.taxRate}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="taxRate"
                                    >
                                        Tax Rate %
                                    </label>
                                    <Field
                                        name="taxRate"
                                        className="form-control"
                                    />
                                </BSField>
                                <BSField touched={touched.ml} error={errors.ml}>
                                    <label
                                        className="control-label"
                                        htmlFor="ml"
                                    >
                                        ML
                                    </label>
                                    <Field name="ml" className="form-control">
                                        {({ field, form }) => (
                                            <div>
                                                <Switch
                                                    checked={field.value}
                                                    onChange={(checked) => {
                                                        setFieldValue(
                                                            'ml',
                                                            checked
                                                        )
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </BSField>
                            </div>
                            <div className="col-md-12 form-group">
                                <button
                                    type="submit"
                                    className="btn btn-primary pull-right"
                                    disabled={!isValid}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <RouteButton
                path={`/illustrations/edit/${illustrationId}`}
                name="Edit Parsed File"
                className="btn btn-info btn-xs pull-right"
            />
            <br />
            <br />
            <br />
            <Table
                title={() => (
                    <label className="control-label">Illustration Data</label>
                )}
                size="small"
                bordered={false}
                rowClassName={(record, index) => {
                    return index % 2 === 1 ? 'table-alt-row' : ''
                }}
                pagination={false}
                columns={columns}
                dataSource={display_data}
            />
        </div>
    )
}

export default IllustrationForm
