import { Card, Col, Menu, Row } from 'antd'
import React, { useState } from 'react'
import { IncomeCompModel, TaxCalcStatus } from '../..'

import {
    ICEstateTax,
    ICInheritedIra,
    ICInputValues,
    ICRmdComp,
    ICStretchIra,
    ICTaxCalc,
} from '../ic_components'

interface IncomeCompViewProps extends IncomeCompModel {
    updateHandler: Function
    taxCalcStatus: TaxCalcStatus
    updateTaxCalcHandler: Function
    downloadPDF: Function
}

const IncomeCompView = (props: IncomeCompViewProps) => {
    const {
        data,
        loading,
        taxCalcStatus,
        updateHandler,
        updateTaxCalcHandler,
        downloadPDF,
    } = props
    const [selectedKey, setSelectedKey] = useState('inputValues')

    return (
        <div>
            <Card title="Income Tax Comparison">
                <Row>
                    <Col span={6}>
                        <div>
                            {data.customer!.name} - {data.customer!.age}
                        </div>
                    </Col>
                    <Col span={3} offset={1}></Col>
                    <Col span={2}>
                        <button
                            className="btn btn-default btn-sm"
                            onClick={() => downloadPDF(data.customer!.name)}
                            title="Download PDF"
                            disabled={loading}
                        >
                            <i className="fa fa-file-pdf-o" /> Download PDF
                        </button>
                    </Col>
                </Row>
            </Card>
            <Menu
                onClick={(e) => setSelectedKey(e.key)}
                selectedKeys={[selectedKey]}
                mode="horizontal"
            >
                <Menu.Item key="inputValues">Input Values</Menu.Item>
                <Menu.Item key="taxCalc">Tax Calculator</Menu.Item>
                <Menu.Item key="rmdComp">RMD Comparison</Menu.Item>
                <Menu.Item key="estateTax">Estate Tax Analysis</Menu.Item>
                <Menu.Item key="inheritedIRA">Inherited IRA</Menu.Item>
                <Menu.Item key="stretchIRA">Stretch IRA Comparison</Menu.Item>
            </Menu>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {selectedKey === 'inputValues' && (
                    <ICInputValues
                        data={data}
                        loading={loading}
                        updateHandler={updateHandler}
                    />
                )}
                {selectedKey === 'taxCalc' && (
                    <ICTaxCalc
                        data={data}
                        taxCalcStatus={taxCalcStatus}
                        updateTaxCalcHandler={updateTaxCalcHandler}
                    />
                )}
                {selectedKey === 'rmdComp' && <ICRmdComp data={data} />}
                {selectedKey === 'estateTax' && <ICEstateTax data={data} />}
                {selectedKey === 'inheritedIRA' && (
                    <ICInheritedIra data={data} />
                )}
                {selectedKey === 'stretchIRA' && <ICStretchIra data={data} />}
            </div>
        </div>
    )
}

export default IncomeCompView
