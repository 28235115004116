import { Modal } from 'antd'
import React from 'react'
import { BucketDetailType, BucketType } from '../../../../index.d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import NumberInput from '../../../../components/shared_components/numberinput'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { bucketService } from '../../../../services/bucket.service'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { debounce } from 'lodash'

interface DeleteConfirmProps {
    bucketSegmentId: number
    deleteAction: () => void
}

const DeleteConfirm = (props: DeleteConfirmProps) => {
    const { bucketSegmentId, deleteAction } = props

    const baseYear = moment().year()

    const confirm = () => {
        deleteAction()
    }

    const modalConfig = {
        closable: true,
        title: 'Confirm Delete',
        onOk: confirm,
        centered: true,
        okText: 'Delete',
        content: (
            <span>
                Are you sure you want to delete <b>Bucket {bucketSegmentId}</b>?{' '}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Once deleted, it cannot be recovered.
                </span>
            </span>
        ),
    }

    return (
        <i onClick={() => Modal.confirm(modalConfig)} className="bucket-delete">
            <FontAwesomeIcon icon={faTimesCircle} />
        </i>
    )
}

interface BucketProps {
    bucket: BucketDetailType
    bucketNumber: number
    bucketCount: number
}

const Bucket = (props: BucketProps) => {
    const queryClient = useQueryClient()
    const { bucket, bucketNumber, bucketCount } = props

    const deleteBucketDetail = useMutation(
        (bucketDetailId: number | string) =>
            bucketService.deleteBucketDetail(
                bucket.bucketProposal,
                bucketDetailId
            ),
        {
            onError: (error: AxiosError) => {
                toast.error(`An error occured while deleting: ${error.message}`)
            },
            onSettled: () => {
                queryClient.invalidateQueries([
                    'buckets',
                    bucket.bucketProposal.toString(),
                    'details',
                ])
            },
        }
    )

    const updateBucketDetail = useMutation(
        (bucketValues: BucketDetailType) =>
            bucketService.updateBucketDetail(
                bucket.bucketProposal,
                bucketValues.id,
                bucketValues
            ),
        {
            onError: (error: AxiosError) => {
                toast.error(`An error occured while saving: ${error.message}`)
            },
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'buckets',
                    bucket.bucketProposal.toString(),
                    'details',
                ])
            },
        }
    )

    const baseYear = moment().year()

    const bucketSchema = Yup.object().shape({
        duration: Yup.number()
            .min(0, 'Minimum ${min}')
            .integer('Whole numbers only')
            .required('Required'),
        startYear: Yup.number()
            .integer('Whole numbers only')
            .min(baseYear, 'Minimum ${min}')
            .max(baseYear + 80, 'Maximum ${max}')
            .required('Required'),
        deferral: Yup.number()
            .min(0, 'Minimum ${min}')
            .max(80, 'Maximum ${max}')
            .integer('Whole numbers only')
            .required('Required'),
        monthlyIncome: Yup.number()
            .min(0, 'Minimum ${min}')
            .integer('Whole numbers only')
            .required('Required'),
        inflationAdjusted: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        bonus: Yup.number()
            .min(0, 'Minimum ${min}')
            .max(100, 'Maximum ${max}')
            .required('Required'),
        rate: Yup.number()
            .min(0, 'Minimum ${min}')
            .max(100, 'Maximum ${max}')
            .required('Required'),
        withdrawRate: Yup.number()
            .min(0, 'Minimum ${min}')
            .max(100, 'Maximum ${max}')
            .required('Required'),
        startingValue: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
    })

    return (
        <div className="bucket-values">
            <div className="bucket-name">
                <span className="bucket-name-text">Bucket {bucketNumber}</span>
                {bucketNumber === bucketCount && bucketCount > 1 && (
                    <DeleteConfirm
                        bucketSegmentId={bucketNumber}
                        deleteAction={() =>
                            deleteBucketDetail.mutate(bucket.id)
                        }
                    />
                )}
            </div>
            <Formik
                initialValues={bucket}
                validationSchema={bucketSchema}
                onSubmit={(values) => {
                    debounce(() => updateBucketDetail.mutate(values), 1000)()
                }}
                enableReinitialize
            >
                {({ values, setFieldValue, isValid, errors, handleSubmit }) => {
                    return (
                        <Form>
                            <div>
                                <NumberInput
                                    className="form-control input-sm"
                                    value={values.duration}
                                    error={errors.duration}
                                    id="duration"
                                    name="duration"
                                    min={0}
                                    format="0"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            'duration',
                                            parseInt(event.target.value)
                                        )
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className="form-control input-sm"
                                    value={values.startYear}
                                    id="startYear"
                                    name="startYear"
                                    format="0000"
                                    error={errors.startYear}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const startYear = parseInt(
                                            event.target.value
                                        )
                                        debounce(
                                            () =>
                                                setFieldValue(
                                                    'startYear',
                                                    startYear
                                                ),
                                            500
                                        )()
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className={`form-control input-sm ${
                                        errors.deferral ? 'is-invalid' : ''
                                    }`}
                                    value={values.deferral}
                                    id="deferral"
                                    name="deferral"
                                    format="0"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const deferral = parseInt(
                                            event.target.value
                                        )
                                        debounce(
                                            () =>
                                                setFieldValue(
                                                    'deferral',
                                                    deferral
                                                ),
                                            500
                                        )()
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className={`form-control input-sm ${
                                        errors.monthlyIncome ? 'is-invalid' : ''
                                    }`}
                                    value={values.monthlyIncome}
                                    id="monthlyIncome"
                                    name="monthlyIncome"
                                    format="$0,0"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            'monthlyIncome',
                                            parseInt(event.target.value)
                                        )
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className={`form-control input-sm ${
                                        errors.inflationAdjusted
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    value={values.inflationAdjusted}
                                    id="inflationAdjusted"
                                    name="inflationAdjusted"
                                    format="$0,0"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            'inflationAdjusted',
                                            parseInt(event.target.value)
                                        )
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className={`form-control input-sm ${
                                        errors.bonus ? 'is-invalid' : ''
                                    }`}
                                    value={bucket.bonus}
                                    id="bonus"
                                    name="bonus"
                                    format="0.000"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            'bonus',
                                            parseFloat(event.target.value)
                                        )
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className={`form-control input-sm ${
                                        errors.rate ? 'is-invalid' : ''
                                    }`}
                                    value={
                                        values.deferral === 0 ? 0 : values.rate
                                    }
                                    id="rate"
                                    name="rate"
                                    format="0.000"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            'rate',
                                            parseFloat(event.target.value)
                                        )
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className={`form-control input-sm ${
                                        errors.withdrawRate ? 'is-invalid' : ''
                                    }`}
                                    value={values.withdrawRate}
                                    id="withdrawRate"
                                    name="withdrawRate"
                                    format="0.000"
                                    error={errors.withdrawRate}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            'withdrawRate',
                                            parseFloat(event.target.value)
                                        )
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                                <NumberInput
                                    className={`form-control input-sm ${
                                        errors.startingValue ? 'is-invalid' : ''
                                    }`}
                                    value={values.startingValue}
                                    id="startingValue"
                                    name="startingValue"
                                    format="$0,0"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            'startingValue',
                                            parseInt(event.target.value)
                                        )
                                    }}
                                    onBlur={() => {
                                        if (isValid) {
                                            handleSubmit()
                                        }
                                    }}
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

interface BucketBucketsProps {
    bucket: BucketType
}

const BucketBuckets = (props: BucketBucketsProps) => {
    const queryClient = useQueryClient()
    const { bucket } = props

    const bucketDetails = useQuery<BucketDetailType[] | undefined, AxiosError>(
        ['buckets', bucket.id.toString(), 'details'],
        () => bucketService.getBucketDetails(bucket.id.toString()),
        {
            onError: (error: AxiosError) => {
                toast.error(
                    `An error occured while loading bucket details: ${error.message}`
                )
            },
        }
    )

    const createBucketDetail = useMutation(
        (bucketValues: BucketDetailType) =>
            bucketService.createBucketDetail(bucket.id, bucketValues),
        {
            onError: (error: AxiosError) => {
                toast.error(`An error occured while saving: ${error.message}`)
            },
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'buckets',
                    bucket.id.toString(),
                    'details',
                ])
            },
        }
    )

    if (!bucketDetails.data) {
        return null
    }

    const bucketDetailCount = bucketDetails.data.length

    return (
        <>
            <div className="bucket-labels">
                <p className="bucket-label">Duration Years</p>
                <p className="bucket-label">Start Year</p>
                <p className="bucket-label">Deferral Years</p>
                <p className="bucket-label">Monthly Income</p>
                <p className="bucket-label">Inflation Adjusted</p>
                <p className="bucket-label">Bonus %</p>
                <p className="bucket-label">Rate %</p>
                <p className="bucket-label">Withdraw Rate %</p>
                <p className="bucket-label">Starting Value</p>
            </div>
            <div className="side-scroller">
                {bucketDetails.data.map((bucketDetail, index) => {
                    return (
                        <Bucket
                            bucket={bucketDetail}
                            key={index}
                            bucketNumber={index + 1}
                            bucketCount={bucketDetailCount}
                        />
                    )
                })}
                {bucketDetailCount < 10 && (
                    <div className="bucket-values-add">
                        <button
                            className="bucket-add-segment-button"
                            disabled={
                                createBucketDetail.isLoading ||
                                !bucketDetails.data
                            }
                            onClick={() => {
                                if (bucketDetails.data) {
                                    createBucketDetail.mutate(
                                        {} as BucketDetailType
                                    )
                                }
                            }}
                            title="Add Segment"
                        >
                            <FontAwesomeIcon icon={faPlusSquare} size="4x" />
                            <p>Add Segment</p>
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

BucketBuckets.displayName = 'BucketBuckets'

export default BucketBuckets
