import React from 'react'

const ArrowLine = (props) => {
    const {width, color} = props

    return (
    <svg width={width} height="20px">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0,10 10,0 10,20" style={{fill: color || '#2f5597'}} />
            <line x1="10" y1="10" x2={width - 10} y2="10" style={{stroke: color || '#2f5597', strokeWidth: '10px'}} />
            <polygon points={`${width},10 ${width - 10},20 ${width - 10},0`} style={{fill: color || '#2f5597'}} />
        </g>
    </svg>
    )
}

export default ArrowLine
