import { Card, Col, Row } from "antd";
import numeral from 'numeral';
import React, { Component } from "react";

class MaxAnnualPremium extends Component {
  render() {
    return (
      <Card
        bordered={false}
        title="Maximum Renewal Premium"
      >
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12} style={{ textAlign: 'center' }}>
            % of Liquid Net Worth Based on age
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            {this.props.factor ? this.props.factor + '%' : '...'}
          </Col>
        </Row>
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12} style={{ textAlign: 'center' }}>
            <b>Maximum Annual Premium from Income</b>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <b>{numeral(this.props.incomeSource).format("$ 0,0") || '...'}</b>
          </Col>
        </Row>
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12} style={{ textAlign: 'center' }}>
            <b>Total Aggregate Premium from Liquid Net Worth</b>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <b>{numeral(this.props.totalLiquidNetWorth * (this.props.factor / 100)).format("$ 0,0") || '...'}</b>
          </Col>
        </Row>
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12} style={{ textAlign: 'center' }}>
            <b>Maximum Annual Premium from Liquid Net Worth</b>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <b>{numeral(this.props.incomeLiquid).format("$ 0,0") || '...'}</b>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default MaxAnnualPremium;
