import React from 'react'
import { Table, Form, InputNumber, Row, Col, Divider } from 'antd'
import numeral from 'numeral'

const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

class EditableCell extends React.Component {
    state = {
        editing: true,
    }

    toggleEdit = () => {
        // const editing = !this.state.editing;
        // this.setState({ editing }, () => {
        //   if (editing) {
        //     this.input.focus();
        //   }
        // });
    }

    save = (e) => {
        const { record, handleSave } = this.props
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return
            }
            this.toggleEdit()
            handleSave({ ...record, ...values })
        })
    }

    renderCell = (form) => {
        this.form = form
        const { children, dataIndex, record, title } = this.props
        const { editing } = this.state
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(
                    <InputNumber
                        style={{ width: 140 }}
                        ref={(node) => (this.input = node)}
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onPressEnter={this.save}
                        onBlur={this.save}
                    />
                )}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 10 }}
                onClick={this.toggleEdit}
            >
                {children}
            </div>
        )
    }

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>
                        {this.renderCell}
                    </EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        )
    }
}

class NetWorthCalc extends React.Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                title: 'Net Worth Breakdown',
                dataIndex: 'name',
            },
            {
                title: 'Value',
                dataIndex: 'amount',
                align: 'right',
                editable: true,
                render: (amount) =>
                    `$ ${amount.toFixed(0)}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                    ),
            },
        ]

        this.state = {
            dataSource: [
                {
                    key: '0',
                    name: 'Savings / Cash',
                    amount: 0,
                    liquid: true,
                    editable: true,
                    delete: false,
                },
                {
                    key: '1',
                    name: 'Real Estate (Residence - Non Liquid)',
                    amount: 0,
                    liquid: false,
                    editable: false,
                    delete: false,
                },
                {
                    key: '2',
                    name: 'Investment Real Estate - Non Liquid',
                    amount: 0,
                    liquid: false,
                    editable: false,
                    delete: false,
                },
                {
                    key: '3',
                    name: 'Investments (Stocks & Bonds, Mutual Funds, Annuities, etc.)',
                    amount: 0,
                    liquid: true,
                    editable: true,
                    delete: false,
                },
                {
                    key: '4',
                    name: 'Retirement Funds - Liquid over age 59.5 (Annuities )',
                    amount: 0,
                    liquid: true,
                    editable: true,
                    delete: false,
                },
                {
                    key: '5',
                    name: 'Other Liquid',
                    amount: 0,
                    liquid: true,
                    editable: false,
                    delete: false,
                },
                {
                    key: '6',
                    name: 'Non Liquid Personal Assets (Vehicles, Antiques, Jewelry etc..)',
                    amount: 0,
                    liquid: false,
                    editable: false,
                    delete: false,
                },
            ],
            newName: '',
            newAmount: 0,
            count: 5,
        }
    }

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource]
        const newDataSource = dataSource.filter((item) => item.key !== key)
        this.setState({ dataSource: newDataSource })
        this.findTotalNetWorth(newDataSource)
    }

    handleAdd = () => {
        if (this.state.newName.length === 0) {
            return
        }
        const { count, dataSource } = this.state
        const newData = {
            key: count,
            name: this.state.newName,
            amount: this.state.newAmount,
            liquid: true,
            editable: true,
            delete: true,
        }

        const newDataSource = [...dataSource, newData]
        this.setState({
            dataSource: newDataSource,
            count: count + 1,
            newName: '',
            newAmount: 0,
        })
        this.findTotalNetWorth(newDataSource)
    }

    handleSave = (row) => {
        const newData = [...this.state.dataSource]
        const index = newData.findIndex((item) => row.key === item.key)
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row,
        })
        this.setState({ dataSource: newData })
        this.findTotalNetWorth(newData)
    }

    findTotalNetWorth = (newDataSource) => {
        let totalNetWorth = 0
        let totalLiquidNetWorth = 0
        newDataSource.forEach((s) => {
            totalNetWorth += s.amount
            if (s.liquid) {
                totalLiquidNetWorth += s.amount
            }
        })

        this.props.totalNetWorthChanges(totalNetWorth, totalLiquidNetWorth)
    }

    render() {
        const { dataSource } = this.state
        const sum = dataSource.reduce((prev, next) => prev + next.amount, 0)

        let totalLiquidNetWorth = 0
        dataSource.forEach((s) => {
            if (s.liquid) {
                totalLiquidNetWorth += s.amount
            }
        })

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        }

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            }
        })

        return (
            <div>
                <Divider
                    style={{
                        backgroundColor: '#000000',
                        height: 3,
                        margin: 0,
                        padding: 0,
                    }}
                />

                <Table
                    className="table-condensed"
                    size="middle"
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered={false}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    // footer={() => {
                    //   return (
                    //   )
                    // }}
                />
                <div>
                    <Divider
                        style={{
                            backgroundColor: '#000000',
                            height: 1,
                            margin: 0,
                            padding: 0,
                        }}
                    />

                    <Row
                        style={{
                            height: 40,
                            paddingTop: 10,
                            paddingLeft: 5,
                            paddingRight: 18,
                        }}
                    >
                        <Col span={12}>
                            <strong>Total Net Worth:</strong>
                        </Col>
                        <Col
                            span={12}
                            style={{ textAlign: 'right', paddingRight: 2 }}
                        >
                            <strong>
                                {numeral(sum).format('$ 0,0') || '...'}
                            </strong>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: 40,
                            paddingTop: 10,
                            paddingLeft: 5,
                            paddingRight: 18,
                        }}
                    >
                        <Col span={12}>
                            <strong>Total Liquid Assets:</strong>
                        </Col>
                        <Col
                            span={12}
                            style={{ textAlign: 'right', paddingRight: 2 }}
                        >
                            <strong>
                                {numeral(totalLiquidNetWorth).format('$ 0,0') ||
                                    '...'}
                            </strong>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default NetWorthCalc
