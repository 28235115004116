import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { Table, Divider, Tag } from 'antd'
import { Link } from 'react-router-dom'

const ListIllustrations = (props) => {
    const { data, loading, page, count, pageChangeHandler } = props
    const columns = [
        {
            title: 'Date',
            key: 'proposal_date',
            dataIndex: 'proposalDate',
            align: 'right',
            maxWidth: 90,
            render: (proposal_date) =>
                moment.utc(proposal_date).format('MM/DD/YYYY'),
        },
        {
            title: 'Customer',
            dataIndex: 'customer.fullName',
            align: 'right',
            key: 'customer__last_name',
        },
        {
            title: 'Age',
            dataIndex: 'startAge',
            align: 'right',
            key: 'start_age',
            className: 'text-right',
            maxWidth: 45,
        },
        {
            title: 'Premium',
            key: 'premium',
            dataIndex: 'premium',
            align: 'right',
            className: 'text-right',
            maxWidth: 80,
            render: (premium) => numeral(premium).format('0,000'),
        },
        {
            title: 'Start',
            dataIndex: 'incomeYearStart',
            align: 'right',
            key: 'income_year_start',
            className: 'text-right',
            maxWidth: 45,
        },
        {
            title: 'Income',
            dataIndex: 'incomeAmount',
            align: 'right',
            className: 'text-right',
            maxWidth: 80,
            key: 'income_amount',
            render: (income_amount) => numeral(income_amount).format('0,000'),
        },
        {
            title: 'Qualified',
            key: 'qualified',
            dataIndex: 'qualified',
            align: 'right',
            sortabble: false,
            maxWidth: 50,
            render: (qualified) => (
                <span>
                    <Tag color={qualified ? 'green' : 'red'} key={qualified}>
                        {qualified ? 'Yes' : 'No'}
                    </Tag>
                </span>
            ),
        },
        {
            title: 'Tax',
            key: 'tax_rate',
            dataIndex: 'taxRate',
            align: 'right',
            sortabble: false,
            className: 'text-right',
            maxWidth: 50,
            render: (tax_rate) => numeral(tax_rate / 100).format('0%'),
        },
        {
            title: 'Last Modified',
            key: 'modified_on',
            dataIndex: 'modifiedOn',
            align: 'right',
            render: (modified_on) =>
                moment.utc(modified_on).format('MM/DD/YYYY LT'),
        },
        {
            title: 'Action',
            key: 'original',
            render: (original, record) => (
                <span>
                    <Link to={'/illustrations/' + original.id}>View</Link>
                    <Divider type="vertical" />
                    <Link to={'/illustrations/edit/' + original.id}>Edit</Link>
                </span>
            ),
        },
    ]

    const curr_pagination = {
        position: 'bottom',
        current: page + 1,
        defaultPageSize: 25,
        total: count,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['25', '50', '100'],
    }

    return (
        <Table
            loading={loading}
            rowKey="id"
            pagination={curr_pagination}
            onChange={pageChangeHandler}
            columns={columns}
            dataSource={data}
        />
    )
}

export default ListIllustrations
