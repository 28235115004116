import { Card } from 'antd'
import React from 'react'
import numeral from 'numeral'
import { PolicyDataCamel } from '../../..'

interface PolicyCostsProps {
    clientGender: string
    clientAge: number
    initialFaceAmount: number
    policyData: PolicyDataCamel[]
}

const PolicyCosts = (props: PolicyCostsProps) => {
    const { clientGender, clientAge, initialFaceAmount, policyData } = props

    let totalPremiums = 0
    let totalCharges = 0
    let totalCredits = 0

    let accumulationValue = 0
    let accumulationRow = policyData.find((row) => row.policyYear === 20)
    if (accumulationRow !== undefined) {
        accumulationValue = accumulationRow.nonGuaranteedAccumulationValue
    }

    policyData.forEach((row) => {
        if (row.policyYear <= 20) {
            const annualCharges =
                row.premiumCharge +
                row.coiCharge +
                row.policyIssueCharge +
                row.additionalCharges
            const annualCredits =
                row.bonusInterestCredit + row.additionalPolicyCredits

            totalPremiums += row.premiumOutlay
            totalCharges += annualCharges
            totalCredits += annualCredits
        }
    })

    return (
        <Card title="Policy Costs">
            <h4 className="text-center">
                An often overlooked piece of the puzzle when evaluating an IUL
                contract are the internal costs built into the policy.
            </h4>
            <br />
            <h4 className="text-center">
                Life insurance contract costs include mortality costs, which are
                based on the death benefit, age, and health of the insured, and
                administrative charges to cover the cost of issuing,
                underwriting, and distributing the policy.
            </h4>
            <h4 className="text-center">
                Some contracts have bonus interest credits that offset a portion
                of the policy expenses.
            </h4>
            <br />
            <br />
            <h3 className="text-center">Summary of Policy Charges</h3>
            <h3 className="text-center">
                {clientGender} Age {clientAge}
            </h3>
            <h3 className="text-center">
                Initial Death Benefit:{' '}
                {numeral(initialFaceAmount).format('$0,0')}
            </h3>
            <br />
            <table style={{ width: '400px', margin: 'auto' }}>
                <tbody>
                    <tr className="col-fill-aria-dkgreen">
                        <td className="h3">Annual % of Total Premium</td>
                        <td className="h3 text-right">
                            {numeral(
                                (totalCharges + totalCredits) /
                                    totalPremiums /
                                    20
                            ).format('0,0.00%')}
                        </td>
                    </tr>
                    <tr className="col-fill-aria-dkblue">
                        <td className="h3">Annual % of Account Value</td>
                        <td className="h3 text-right">
                            {numeral(
                                (totalCharges + totalCredits) /
                                    accumulationValue /
                                    20
                            ).format('0,0.00%')}
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <span className="disclaimer">
                Hypothetical example is for illustrative purposes only. This is
                not a prediction or guarantee of actual results, which will vary
                from those described.
                <br />
                This illustration assumes that the currently illustrated
                non‐guaranteed elements will remain unchanged for all years
                shown. This is not likely to occur and actual results may be
                more or less favorable than those shown. Administrative and
                insurance charges are deducted every month regardless of whether
                premium outlays are made. Depending upon actual policy
                experience, the Owner may need to increase premium payments to
                maintain coverage for the desired duration. Any policy loans and
                partial surrenders will affect policy values and may require
                additional premiums to avoid policy termination.
                <br />
                Life insurance guarantees rely on the fiscal strength and claims
                paying ability of the issuing insurer. Universal life insurance
                products are not bank or FDIC insured. Indexed Universal Life
                insurance products are not an investment in the stock market and
                are subject to all policy fees and charges associated with
                Universal Life policies.
                <br />
                ARIA Financial Software, LLC does not provide tax, legal or
                accounting advice, nor is it designed ‐ nor intended ‐ to be
                appliable to any person's individual circumstances. This
                material has been prepared for information purposes only, and is
                not intended to provide, and should not be relied on for, tax,
                legal or accounting advice. You should consult your own
                qualified tax, legal and accounting advisors before engaging in
                any transaction.
                <br />
            </span>
        </Card>
    )
}

export default PolicyCosts
