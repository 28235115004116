import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    requestStates,
    requestCreateAgency,
    requestCreateAgencyAgent,
} from '../actions'
import AgentAdd from '../components/agent/add'

function AgentAddPage(props) {
    const history = useHistory()
    useEffect(() => {
        props.requestStates()
    }, [])

    function createHandler(id, values) {
        props.requestCreateAgencyAgent(id, { ...values, history })
    }

    return <AgentAdd {...props} handleSubmit={createHandler} />
}

const mapStateToProps = (state) => {
    return {
        ...state.agency,
        states: state.states,
    }
}

export default connect(mapStateToProps, {
    requestStates,
    requestCreateAgency,
    requestCreateAgencyAgent,
})(AgentAddPage)
