import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { requestAgency, requestAgencyAgents } from '../actions'
import AgencyView from '../components/agency/view'

const AgencyViewPage = (props) => {
    const { agencyId } = useParams()

    const agentsPageChangeHandler = (state) => {
        let sorted = ''
        if (state.sorted.length > 0) {
            sorted = `${state.sorted[0].desc ? '-' : ''}${state.sorted[0].id}`
        }
        props.requestAgencyAgents(agencyId, state.page, state.pageSize, sorted)
    }

    useEffect(() => {
        if (agencyId !== 'add') {
            props.requestAgency(agencyId)
        }
    }, [])

    if (agencyId !== 'add') {
        return (
            <AgencyView
                pageChangeHandler={agentsPageChangeHandler}
                {...props}
            />
        )
    } else {
        return <></>
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.agency,
        agents: { ...state.agents },
    }
}

export default connect(mapStateToProps, { requestAgency, requestAgencyAgents })(
    AgencyViewPage
)
