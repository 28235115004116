import React from 'react'
import numeral from 'numeral'
import { Card, Row } from 'antd'

const MaxBenefitAssetPlan = (props) => {
    const { client, account, tax, maxBenefit, illustration } = props
    const currentTaxRate =
        tax.currentFederalTaxRate / 100 + tax.currentStateTaxRate / 100
    const futureTaxRate =
        (tax.futureFederalTaxRate >= 0
            ? tax.futureFederalTaxRate
            : tax.currentFederalTaxRate) /
            100 +
        (tax.futureStateTaxRate >= 0
            ? tax.futureStateTaxRate
            : tax.currentStateTaxRate) /
            100
    const taxFreeBenefit = maxBenefit.totalIncome + maxBenefit.familyBenefit

    return (
        <div className="center">
            <Card
                className="container700"
                title={`Maximum Benefit Tax Free Asset Plan`}
                bordered={false}
                headStyle={{
                    color: '#003e80',
                    fontWeight: 'bold',
                    fontSize: 24,
                    textAlign: 'center',
                    borderBottomColor: '#003e80',
                }}
            >
                <Row
                    gutter={[32, 32]}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            width: 700,
                        }}
                    >
                        <Card
                            style={{
                                flexWrap: 'wrap',
                                borderColor: 'black',
                                borderWidth: 2,
                                width: 400,
                            }}
                        >
                            <strong>Client:</strong> {client.fullName} - Age{' '}
                            {client.currentAge}
                            <br />
                            <strong>Current Plan:</strong> Retirement Plan
                            <br />
                            <strong>Tax Status:</strong> Fully Taxable
                            <br />
                            <strong>Tax Rate:</strong>{' '}
                            {numeral(currentTaxRate * 100).format('0')} %
                            <br />
                            <strong>Income Period:</strong> Age{' '}
                            {illustration.incomeYearStart} - 100
                            <br />
                            <strong>Current Asset Value:</strong> $
                            {numeral(account.accountBalance).format('0,0')}
                            <br />
                            <strong>Recommended Reallocation:</strong> $
                            {numeral(account.accountBalance).format('0,0')}
                            <br />
                            <strong>Net Amount Available:</strong> $
                            {numeral(
                                account.accountBalance * (1 - currentTaxRate)
                            ).format('0,0')}
                            <br />
                            <br />
                            <p>
                                (Example below based on reallocation of $
                                {numeral(account.accountBalance).format('0,0')}{' '}
                                or $
                                {numeral(
                                    account.accountBalance *
                                        (1 - currentTaxRate)
                                ).format('0,0')}{' '}
                                net after tax)
                            </p>
                        </Card>
                        <br />
                        <br />
                        <p className="blue full">
                            <strong>
                                Option 1: Reallocation to Alternative Tax Free
                                Plan
                            </strong>
                        </p>
                        <p>
                            ${numeral(maxBenefit.income).format('0,0')}/yr Tax
                            Free to the age of 100 = $
                            {numeral(maxBenefit.totalIncome).format('0,0')} of
                            Tax Free income (Taxable Equivalent: $
                            {numeral(
                                maxBenefit.totalIncome / (1 - currentTaxRate)
                            ).format('0,0')}
                            ) &nbsp;with a Tax Free family benefit still
                            available of $
                            {numeral(maxBenefit.familyBenefit).format('0,0')}.*
                        </p>
                        <br />
                        <p>
                            That’s {maxBenefit.years} years of Tax Free income
                            with a Tax Free Family Benefit.
                        </p>
                        <p
                            style={{
                                textAlign: 'center',
                                fontSize: 20,
                                fontWeight: 'bold',
                            }}
                        >
                            VS.
                        </p>
                        <p className="red full">
                            <strong>
                                Option 2: Continue With Qualified Retirement
                                Plan
                            </strong>
                        </p>
                        <br />
                        {/*option2IntroText*/} After {maxBenefit.iraYears} years
                        the value of your Retirement Plan would be $
                        {numeral(maxBenefit.iraValue).format('0,0')}. If you
                        were to spread out this income for the same{' '}
                        {maxBenefit.years} years it only would generate $
                        {numeral(maxBenefit.iraAnnual).format('0,0')}/year or a
                        net amount (after tax) of $
                        {numeral(maxBenefit.iraNetIncome).format('0,0')}/yr,
                        which would equate to $
                        {numeral(maxBenefit.iraTotalNetIncome).format('0,0')} of
                        net income for the same period of time.**
                        <br />
                        <br />
                        That is a difference of $
                        {numeral(
                            maxBenefit.totalIncome -
                                maxBenefit.iraTotalNetIncome
                        ).format('0,0')}{' '}
                        of lost income and no Tax Free Family Benefit.
                        <br />
                        <br />
                        Based on the net income of $
                        {numeral(maxBenefit.income).format('0,0')}/yr we are
                        able to provide through the Tax Free Benefit Plan, had
                        you utilized your Retirement Plan, you have gone through
                        all of your Retirement Plan assets by the age of{' '}
                        {maxBenefit.iraRunoutAge}, leaving no assets to pass on
                        to significant others.
                        <br />
                        <br />
                    </div>
                </Row>
                <Row
                    gutter={[32, 32]}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <h3>Maximum Benefit Tax Free Asset Plan</h3>
                </Row>
                <Row
                    gutter={[32, 32]}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <table className="table table-800 table-noborder table-condensed">
                        <tbody>
                            <tr>
                                <td
                                    colSpan="3"
                                    className="table-td-blue text-center"
                                >
                                    <h5 className="white">
                                        ALTERNATIVE TAX FREE PLAN
                                    </h5>
                                </td>
                            </tr>
                            <tr className="table-td-noborder">
                                <td className="table-td-noborder">
                                    <p className="boxed-label">
                                        TAX FREE ANNUAL INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(maxBenefit.income).format(
                                            '0,0'
                                        )}
                                    </p>
                                </td>
                                <td />
                                <td className="table-td-noborder">
                                    <p className="boxed-label">
                                        TAXABLE EQUIVALENT
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.income /
                                                (1 - futureTaxRate)
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        AGGREGATE INCOME AT AGE 100
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(maxBenefit.totalIncome).format(
                                            '0,0'
                                        )}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        TAXABLE EQUIVALENT
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.totalIncome /
                                                (1 - futureTaxRate)
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        TAX FREE FAMILY BENEFIT
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.familyBenefit
                                        ).format('0,0')}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        TAXABLE EQUIVALENT
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.familyBenefit /
                                                (1 - futureTaxRate)
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL TAX FREE BENEFIT
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(taxFreeBenefit).format('0,0')}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        TAXABLE EQUIVALENT
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            taxFreeBenefit / (1 - futureTaxRate)
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="text-center">
                                    <h5>
                                        {maxBenefit.years} YEARS OF TAX FREE
                                        INCOME WITH A FAMILY BENEFIT
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan="3"
                                    className="table-td-red text-center"
                                >
                                    <h5 className="white">
                                        QUALIFIED RETIREMENT PLAN
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        ANNUAL TAXABLE INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(maxBenefit.iraAnnual).format(
                                            '0,0'
                                        )}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        NET ANNUAL INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.iraAnnual *
                                                (1 - futureTaxRate)
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL TAXABLE INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.iraAnnual *
                                                maxBenefit.years
                                        ).format('0,0')}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        TOTAL NET INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.iraTotalNetIncome
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        TOTAL FAMILY BENEFIT
                                    </p>
                                    <p className="boxed-currency">$ 0</p>
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td colSpan="3" className="text-center">
                                    <h5>
                                        {maxBenefit.years} YEARS OF TAXABLE
                                        INCOME WITH NO FAMILY BENEFIT
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan="3"
                                    className="table-th-dk-gray table-td-noborder text-center"
                                >
                                    <h5 className="white">
                                        ACCUMULATION BENEFIT DIFFERENCE
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-blue table-td-noborder text-center">
                                    <h5 className="white">
                                        ALTERNATIVE TAX FREE PLAN
                                    </h5>
                                </td>
                                <td />
                                <td className="table-td-red table-td-noborder text-center">
                                    <h5 className="white">
                                        QUALIFIED RETIREMENT PLAN
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL NET INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.income * maxBenefit.years
                                        ).format('0,0')}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        TOTAL NET INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            parseInt(maxBenefit.iraAnnual) *
                                                maxBenefit.years *
                                                (1 - futureTaxRate)
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        FAMILY BENEFIT
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.familyBenefit
                                        ).format('0,0')}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        FAMILY BENEFIT
                                    </p>
                                    <p className="boxed-currency">$ 0</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL TAXES PAID
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(maxBenefit.taxes).format(
                                            '0,0'
                                        )}
                                    </p>
                                </td>
                                <td />
                                <td>
                                    <p className="boxed-label">
                                        TOTAL TAXES PAID
                                    </p>
                                    <p className="boxed-currency">
                                        ${' '}
                                        {numeral(
                                            maxBenefit.iraAnnual *
                                                maxBenefit.years *
                                                futureTaxRate
                                        ).format('0,0')}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
            </Card>

            <div className="disclaimer-800">
                <p>
                    * Based on projections of indexed life insurance using index
                    returns and annual income. Please refer to the full life
                    insurance illustration for details.
                </p>
                <p>
                    ** Based on existing retirement plan assets and growth using
                    market returns. Income is projected using a 4.0% present
                    value rate.
                </p>
                <p>
                    Market returns assume {illustration.marketRateName} Annual
                    Returns Years 2001-2018 plus 1.5% management fee, excluding
                    dividends
                </p>
                <p>
                    Index returns assume {illustration.marketIndexDesc.trim()}.
                </p>
            </div>
        </div>
    )
}

export default MaxBenefitAssetPlan
