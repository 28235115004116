import React from 'react'
import { connect } from 'react-redux'

import BizPlan from '../components/bizplan'

const BizPlanPage = () => {
    return <BizPlan />
}

export default connect()(BizPlanPage)
