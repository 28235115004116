import React, { Component } from "react";
import { Col, DatePicker, Row } from "antd";
import moment from 'moment';

class AgeAndDob extends Component {

  disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  render() {
    return (
      <div style={{ padding: 10 }}>
        <Row gutter={12} style={{ paddingBottom: 10 }}>
          <Col span={12}>
            Today's Date:
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {moment().format('MM/DD/YYYY')}
          </Col>
        </Row>
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12}>
            Date of Birth (Eldest Spouse):
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <DatePicker
              style={{ width: 130 }}
              format={'MM/DD/YYYY'}
              disabledDate={this.disabledDate}
              onChange={(date, dateString) => {
                this.props.dobChanged(date);
              }} />
          </Col>
        </Row>
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12}>
            Current Age:
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {this.props.currentAge} Years
          </Col>
        </Row>
      </div>
    );
  }
}

export default AgeAndDob;
