/*
 * Vinod <vmohanan@coretechs.com>
 * Thu Aug 15 2019
 */
import { Card, Col, Row, Table } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { default_sheet, vola_calc } from './calc'
import Chart from './chart'
import Form from './form'

const columns = [
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: 'Return',
        dataIndex: 'return',
        key: 'return',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: (text * 100).toFixed(3) + '%',
            }
        },
    },
    {
        title: 'Gain/Loss',
        dataIndex: 'gain_loss',
        key: 'gain_loss',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
    {
        title: 'Gross Income',
        dataIndex: 'gross_income',
        key: 'gross_income',
        render: (text, record) => {
            return {
                props: {
                    style: { textAlign: 'right' },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
    {
        title: 'IRA Account Value',
        dataIndex: 'ira_value',
        key: 'ira_value',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
]

const VolatilityPage = (props) => {
    const [sheetValues, setSheetValues] = useState({
        sheet: [],
        default_values: {
            starting: 300000,
            net_income: 38000,
            tax_rate: 30,
            gross_income: 0,
        },
        chart_data: [],
        snp500_data: [],
        ...vola_calc(
            {
                starting: 300000,
                net_income: 38000,
                tax_rate: 30,
                gross_income: 0,
            },
            default_sheet
        ),
    })

    const formSubmit = (values) => {
        const { new_sheet, chart_data, snp500_data } = vola_calc(
            values,
            default_sheet
        )
        setSheetValues({
            ...sheetValues,
            sheet: new_sheet,
            chart_data: chart_data,
            snp500_data: snp500_data,
        })
    }

    return (
        <Card
            bordered={false}
            extra={<Form onSubmit={(values) => formSubmit(values)} />}
            title="Volatility"
        >
            <p>
                9% annualized return (first nine years.) Bear markets (loss of
                20% or more) occur once every 4.5 Years (historically)
            </p>
            <Row gutter={10}>
                <Col span={15}>
                    <Table
                        pagination={false}
                        dataSource={sheetValues.sheet}
                        columns={columns}
                    />
                </Col>
                <Col span={9}>
                    <Row>
                        <Card
                            headStyle={{
                                backgroundColor: '#E0E0E0',
                                textAlign: 'center',
                            }}
                            bordered={false}
                            title={'Account Value'}
                        >
                            <Chart id={2} data={sheetValues.chart_data} />
                        </Card>
                    </Row>
                    <Row>
                        <Card
                            headStyle={{
                                backgroundColor: '#E0E0E0',
                                textAlign: 'center',
                            }}
                            bordered={false}
                            title={'S&P 500'}
                        >
                            <Chart id={1} data={sheetValues.snp500_data} />
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default connect()(VolatilityPage)
