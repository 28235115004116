import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestProposal, requestUpdateProposal } from '../actions'
import ProposalEdit from '../components/proposal/edit'
import { Card, Row, Col, Button } from 'antd'
import { Link, useParams, useHistory } from 'react-router-dom'

const ProposalEditPage = (props) => {
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        props.requestProposal(params.proposalId)
    }, [])

    const updateProposal = (values) => {
        props.requestUpdateProposal(params.proposalId, { ...values, history })
    }

    return (
        <Card
            title={
                <Row>
                    <Col span={8}>
                        <Link to="/proposals" className="pull-left">
                            <Button type="danger" icon="close">
                                Cancel
                            </Button>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <div style={{ textAlign: 'center' }}>Proposal View</div>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            }
        >
            <ProposalEdit {...props} submitHandler={updateProposal} />
        </Card>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.proposal,
    }
}

export default connect(mapStateToProps, {
    requestProposal,
    requestUpdateProposal,
})(ProposalEditPage)
