import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
    requestIndexes,
    requestCreateProposal,
    requestAddProposal,
} from '../actions'
import ProposalAdd from '../components/proposal/add'

const ProposalAddPage = (props) => {
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        if (params.illustrationId) {
            props.requestIndexes()
            props.requestCreateProposal(params.illustrationId)
        }
    }, [])

    const addProposal = (values) => {
        props.requestAddProposal(params.illustrationId, { ...values, history })
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">Proposal - Add</h3>
            </div>
            <div className="panel-body">
                <ProposalAdd {...props} submitHandler={addProposal} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.proposal,
        indexes: state.indexes,
    }
}

export default connect(mapStateToProps, {
    requestCreateProposal,
    requestAddProposal,
    requestIndexes,
})(ProposalAddPage)
