import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import {
    requestCreateAriaV2,
    requestAddAriaV2,
    requestResetAriaV2,
    requestAriaV2FileParse,
} from '../actions'
import AriaV2Form from '../components/aria-v2/form'

const AriaV2AddPage = (props) => {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        props.requestCreateAriaV2(location.state.client)
    }, [])

    const addAriaV2 = (values) => {
        props.requestAddAriaV2({ ...values, history })
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">ARIA - Add</h3>
            </div>
            <div className="panel-body">
                <AriaV2Form {...props} submitHandler={addAriaV2} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.ariav2,
    }
}

export default connect(mapStateToProps, {
    requestCreateAriaV2,
    requestAddAriaV2,
    requestResetAriaV2,
    requestAriaV2FileParse,
})(AriaV2AddPage)
