import { Button, Card, Col, Row, Tag, Tabs } from 'antd'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import numeral from 'numeral'
import * as moment from 'moment'
import Container from '../base/container'
import ProposalClientPanel from './view/proposal_client_panel'
import ProposalActions from './view/proposal_actions'
import LoadingField from '../shared_components/loading_field'
import PolicyCostDetail from './components/policy_cost_detail'
import PolicyCosts from './components/policy_costs'
import AtapSummary from './components/atap_summary'
import AtapIndexedUniversalLife from './components/atap_indexed_universal_life'

const ProposalView = (props) => {
    const { data, auth, loading, error, downloadPDFv2, pdfs } = props
    const { TabPane } = Tabs
    const { proposalId } = useParams()
    if (error) return <div>Error {error || 'oops...'}</div>

    return (
        <Container>
            <Card
                title={
                    <Row>
                        <Col span={8}>
                            <Link to="/aria-v2" className="pull-left">
                                <Button icon="arrow-left">ARIAs</Button>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <div style={{ textAlign: 'center' }}>
                                Proposal View
                            </div>
                        </Col>
                        <Col span={8}>
                            <Link
                                to={`/aria-v2/edit/${proposalId}`}
                                className="pull-right"
                            >
                                <Button type="primary" icon="edit">
                                    Edit
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                }
            >
                <ProposalClientPanel {...data} loading={loading} />
                <div className="col-md-6">
                    <Card title="Input Data">
                        <Row>
                            <Col span={6}>
                                <span>
                                    <strong>Proposal Date</strong>
                                </span>
                            </Col>
                            <Col span={6}>
                                <span>
                                    {loading ? (
                                        <LoadingField width={30} />
                                    ) : (
                                        moment(data.proposalDate).format(
                                            'MM-DD-YYYY'
                                        )
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <span>
                                    <strong>Index Crediting Rate</strong>
                                </span>
                            </Col>
                            <Col span={6}>
                                <span>
                                    {loading ? (
                                        <LoadingField width={30} />
                                    ) : (
                                        numeral(
                                            data.indexCreditingRate / 100
                                        ).format('0,000.00%')
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <span>
                                    <strong>Initial Face Amount</strong>
                                </span>
                            </Col>
                            <Col span={6}>
                                <span>
                                    {loading ? (
                                        <LoadingField width={30} />
                                    ) : (
                                        numeral(data.initialFaceAmount).format(
                                            '0,000'
                                        )
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <span>
                                    <strong>Qualified</strong>
                                </span>
                            </Col>
                            <Col span={6}>
                                <span>
                                    {loading ? (
                                        <LoadingField width={30} />
                                    ) : (
                                        <Tag
                                            color={
                                                data.qualified ? 'green' : 'red'
                                            }
                                            key={data.qualified}
                                        >
                                            {data.qualified ? 'Yes' : 'No'}
                                        </Tag>
                                    )}
                                </span>
                            </Col>
                        </Row>
                        {!loading && data.qualified && (
                            <Row>
                                <Col span={6}>
                                    <span>
                                        <strong>Qualified Funds Amount</strong>
                                    </span>
                                </Col>
                                <Col span={6}>
                                    <span>
                                        {loading ? (
                                            <LoadingField width={30} />
                                        ) : (
                                            numeral(
                                                data.qualifiedFundsAmount
                                            ).format('0,000')
                                        )}
                                    </span>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col span={6}>
                                <span>
                                    <strong>Income Start Age</strong>
                                </span>
                            </Col>
                            <Col span={6}>
                                <span>
                                    {loading ? (
                                        <LoadingField width={30} />
                                    ) : (
                                        data.incomeStartAge
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <span>
                                    <strong>Federal Tax Rate</strong>
                                </span>
                            </Col>
                            <Col span={6}>
                                <span>
                                    {loading ? (
                                        <LoadingField width={30} />
                                    ) : (
                                        numeral(
                                            data.federalTaxRate / 100
                                        ).format('0,000.00%')
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <span>
                                    <strong>State Tax Rate</strong>
                                </span>
                            </Col>
                            <Col span={6}>
                                <span>
                                    {loading ? (
                                        <LoadingField width={30} />
                                    ) : (
                                        numeral(data.stateTaxRate / 100).format(
                                            '0,000.00%'
                                        )
                                    )}
                                </span>
                            </Col>
                        </Row>
                    </Card>
                </div>
                <div className="col-md-6">
                    <ProposalActions
                        {...data}
                        pdfs={pdfs}
                        auth={auth}
                        downloadPDFv2={downloadPDFv2}
                        loading={loading}
                    />
                </div>
                <div className="col-md-12">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Indexed Universal Life" key="1">
                            {!loading && (
                                <AtapIndexedUniversalLife
                                    policyData={data.policyData}
                                    indexCreditingRate={data.indexCreditingRate}
                                />
                            )}
                        </TabPane>
                        <TabPane tab="Summary" key="2">
                            {!loading && (
                                <AtapSummary
                                    policyDataSummary={data.policyDataSummary}
                                />
                            )}
                        </TabPane>
                        <TabPane tab="Policy Costs" key="3">
                            {!loading && (
                                <PolicyCosts
                                    policyData={data.policyData}
                                    clientGender={data.clientGender}
                                    clientAge={data.clientAge}
                                    initialFaceAmount={data.initialFaceAmount}
                                />
                            )}
                        </TabPane>
                        <TabPane tab="Policy Cost Detail" key="4">
                            {!loading && (
                                <PolicyCostDetail
                                    policyData={data.policyData}
                                />
                            )}
                        </TabPane>
                    </Tabs>
                </div>
            </Card>
        </Container>
    )
}

export default ProposalView
