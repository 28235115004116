import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'antd'
import React, { Component } from 'react'

class ProposalActions extends Component {
    state = {
        children: [],
    }

    render() {
        const { clientName, downloadPDFv2, loading, pdfs } = this.props

        return (
            <Card title="Download PDF:">
                <div style={{ marginBottom: 4 }}>
                    <button
                        className="btn btn-default btn-link btn-sm"
                        onClick={() => downloadPDFv2(clientName, 1)}
                        title="Download PDF"
                        disabled={loading}
                    >
                        <FontAwesomeIcon icon={faFilePdf} /> Alternative Tax
                        Advantaged Plan PDF
                    </button>
                </div>
                {pdfs.data.map((pdfPage) => {
                    return (
                        <div style={{ marginBottom: 4 }} key={pdfPage.id}>
                            <a
                                href={pdfPage.downloadUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-default btn-link btn-sm"
                                title={pdfPage.description}
                                disabled={loading}
                            >
                                <FontAwesomeIcon icon={faFilePdf} />{' '}
                                {pdfPage.name}
                            </a>
                        </div>
                    )
                })}
            </Card>
        )
    }
}

ProposalActions.displayName = 'ProposalActions'

export default ProposalActions
