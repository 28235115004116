import React from 'react'

const IPAddressDetail = (props) => {
    const {
        country,
        countryCode,
        region,
        regionName,
        city,
        zip,
        timezone,
        isp,
        org,
    } = props

    console.debug(city)
    return (
        <div>
            Country: <b>{country || '...'}</b>
            <br />
            Country Code: <b>{countryCode || '...'}</b>
            <br />
            Region: <b>{region || '...'}</b>
            <br />
            Region Name: <b>{regionName || '...'}</b>
            <br />
            Timezone: <b>{timezone || '...'}</b>
            <br />
            City: <b>{city || '...'}</b>
            <br />
            Zip: <b>{zip || '...'}</b>
            <br />
            ISP: <b>{isp || '...'}</b>
            <br />
            Org: <b>{org || '...'}</b>
            <br />
        </div>
    )
}

export default IPAddressDetail
