import { Col, Form, InputNumber, Row, Table, Divider } from 'antd'
import numeral from 'numeral'
import React from 'react'
import './styles.css'

const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

class EditableCell extends React.Component {
    state = {
        editing: true,
    }

    toggleEdit = () => {
        // const editing = !this.state.editing;
        // this.setState({ editing }, () => {
        //   if (editing) {
        //     this.input.focus();
        //   }
        // });
    }

    save = (e) => {
        const { record, handleSave } = this.props
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return
            }
            this.toggleEdit()
            handleSave({ ...record, ...values })
        })
    }

    renderCell = (form) => {
        this.form = form
        const { children, dataIndex, record, title } = this.props
        const { editing } = this.state
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(
                    <InputNumber
                        style={{ width: 140 }}
                        ref={(node) => {
                            this.input = node
                        }}
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onPressEnter={this.save}
                        onBlur={this.save}
                    />
                )}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 10 }}
                onClick={this.toggleEdit}
            >
                {children}
            </div>
        )
    }

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>
                        {this.renderCell}
                    </EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        )
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                title: 'Personal Income (Annual)',
                dataIndex: 'name',
            },
            {
                title: 'Value',
                dataIndex: 'amount',
                align: 'right',
                editable: true,
                render: (amount) =>
                    `$ ${amount.toFixed(0)}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                    ),
            },
        ]

        this.state = {
            dataSource: [
                {
                    key: '0',
                    name: 'Salary',
                    amount: 0,
                    editable: false,
                    delete: false,
                },
                {
                    key: '1',
                    name: 'Other salary or wages',
                    amount: 0,
                    editable: false,
                    delete: false,
                },
                {
                    key: '2',
                    name: 'Rental Income',
                    amount: 0,
                    editable: false,
                    delete: false,
                },
                {
                    key: '3',
                    name: 'Investment Income',
                    amount: 0,
                    editable: false,
                    delete: false,
                },
                {
                    key: '4',
                    name: 'Retirement Income',
                    amount: 0,
                    editable: false,
                    delete: false,
                },
                {
                    key: '5',
                    name: 'Other Income',
                    amount: 0,
                    editable: false,
                    delete: false,
                },
            ],
            newName: '',
            newAmount: 0,
            count: 5,
        }
    }

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource]
        const newDataSource = dataSource.filter((item) => item.key !== key)
        this.setState({ dataSource: newDataSource })
        this.findTotalIncome(newDataSource)
    }

    handleAdd = () => {
        if (this.state.newName.length === 0) {
            return
        }
        const { count, dataSource } = this.state
        const newData = {
            key: count,
            name: this.state.newName,
            amount: this.state.newAmount,
            editable: true,
            delete: true,
        }

        const newDataSource = [...dataSource, newData]
        this.setState({
            dataSource: newDataSource,
            count: count + 1,
            newName: '',
            newAmount: 0,
        })

        this.findTotalIncome(newDataSource)
    }

    handleSave = (row) => {
        const newData = [...this.state.dataSource]
        const index = newData.findIndex((item) => row.key === item.key)
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row,
        })
        this.setState({ dataSource: newData })
        this.findTotalIncome(newData)
    }

    findTotalIncome = (newDataSource) => {
        const totalIncome = newDataSource.reduce(
            (total, item) => total + item.amount,
            0
        )
        this.props.totalIncomeChanges(totalIncome)
    }

    render() {
        const { dataSource } = this.state
        const sum = dataSource.reduce((prev, next) => prev + next.amount, 0)
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        }

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            }
        })

        return (
            <div>
                <Divider
                    style={{
                        backgroundColor: '#000000',
                        height: 3,
                        margin: 0,
                        padding: 0,
                    }}
                />
                <Table
                    className="table-condensed"
                    size="middle"
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered={false}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    // footer={() => {
                    //   return (
                    //   )
                    // }}
                />
                <Divider
                    style={{
                        backgroundColor: '#000000',
                        height: 1,
                        margin: 0,
                        padding: 0,
                    }}
                />
                <Row
                    style={{
                        height: 40,
                        paddingTop: 10,
                        paddingLeft: 5,
                        paddingRight: 18,
                    }}
                >
                    <Col span={12}>
                        <strong>Total Income:</strong>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <strong>{numeral(sum).format('$ 0,0') || '...'}</strong>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default EditableTable
