import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestStates, requestCreateAgency } from '../actions'
import AgencyAdd from '../components/agency/add'

function AgencyAddPage(props) {
    const history = useHistory()
    useEffect(() => {
        props.requestStates()
    }, [])

    function createHandler(values) {
        props.requestCreateAgency({ ...values, history })
    }

    return <AgencyAdd {...props} handleSubmit={createHandler} />
}

const mapStateToProps = (state) => {
    return {
        ...state.agency,
        states: state.states,
    }
}

export default connect(mapStateToProps, { requestStates, requestCreateAgency })(
    AgencyAddPage
)
