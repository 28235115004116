import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
    requestClient,
    requestUpdateClient,
    requestAgenciesAgents,
    requestStates,
} from '../actions'
import ClientEdit from '../components/client/edit'

const ClientEditPage = (props) => {
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        props.requestClient(params.clientId)
        props.requestAgenciesAgents()
        props.requestStates()
    }, [])

    const updateHandler = (values) => {
        props.requestUpdateClient(params.clientId, { ...values, history })
    }

    return <ClientEdit submitHandler={updateHandler} {...props} />
}

const mapStateToProps = (state) => {
    return {
        data: state.client,
        states: state.states,
        agenciesAgents: state.agenciesAgents,
    }
}

export default connect(mapStateToProps, {
    requestClient,
    requestUpdateClient,
    requestAgenciesAgents,
    requestStates,
})(ClientEditPage)
