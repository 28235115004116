import React from 'react'
import { bucketService } from '../../services/bucket.service'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { BucketType } from '../../index.d'
import { useParams, Link, useHistory } from 'react-router-dom'
import { Button, Card, Col, Row } from 'antd'
import ClientInfo from '../../components/shared_components/client_info'
import BucketGlobals from './components/BucketGlobals'
import BucketNeeds from './components/BucketNeeds'
import BucketSummary from './components/BucketSummary'
import BucketBuckets from './components/BucketBuckets'
import './buckets.css'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import BucketIncomeSources from './components/BucketIncomeSources'

interface BucketPageProps {}

const BucketPage = (props: BucketPageProps) => {
    // const location = useLocation()
    const history = useHistory()
    const queryClient = useQueryClient()
    const params = useParams<{ bucketId: string }>()
    const { bucketId } = params

    // const { client } = location.state

    if (!bucketId) {
        return null
    }

    const bucket = useQuery<BucketType | undefined, AxiosError>(
        ['buckets', bucketId],
        () => bucketService.getBucket(bucketId),
        {
            onError: (error: AxiosError) => {
                toast.error(
                    `An error occured while loading bucket: ${error.message}`
                )
            },
        }
    )

    const updateBucket = useMutation(
        (bucketValues: BucketType) =>
            bucketService.updateBucket(bucketId, bucketValues),
        {
            onError: (error: AxiosError) => {
                toast.error(`An error occured while saving: ${error.message}`)
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['buckets', bucketId])
            },
        }
    )

    const cloneBucket = useMutation(
        (bucketId: string) => bucketService.cloneBucket(bucketId),
        {
            onError: (error: AxiosError) => {
                toast.error(`An error occured while cloning: ${error.message}`)
            },
            onSuccess: (data) => {
                if (data) {
                    queryClient.invalidateQueries(['buckets', data.id])
                }
            },
        }
    )

    if (!bucket.data) {
        return null
    }

    return (
        <Card
            loading={!bucket.data || bucket.isLoading}
            title={
                <Row>
                    <Col span={8}>
                        <Link to="/buckets" className="pull-left">
                            <Button icon="arrow-left">Buckets</Button>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <div style={{ textAlign: 'center' }}>Bucketeer</div>
                    </Col>
                    <Col span={8}>
                        <Button
                            onClick={() =>
                                cloneBucket.mutate(bucketId, {
                                    onSuccess: (data) => {
                                        toast.success(
                                            `Bucket cloned successfully`
                                        )
                                        if (data) {
                                            history.push(`/buckets/${data.id}`)
                                        }
                                    },
                                })
                            }
                            icon="copy"
                            className="pull-right"
                        >
                            Clone
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Row>
                <Col span={12}>
                    <Card
                        title="Client"
                        type="inner"
                        loading={!bucket.data || bucket.isLoading}
                    >
                        <div className="panel-body" style={{ height: '209px' }}>
                            <ClientInfo customer={bucket.data.customer} />
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title="Income Sources"
                        type="inner"
                        loading={!bucket.data || bucket.isLoading}
                        extra="Check to deduct from income"
                    >
                        <BucketIncomeSources
                            bucket={bucket.data}
                            updateBucket={updateBucket}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Card
                        title="Starting Values"
                        type="inner"
                        loading={!bucket.data || bucket.isLoading}
                    >
                        <BucketGlobals
                            bucket={bucket.data}
                            updateBucket={updateBucket}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title="Needs"
                        loading={!bucket.data || bucket.isLoading}
                        type="inner"
                        style={{ height: '201px' }}
                    >
                        <BucketNeeds
                            bucket={bucket.data}
                            updateBucket={updateBucket}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card
                        title="Buckets"
                        type="inner"
                        loading={!bucket.data || bucket.isLoading}
                    >
                        <BucketBuckets bucket={bucket.data} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card
                        title="Summary"
                        type="inner"
                        loading={!bucket.data || bucket.isLoading}
                    >
                        <BucketSummary bucket={bucket.data} />
                    </Card>
                </Col>
            </Row>
        </Card>
    )
}

export default BucketPage
