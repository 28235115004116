import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    requestCreateClient,
    requestAgenciesAgents,
    requestStates,
} from '../actions'
import ClientAdd from '../components/client/add'

const ClientAddPage = (props) => {
    const history = useHistory()
    useEffect(() => {
        props.requestAgenciesAgents()
        props.requestStates()
    }, [])

    const createHandler = (values) => {
        props.requestCreateClient({ ...values, history })
    }

    return <ClientAdd submitHandler={createHandler} {...props} />
}

const mapStateToProps = (state) => {
    return {
        data: {},
        states: state.states,
        agenciesAgents: state.agenciesAgents,
    }
}

export default connect(mapStateToProps, {
    requestCreateClient,
    requestStates,
    requestAgenciesAgents,
})(ClientAddPage)
