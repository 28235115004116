import { Form, Input, Button, message } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import authService from '../services/auth.service'

// TODO: redo this entire form... what a disaster
class ChangePasswordForm extends React.Component {
    state = {
        loading: false,
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
                this.setState({ loading: true })
                authService
                    .changePassword(values)
                    .then((res) => {
                        message.success(res.data.message)
                        this.setState({ loading: false })
                        this.props.form.resetFields()
                    })
                    .catch((error) => {
                        const error_msg = error.response.data || {
                            error: 'Error!',
                        }
                        message.error(error_msg.error)
                        this.setState({ loading: false })
                    })
            }
        })
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!')
        } else {
            callback()
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true })
        }
        callback()
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        }

        return (
            <Form
                {...formItemLayout}
                style={{ width: 1000 }}
                onSubmit={this.handleSubmit}
            >
                <Form.Item label="Current Password">
                    {getFieldDecorator('current_password', {
                        rules: [
                            {
                                message: 'Please input your password!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="New Password" hasFeedback>
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your new password!',
                            },
                            {
                                validator: this.validateToNextPassword,
                            },
                        ],
                    })(<Input.Password />)}
                </Form.Item>
                <Form.Item label="Confirm New Password" hasFeedback>
                    {getFieldDecorator('confirm', {
                        rules: [
                            {
                                required: true,
                                message: 'Please confirm your new password!',
                            },
                            {
                                validator: this.compareToFirstPassword,
                            },
                        ],
                    })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button
                        loading={this.state.loading}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

const ChangePassword = Form.create({ name: 'register' })(ChangePasswordForm)

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps, {})(ChangePassword)
