/*****************************
 *   Author: Sina Khelil
 *   Date: Jun 5, 2018
 ******************************/

import React from "react";
import ClientForm from "./form";

const ClientEdit = props => {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">Client - Edit</h3>
      </div>
      <div className="panel-body">
        <ClientForm {...props} />
      </div>
    </div>
  );
};

export default ClientEdit;
