import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingField from '../shared_components/loading_field'
import { Table, Card, Row, Col, Button, Typography, Divider } from 'antd'
import { Link, useParams } from 'react-router-dom'

const { Title } = Typography

const columns = [
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        align: 'right',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        align: 'right',
    },
    {
        title: 'Index',
        dataIndex: 'index',
        key: 'index',
        render: (index) => numeral(index).format('0.00'),
        align: 'right',
    },
    {
        title: 'Annual Premium',
        dataIndex: 'premium',
        key: 'premium',
        render: (premium) => numeral(premium).format('0,0'),
        align: 'right',
    },
    {
        title: 'Premium Charge',
        dataIndex: 'premiumCharge',
        key: 'premiumCharge',
        render: (premiumCharge) => numeral(premiumCharge).format('0,0'),
        align: 'right',
    },
    {
        title: 'COI',
        dataIndex: 'coi',
        key: 'coi',
        render: (coi) => numeral(coi).format('0,0'),
        align: 'right',
    },
    {
        title: 'PIC',
        dataIndex: 'pic',
        key: 'pic',
        render: (pic) => numeral(pic).format('0,0'),
        align: 'right',
    },
    {
        title: 'Admin',
        dataIndex: 'admin',
        key: 'admin',
        render: (admin) => numeral(admin).format('0,0'),
        align: 'right',
    },
    {
        title: 'Remaining Value',
        dataIndex: 'remainingValue',
        key: 'remainingValue',
        render: (remainingValue) => numeral(remainingValue).format('0,0'),
        align: 'right',
    },
    {
        title: 'Interest Credited',
        dataIndex: 'interestEarned',
        key: 'interestEarned',
        render: (interestEarned) => numeral(interestEarned).format('0,0'),
        align: 'right',
    },
    {
        title: 'Bonus Interest Credit',
        dataIndex: 'bonusInterestCredit',
        key: 'bonusInterestCredit',
        render: (bonusInterestCredit) =>
            numeral(bonusInterestCredit).format('0,0'),
        align: 'right',
    },
    {
        title: 'Annual Policy Credit',
        dataIndex: 'reimbursement',
        key: 'reimbursement',
        render: (reimbursement) => numeral(reimbursement).format('0,0'),
        align: 'right',
    },
    {
        title: 'Accumulation Value',
        dataIndex: 'taxFree',
        key: 'taxFree',
        render: (taxFree) => numeral(taxFree).format('0,0'),
        align: 'right',
    },
    {
        title: 'Loan',
        dataIndex: 'loan',
        key: 'loan',
        render: (loan) => numeral(loan).format('0,0'),
        align: 'right',
    },
    {
        title: 'Loan Interest',
        dataIndex: 'loanInterest',
        key: 'loanInterest',
        render: (loanInterest) => numeral(loanInterest).format('0,0'),
        align: 'right',
    },
    {
        title: 'Total Loan',
        dataIndex: 'loanBalance',
        key: 'loanBalance',
        render: (loanBalance) => numeral(loanBalance).format('0,0'),
        align: 'right',
    },
    {
        title: 'Surrender Value',
        dataIndex: 'surrenderValue',
        key: 'surrenderValue',
        render: (surrenderValue) => numeral(surrenderValue).format('0,0'),
        align: 'right',
    },
    {
        title: 'Death Benefit',
        dataIndex: 'deathBenefit',
        key: 'deathBenefit',
        render: (deathBenefit) => numeral(deathBenefit).format('0,0'),
        align: 'right',
    },
]

const IllustrationView = (props) => {
    const { loading, error, data, indexes } = props
    const { illustrationId } = useParams()
    if (error || indexes.error) return <div>{error || indexes.error}</div>

    const display_data = data.lines
        ? data.lines.filter((item) => item.age <= 100)
        : []

    return (
        <Card
            type="inner"
            title={
                <Row>
                    <Col span={8}>
                        <Link to="/illustrations">
                            <Button type="danger dashed" icon="arrow-left">
                                Back
                            </Button>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <Title level={4} style={{ textAlign: 'center' }}>
                            Illustrations - View
                        </Title>
                    </Col>
                    <Col span={8}>
                        <Link to={`/illustrations/edit/${illustrationId}`}>
                            <Button
                                className="pull-right"
                                type="primary"
                                icon="edit"
                            >
                                Edit
                            </Button>
                        </Link>
                        <Divider
                            type="vertical"
                            className="pull-right"
                            style={{ height: 20, width: 0 }}
                        ></Divider>
                        <Link to={`/proposals/add/${illustrationId}`}>
                            <Button
                                className="pull-right"
                                type="primary"
                                icon="plus"
                            >
                                Create proposal
                            </Button>
                        </Link>
                    </Col>
                </Row>
            }
        >
            <div>
                <table className="table table-striped table-condensed">
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Client</th>
                            <th>Premium</th>
                            <th>Start Year</th>
                            <th>Start Age</th>
                            <th>Market Index</th>
                            <th />
                        </tr>
                        <tr>
                            <td className="text-center">
                                {!loading &&
                                    moment(data.proposalDate).format('LL')}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td>
                                {!loading && data.customer.fullName}
                                {loading && <LoadingField />}
                            </td>
                            <td className="text-right">
                                {!loading &&
                                    numeral(data.premium).format('0,0')}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td className="text-right">
                                {!loading && data.startYear}
                                {loading && <LoadingField width={40} />}
                            </td>
                            <td className="text-right">
                                {!loading && data.startAge}
                                {loading && <LoadingField width={30} />}
                            </td>
                            <td className="text-center">
                                {!loading &&
                                    indexes.data.find(
                                        (o) => o.id === data.marketIndex
                                    ).name}
                                {loading && <LoadingField width={40} />}
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <th>Init. Death Benefit</th>
                            <th>Loan Rate</th>
                            <th>Tax Rate</th>
                            <th>Income Start</th>
                            <th>Income</th>
                            <th>Qualified</th>
                            <th>ML</th>
                        </tr>
                        <tr>
                            <td className="text-right">
                                {!loading &&
                                    numeral(data.initDeathBenefit).format(
                                        '0,0'
                                    )}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td className="text-right">
                                {!loading && data.loanRate}
                                {loading && <LoadingField width={20} />}%
                            </td>
                            <td className="text-right">
                                {!loading && data.taxRate}
                                {loading && <LoadingField width={30} />}%
                            </td>
                            <td className="text-right">
                                {!loading && data.incomeYearStart}
                                {loading && <LoadingField width={30} />}
                            </td>
                            <td className="text-right">
                                {!loading &&
                                    numeral(data.incomeAmount).format('0,0')}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td className="text-center">
                                {!loading && data.qualified && (
                                    <FontAwesomeIcon
                                        icon="check-circle"
                                        style={{ color: '#22AA22' }}
                                    />
                                )}
                                {!loading && !data.qualified && (
                                    <FontAwesomeIcon
                                        icon="times-circle"
                                        style={{ color: '#AA2222' }}
                                    />
                                )}
                                {loading && <LoadingField width={30} />}
                            </td>
                            <td className="text-center">
                                {!loading && data.ml && (
                                    <FontAwesomeIcon
                                        icon="check-circle"
                                        style={{ color: '#22AA22' }}
                                    />
                                )}
                                {!loading && !data.ml && (
                                    <FontAwesomeIcon
                                        icon="times-circle"
                                        style={{ color: '#AA2222' }}
                                    />
                                )}
                                {loading && <LoadingField width={30} />}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Table
                    title={() => (
                        <label className="control-label">
                            Illustration Data
                        </label>
                    )}
                    headStyle={{ background: 'green' }}
                    size="small"
                    bordered={false}
                    rowClassName={(record, index) => {
                        return index % 2 === 1 ? 'table-alt-row' : ''
                    }}
                    pagination={false}
                    columns={columns}
                    dataSource={display_data}
                />
            </div>
        </Card>
    )
}

export default IllustrationView
