import * as api from "./api";
import { isEmpty } from "lodash";

export const clientService = {
  getAllClients,
  getClientProposals,
  getClient,
  createClient,
  updateClient
};

function getClientProposals(client, page, perPage, sorted) {
  if (isEmpty(sorted)) {
    sorted = null;
  }
  return api
    .get(`/api/v1/clients/${client}/proposals/`, {
      params: { page: page, per_page: perPage, sorted: sorted }
    })
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function getAllClients({ page, perPage, sorted }) {
  if (isEmpty(sorted)) {
    sorted = null;
  }
  return api
    .get("/api/v1/clients/", {
      params: { page: page, per_page: perPage, sorted: sorted }
    })
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function getClient(client) {
  return api
    .get(`/api/v1/clients/${client}`)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function createClient(payload) {
  return api
    .post("/api/v1/clients/", payload)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function updateClient(clientId, payload) {
  payload.agent = payload.selectedAgent.value;
  return api
    .put(`/api/v1/clients/${clientId}/`, payload)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}
