import React from 'react'
import { IncomeCompModelData } from '../..';

import IncomeCompForm from '../incomeComp/form'

interface ICInputValuesProps {
    loading: boolean;
    data: IncomeCompModelData;
    updateHandler: Function;
}

export const ICInputValues = (props: ICInputValuesProps) => {
    const { updateHandler } = props;
      
    return <IncomeCompForm
                title="Income Tax Comparison - Edit"
                clients={{loading: false, data: [] }}
                submitHandler={updateHandler} {...props} 
                />;
      
}
