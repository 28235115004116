import React from 'react'
import numeral from 'numeral'
import { Card } from 'antd'
import { PolicyDataCamel } from '../../..'
import { max } from 'lodash'
import moment from 'moment'

interface AtapIndexedUniversalLifeProps {
    policyData: PolicyDataCamel[]
    indexCreditingRate: number
}

const formatNumber = (value: number) => {
    if (value === 0) return '-'
    return numeral(value).format('(0,0)')
}

const AtapIndexedUniversalLife = (props: AtapIndexedUniversalLifeProps) => {
    const { policyData, indexCreditingRate } = props

    return (
        <Card title="Alternative Tax‐Advantaged Plan (Indexed Universal Life)">
            <p className="h4 text-center">Income</p>
            <table className="atap-iul">
                <thead>
                    <tr>
                        <th>
                            Index Return
                            <br />
                            (%)
                        </th>
                        <th>Age</th>
                        <th>Year</th>
                        <th>
                            Premium
                            <br />
                            Payment
                        </th>
                        <th>
                            Loan
                            <br />
                            Withdrawal
                        </th>
                        <th>
                            Accumulation
                            <br />
                            Value
                        </th>
                        <th>Cash Value*</th>
                        <th>Death Benefit</th>
                    </tr>
                </thead>
                <tbody>
                    {policyData.map((row) => {
                        if (row.beginningOfYearAge <= 100) {
                            return (
                                <tr key={row.beginningOfYearAge}>
                                    <td>{indexCreditingRate}</td>
                                    <td>{row.beginningOfYearAge}</td>
                                    <td>
                                        {row.policyYear + (moment().year() - 1)}
                                    </td>
                                    <td>{formatNumber(row.premiumOutlay)}</td>
                                    <td>
                                        {formatNumber(row.annualLoanAmount)}
                                    </td>
                                    <td>
                                        {formatNumber(
                                            row.nonGuaranteedAccumulationValue
                                        )}
                                    </td>
                                    <td>
                                        {formatNumber(
                                            row.nonGuaranteedSurrenderValue
                                        )}
                                    </td>
                                    <td>
                                        {formatNumber(
                                            row.nonGuaranteedDeathBenefit
                                        )}
                                    </td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
            <br />
            <br />
            <span className="disclaimer">
                This illustration assumes that the currently illustrated
                non‐guaranteed elements will remain unchanged for all years
                shown. This is not likely to occur and actual results may be
                more or less favorable than those shown. Please refer to the
                basic illustration for guaranteed elements. Administrative and
                insurance charges are deducted every month regardless of whether
                premium outlays are made. Depending upon actual policy
                experience, the Owner may need to increase premium payments to
                maintain coverage for the desired duration. Any policy loans and
                partial surrenders will affect policy values and may require
                additional premiums to avoid policy termination.
                <br />
                Life insurance guarantees rely on the fiscal strength and claims
                paying ability of the issuing insurer. Universal life insurance
                products are not bank or FDIC insured. Indexed Universal Life
                insurance products are not an investment in the stock market and
                are subject to all policy fees and charges associated with
                Universal Life policies.
                <br />
                ARIA Financial Software, LLC does not provide tax, legal or
                accounting advice, nor is it designed ‐ nor intended ‐ to be
                appliable to any person's individual circumstances. This
                material has been prepared for information purposes only, and is
                not intended to provide, and should not be relied on for, tax,
                legal or accounting advice. You should consult your own
                qualified tax, legal and accounting advisors before engaging in
                any transaction.
                <br />
            </span>
        </Card>
    )
}

export default AtapIndexedUniversalLife
