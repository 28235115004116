import React from "react";
import ProposalForm from "./form.js";

const ProposalEdit = props => {
  return (
    <section>
      <ProposalForm {...props} buttonText="Update Proposal" />
    </section>
  );
};

export default ProposalEdit;
