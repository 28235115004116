import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import RouteButton from '../shared_components/route_button'
import Account from '../shared_components/proposal_account'
import AnnuityForm from '../shared_components/proposal_annuity'
import TaxForm from '../shared_components/proposal_tax'

const valSchema = yup.object().shape({
    annuity: yup.object().shape({
        rollUpIr: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
        incomeBonus: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
        premiumBonus: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
        withdrawalRate: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
        roiIr: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
        fiaStartingBalance: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .required('Required'),
    }),
    tax: yup.object().shape({
        currentIncome: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .required('Required'),
        currentFederalTaxRate: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
        capitalGainsTax: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
        currentStateTaxRate: yup
            .number()
            .typeError('Must be a number')
            .min(0)
            .max(100)
            .required('Required'),
    }),
    investments: yup.array().of(
        yup.object().shape({
            useMmarketRate: yup.boolean(),
            roi: yup.number().typeError('Must be a number').min(0).max(100),
            mgmtFee: yup
                .number()
                .typeError('Must be a number')
                .min(0)
                .required('Required'),
            salesChg: yup
                .number()
                .typeError('Must be a number')
                .min(0)
                .required('Required'),
        })
    ),
})

const ProposalForm = (props) => {
    const { data, loading, error, submitHandler, buttonText } = props
    if (loading) return <div>Loading...</div>
    if (error) return <div>error</div>

    return (
        <Formik
            validationSchema={valSchema}
            isInitialValid={() =>
                valSchema.isValid({
                    ...data,
                })
            }
            initialValues={{
                client: data.client,
                account: data.account,
                annuity: data.annuity,
                tax: data.tax,
                investments: data.investments,
            }}
            onSubmit={(values) => {
                submitHandler(values)
            }}
        >
            {({ values, touched, errors, isValid }) => (
                <Form>
                    <div className="row">
                        <div className="col-md-4">
                            <Account
                                values={values}
                                touched={touched}
                                errors={errors}
                            />
                        </div>
                        <div className="col-md-4">
                            <AnnuityForm
                                values={values}
                                touched={touched}
                                errors={errors}
                            />
                        </div>
                        <div className="col-md-4">
                            <TaxForm
                                values={values}
                                touched={touched}
                                errors={errors}
                            />
                        </div>
                        <div className="col-md-12">
                            {/* <Investments values={values} touched={touched} errors={errors} loading={loading} /> */}
                        </div>
                        <div className="col-md-12 form-group">
                            <RouteButton
                                path="/proposals"
                                name="Cancel"
                                className="btn btn-danger pull-left"
                            />
                            <button
                                type="submit"
                                className="btn btn-primary pull-right"
                                disabled={!isValid}
                            >
                                {buttonText || 'Create Proposal'}
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ProposalForm
