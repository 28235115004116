import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { Table, Divider } from 'antd'
import { Link } from 'react-router-dom'

import './shared.css'

const ListProposals = (props) => {
    const { data, loading, count, page, pageChangeHandler } = props

    const columns = [
        {
            title: 'Date',
            dataIndex: 'proposalDate',
            key: 'proposal_date',
            render: (proposal_date) =>
                moment.utc(proposal_date).format('MM/DD/YYYY'),
        },
        {
            title: 'Customer',
            dataIndex: 'customerName',
            key: 'customer__last_name',
        },
        {
            title: 'Balance',
            dataIndex: 'accountBalance',
            key: 'account_balance',
            render: (account_balance) =>
                numeral(account_balance).format('0,000'),
        },
        {
            title: 'Creator',
            dataIndex: 'createdBy',
            key: 'created_by',
        },
        {
            title: 'Last Modified',
            dataIndex: 'modifiedOn',
            key: 'modified_on',
            render: (modifiedOn) =>
                moment.utc(modifiedOn).format('MM/DD/YYYY LT'),
            sorter: (a, b) => a.modifiedOn.length - b.modifiedOn.length,
        },
        {
            title: 'Action',
            key: 'original',
            render: (original) => (
                <span>
                    <Link to={'/proposals/' + original.id}>View</Link>
                    <Divider type="vertical" />
                    <Link to={'/proposals/edit/' + original.id}>Edit</Link>
                </span>
            ),
        },
    ]

    const curr_pagination = {
        position: 'bottom',
        current: page + 1,
        defaultPageSize: 25,
        total: count,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['25', '50', '100'],
    }

    return (
        <div>
            <Table
                loading={loading}
                rowKey="id"
                pagination={curr_pagination}
                onChange={pageChangeHandler}
                columns={columns}
                rowClassName={(record, index) => {
                    return (index + 1) % 2 === 0 ? 'table-alt-row' : ''
                }}
                dataSource={data}
            />
        </div>
    )
}

export default ListProposals
