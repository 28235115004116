/*
 * Vinod <vmohanan@coretechs.com>
 * Thu Aug 16 2019
 */
import { Card, InputNumber, Row } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requestArbitrage } from '../actions'
import EditableTable from '../components/arbitrage/table'

class ArbitragePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loanAmount: 40000,
        }
        this.loanAmountChange = this.loanAmountChange.bind(this)
    }

    componentWillMount() {
        this.props.requestArbitrage()
    }

    loanAmountChange(amount) {
        this.setState({ loanAmount: amount })
    }

    render() {
        const { loading, data } = this.props
        const { loanAmount } = this.state

        return (
            <Row>
                <Card
                    bordered={false}
                    extra={
                        <div>
                            <strong>Loan amount:&nbsp;</strong>
                            <InputNumber
                                style={{ width: 150 }}
                                value={this.state.loanAmount}
                                formatter={(value) =>
                                    `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                onChange={this.loanAmountChange}
                            />
                        </div>
                    }
                    title={'Arbitrage'}
                >
                    <EditableTable
                        loading={loading}
                        dataSource={data}
                        loanAmount={loanAmount}
                    />
                </Card>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.arbitrage,
    }
}

export default connect(mapStateToProps, { requestArbitrage })(ArbitragePage)
