import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestAriaV2s } from '../actions'
import AriaV2List from '../components/aria-v2/list'

const AriaV2sPage = (props) => {
    useEffect(() => {
        props.requestAriaV2s(props.page, props.pageSize, props.sorted)
    }, [])

    const handleTableChange = (pagination, filters, sorter) => {
        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        props.requestAriaV2s(
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    return <AriaV2List pageChangeHandler={handleTableChange} {...props} />
}

const mapStateToProps = (state) => {
    return {
        ...state.ariav2s,
    }
}

export default connect(mapStateToProps, { requestAriaV2s })(AriaV2sPage)
