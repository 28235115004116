/*
 * Created by Sina Khelil on 25/05/18.
 */
import { Col, Row } from 'antd'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import BSField from '../shared_components/bs_field'
import GroupedSelect from '../shared_components/grouped-select'
import RouteButton from '../shared_components/route_button'

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
}

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
)

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const valSchema = yup.object().shape({
    selectedAgent: yup
        .object()
        .typeError('Please select and agent.')
        .required('This field is required'),
    firstName: yup
        .string()
        .min(2, 'First Name must be at least 2 characters long.')
        .required('This field is required'),
    lastName: yup
        .string()
        .min(2, 'First Name must be at least 2 characters long.')
        .required('This field is required'),
    currentAge: yup
        .number()
        .typeError('Not a valid number, please correct')
        .max(100)
        .required('This field is required'),
    spouseAge: yup
        .number()
        .typeError('Not a valid number, please correct')
        .max(100)
        .nullable()
        .notRequired(),
    gender: yup
        .number()
        .oneOf([1, 2], 'Please select either male or female')
        .required('This field is required'),
    filingStatus: yup
        .number()
        .oneOf([1, 2, 3, 4], 'please select a filing status')
        .required('This field is required'),
    state: yup.string().min(2).max(2).required('This field is required'),
    email: yup.string().email('Must be a valid email address'),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    postal: yup.string().min(5).max(5).notRequired(),
})

const ClientForm = (props) => {
    const { data, states, agenciesAgents, submitHandler } = props
    if (agenciesAgents.loading || states.loading || data.loading) {
        return <div>Loading...</div>
    } else {
        const statesList = states.data
        return (
            <div>
                <Formik
                    validationSchema={valSchema}
                    initialValues={{
                        agent: data.agent || undefined,
                        selectedAgent: data.selectedAgent || undefined,
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        currentAge: data.currentAge || '',
                        gender: data.gender || 0,
                        filingStatus: data.filingStatus || 0,
                        spouseFirstName: data.spouseFirstName || '',
                        spouseLastName: data.spouseLastName || '',
                        spouseAge: data.spouseAge || undefined,
                        address: data.address || '',
                        address2: data.address2 || '',
                        city: data.city || '',
                        state: data.state || '',
                        postal: data.postal || '',
                        phone: data.phone || '',
                        fax: data.fax || '',
                        email: data.email || '',
                    }}
                    onSubmit={(values) => {
                        if (values.spouseAge !== undefined)
                            if (values.spouseAge.length === 0) {
                                values.spouseAge = null
                            }
                        submitHandler(values)
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        isValid,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <Form>
                            <Row gutter={16}>
                                <GroupedSelect
                                    name="selectedAgent"
                                    id="selectedAgent"
                                    touched={touched.selectedAgent}
                                    error={errors.selectedAgent}
                                    options={agenciesAgents.data}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={(value) => {
                                        setFieldValue('selectedAgent', value)
                                        setFieldValue('agent', value.value)
                                    }}
                                    onBlur={() =>
                                        setFieldTouched('selectedAgent')
                                    }
                                    value={values.selectedAgent}
                                    autoFocus={true}
                                />
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <BSField
                                        touched={touched.firstName}
                                        error={errors.firstName}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="firstName"
                                        >
                                            First Name *
                                        </label>
                                        <Field
                                            name="firstName"
                                            className="form-control"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.lastName}
                                        error={errors.lastName}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="lastName"
                                        >
                                            Last Name *
                                        </label>
                                        <Field
                                            name="lastName"
                                            className="form-control"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.currentAge}
                                        error={errors.currentAge}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="currentAge"
                                        >
                                            Current Age *
                                        </label>
                                        <Field
                                            name="currentAge"
                                            className="form-control"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.gender}
                                        error={errors.gender}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="gender"
                                        >
                                            Gender *
                                        </label>
                                        <Field
                                            name="gender"
                                            className="form-control"
                                            component="select"
                                        >
                                            {!values.gender && (
                                                <option key={0} value="0">
                                                    ---
                                                </option>
                                            )}
                                            <option key={1} value="1">
                                                Male
                                            </option>
                                            <option key={2} value="2">
                                                Female
                                            </option>
                                        </Field>
                                    </BSField>
                                    <BSField
                                        touched={touched.filingStatus}
                                        error={errors.filingStatus}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="filingStatus"
                                        >
                                            Filing Status *
                                        </label>
                                        <Field
                                            name="filingStatus"
                                            className="form-control"
                                            component="select"
                                        >
                                            {!values.filingStatus && (
                                                <option key={0} value="0">
                                                    ---
                                                </option>
                                            )}
                                            <option key={1} value="1">
                                                Single
                                            </option>
                                            <option key={2} value="2">
                                                Married filing Jointly
                                            </option>
                                            <option key={3} value="3">
                                                Married filing separately
                                            </option>
                                            <option key={4} value="4">
                                                Head of Household
                                            </option>
                                        </Field>
                                    </BSField>
                                </Col>
                                <Col span={12}>
                                    <BSField
                                        touched={touched.spouseFirstName}
                                        error={errors.spouseFirstName}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="spouseFirstName"
                                        >
                                            Spouse First Name
                                        </label>
                                        <Field
                                            name="spouseFirstName"
                                            className="form-control"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.spouseLastName}
                                        error={errors.spouseLastName}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="spouseLastName"
                                        >
                                            Spouse Last Name
                                        </label>
                                        <Field
                                            name="spouseLastName"
                                            className="form-control"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.spouseAge}
                                        error={errors.spouseAge}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="spouseAge"
                                        >
                                            Spouse Age
                                        </label>
                                        <Field
                                            name="spouseAge"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <BSField
                                        touched={touched.address}
                                        error={errors.address}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="address"
                                        >
                                            Address
                                        </label>
                                        <Field
                                            name="address"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                                <Col span={12}>
                                    <BSField
                                        touched={touched.address2}
                                        error={errors.address2}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="address2"
                                        >
                                            Address 2
                                        </label>
                                        <Field
                                            name="address2"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <BSField
                                        touched={touched.city}
                                        error={errors.city}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="city"
                                        >
                                            City
                                        </label>
                                        <Field
                                            name="city"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                                <Col span={8}>
                                    <BSField
                                        touched={touched.state}
                                        error={errors.state}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="state"
                                        >
                                            State *
                                        </label>
                                        <Field
                                            name="state"
                                            className="form-control"
                                            component="select"
                                        >
                                            {!values.state && (
                                                <option key={0} value="0">
                                                    ---
                                                </option>
                                            )}
                                            {statesList.map((item, idx) => (
                                                <option
                                                    key={idx}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Field>
                                    </BSField>
                                </Col>
                                <Col span={8}>
                                    <BSField
                                        touched={touched.postal}
                                        error={errors.postal}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="postal"
                                        >
                                            Zip
                                        </label>
                                        <Field
                                            name="postal"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <BSField
                                        touched={touched.phone}
                                        error={errors.phone}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="phone"
                                        >
                                            Phone
                                        </label>
                                        <Field
                                            name="phone"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                                <Col span={8}>
                                    <BSField
                                        touched={touched.fax}
                                        error={errors.fax}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="fax"
                                        >
                                            Fax
                                        </label>
                                        <Field
                                            name="fax"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                                <Col span={8}>
                                    <BSField
                                        touched={touched.email}
                                        error={errors.email}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="email"
                                        >
                                            Email
                                        </label>
                                        <Field
                                            name="email"
                                            className="form-control"
                                        />
                                    </BSField>
                                </Col>
                                <div className="form-group">
                                    <RouteButton
                                        path="/clients"
                                        name="Cancel"
                                        className="btn btn-danger"
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-primary pull-right"
                                        disabled={!isValid}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default ClientForm
