import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IncomeCompAddModel, CustomerModel } from '..'

import {
    requestCalcClients,
    requestResetIncomeComp,
    requestCreateCalcClient,
    requestCreateIncomeComp,
    requestSetIncomeCompClient,
} from '../actions'
import ClientSelect from '../components/client_select'
import IncomeCompForm from '../components/incomeComp/form'
import Loading from '../components/shared_components/loading'

interface IncomeCompAddPageProps extends IncomeCompAddModel {
    clients: {
        data: CustomerModel[]
        loading: boolean
    }
    requestCreateIncomeComp: Function
    requestCalcClients: Function
    requestCreateCalcClient: Function
    requestResetIncomeComp: Function
    requestSetIncomeCompClient: Function
}

const IncomeCompAddPage = (props: IncomeCompAddPageProps): JSX.Element => {
    const title = 'Income Tax Comparison - Add'
    const {
        data,
        loading,
        requestResetIncomeComp,
        requestSetIncomeCompClient,
    } = props

    useEffect(() => {
        requestResetIncomeComp()
    }, [])

    const setSelectedClient = (customer: CustomerModel) => {
        requestSetIncomeCompClient(customer)
    }

    if (!data || loading) return <Loading title={title} />
    else if (data.customer === undefined)
        return (
            <ClientSelect
                title={title}
                setSelectedClient={setSelectedClient}
                {...props}
            />
        )
    else
        return (
            <IncomeCompForm
                title={title}
                submitHandler={props.requestCreateIncomeComp}
                {...props}
            />
        )
}

const mapStateToProps = (state: { incomeComp: any; calcClients: any }) => {
    return {
        ...state.incomeComp,
        clients: state.calcClients,
    }
}

export default connect(mapStateToProps, {
    requestCreateIncomeComp,
    requestCalcClients,
    requestCreateCalcClient,
    requestResetIncomeComp,
    requestSetIncomeCompClient,
})(IncomeCompAddPage)
