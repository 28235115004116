import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import isEmpty from 'lodash/isEmpty'
import BSField from '../shared_components/bs_field'
import RouteButton from '../shared_components/route_button'

const valSchema = yup.object().shape({
    company: yup
        .string()
        .min(3, 'Company must be at least 3 characters long.')
        .required('Company is required.'),
    state: yup.string().required('State is a required field'),
    email: yup.string().email('Must be a valid email address'),
    phone: yup.string().max(10),
    fax: yup.string().max(10),
    postal: yup.string().min(5).max(5),
})

const AgencyForm = (props) => {
    const { data, loading, error, states, handleSubmit } = props
    if (error || states.error)
        return (
            <div>
                An error occurred. It has been reported to our support team.
            </div>
        )
    if (loading || states.loading) return <div>Loading...</div>
    return (
        <div>
            <Formik
                validationSchema={valSchema}
                initialValues={{
                    company: data.company || '',
                    address: data.address || '',
                    address2: data.address2 || '',
                    city: data.city || '',
                    state: data.state || '',
                    postal: data.postal || '',
                    phone: data.phone || '',
                    fax: data.fax || '',
                    email: data.email || '',
                }}
                onSubmit={(values, actions) => {
                    handleSubmit(values)
                }}
            >
                {({ values, touched, errors, dirty, isSubmitting }) => (
                    <Form>
                        <BSField
                            touched={touched.company}
                            error={errors.company}
                        >
                            <label className="control-label" htmlFor="company">
                                Company
                            </label>
                            <Field name="company" className="form-control" />
                        </BSField>
                        <BSField
                            touched={touched.address}
                            error={errors.address}
                        >
                            <label className="control-label" htmlFor="address">
                                Address
                            </label>
                            <Field name="address" className="form-control" />
                        </BSField>
                        <BSField
                            touched={touched.address2}
                            error={errors.address2}
                        >
                            <label className="control-label" htmlFor="address2">
                                Address 2
                            </label>
                            <Field name="address2" className="form-control" />
                        </BSField>
                        <BSField touched={touched.city} error={errors.city}>
                            <label className="control-label" htmlFor="city">
                                City
                            </label>
                            <Field name="city" className="form-control" />
                        </BSField>
                        <BSField touched={touched.state} error={errors.state}>
                            <label className="control-label" htmlFor="state">
                                State
                            </label>
                            <Field
                                name="state"
                                className="form-control"
                                component="select"
                            >
                                (!values.state &&{' '}
                                <option key={99} value={undefined}>
                                    --- Select State ---
                                </option>
                                )
                                {states &&
                                    states.data.map((state, idx) => (
                                        <option key={idx} value={state.id}>
                                            {state.name}
                                        </option>
                                    ))}
                            </Field>
                        </BSField>
                        <BSField touched={touched.postal} error={errors.postal}>
                            <label className="control-label" htmlFor="postal">
                                Zip
                            </label>
                            <Field name="postal" className="form-control" />
                        </BSField>
                        <BSField touched={touched.phone} error={errors.phone}>
                            <label className="control-label" htmlFor="phone">
                                Phone
                            </label>
                            <Field name="phone" className="form-control" />
                        </BSField>
                        <BSField touched={touched.fax} error={errors.fax}>
                            <label className="control-label" htmlFor="fax">
                                Fax
                            </label>
                            <Field name="fax" className="form-control" />
                        </BSField>
                        <BSField touched={touched.email} error={errors.email}>
                            <label className="control-label" htmlFor="email">
                                Email
                            </label>
                            <Field name="email" className="form-control" />
                        </BSField>
                        <div className="form-group">
                            <RouteButton
                                path="/agencies"
                                name="Cancel"
                                className="btn btn-danger"
                            />
                            <button
                                type="submit"
                                className="btn btn-primary pull-right"
                                disabled={
                                    isSubmitting || !isEmpty(errors) || !dirty
                                }
                            >
                                Save
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AgencyForm
