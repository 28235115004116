import React from 'react'
import { saveAs } from 'file-saver'
import { useParams } from 'react-router-dom'

import './view.css'

const ProposalPDFView = () => {
    const { proposalId } = useParams()

    const token = localStorage.getItem('token')
    fetch(`/api/v1/proposals/${proposalId}/pdf/`, {
        headers: { Authorization: `JWT ${token}` },
    })
        .then((response) => response.blob())
        .then((blob) => {
            saveAs(blob)
        })
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">Proposal PDF View</h3>
            </div>
            <div className="panel-body">Downloading</div>
        </div>
    )
}

export default ProposalPDFView
