import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { requestAgent, requestStates, requestUpdateAgent } from '../actions'
import AgentEdit from '../components/agent/edit'

const AgentEditPage = (props) => {
    const params = useParams()
    const history = useHistory()
    useEffect(() => {
        props.requestStates()
        props.requestAgent(params.agentId)
    }, [])

    const updateAgent = (agencyId, values) => {
        props.requestUpdateAgent(params.agentId, { ...values, history })
    }

    return <AgentEdit {...props} handleSubmit={updateAgent} />
}

const mapStateToProps = (state) => {
    return {
        ...state.agent,
        states: state.states,
    }
}

export default connect(mapStateToProps, {
    requestAgent,
    requestStates,
    requestUpdateAgent,
})(AgentEditPage)
