import { Card, Tabs } from 'antd'
import React from 'react'
import { useAuth } from '../../use_auth'
import LoadingField from '../../shared_components/loading_field'
import ProposalLinks from './proposal_links'
import ProposalEmailForm from './proposal_send_email'
const { TabPane } = Tabs

const ProposalActions = (props) => {
    const auth = useAuth()

    const {
        client,
        proposal,
        links,
        downloadPDF,
        requestProposalLinkByEmail,
        loading,
        emails,
    } = props

    return (
        <Card title="Proposal Actions">
            <div className="row">
                <div className="col-md-6">
                    <Tabs defaultActiveKey={'1'}>
                        <TabPane tab="Send Proposal Links" key="1">
                            <ProposalEmailForm
                                emails={emails}
                                onSend={requestProposalLinkByEmail}
                            />
                        </TabPane>
                        {auth.roles.includes('REPORT') && (
                            <TabPane tab="Proposal Links" key="2">
                                <ProposalLinks
                                    links={links}
                                    loading={loading}
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </div>
                <div className="col-md-2 col-md-offset-1">
                    Reference ID: {!loading && proposal.referenceId}
                    {loading && <LoadingField width={60} />}
                </div>
                <div className="col-md-3">
                    <Card>
                        <button
                            className="btn btn-default btn-sm"
                            onClick={() => downloadPDF(client.fullName)}
                            title="Download PDF"
                            disabled={loading}
                        >
                            <i className="fa fa-file-pdf-o" /> Download Classic
                            PDF
                        </button>
                    </Card>
                </div>
            </div>
        </Card>
    )
}

ProposalActions.displayName = 'ProposalActions'

export default ProposalActions
