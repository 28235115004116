import React from "react";
import moment from "moment";
import numeral from "numeral";
import { Card } from "antd";

const getIndexValue = (indexValues, idx) => {
  const indexValuesCount = Object.keys(indexValues).length;
  const indexValuesIdx = idx >= indexValuesCount ? idx - indexValuesCount * Math.floor(idx / indexValuesCount) : idx;
  return parseFloat(indexValues[indexValuesIdx]) || 0;
};

const TaxFreeIllustration = props => {
  const { proposal, illustration, indexValues, planType, file } = props;
  let investmentData = [];
  if (proposal.hasOwnProperty(file)) {
    investmentData = proposal[`${file}`].investmentData;
  }
  return (
    <div className="center">

      <Card className="container800" title={`Stress Test - Alternative Tax Free Plan - ${planType}`}
        bordered={false}
        headStyle={{ color: '#003e80', fontWeight: 'bold', fontSize: 24, textAlign: 'center', borderBottomColor: '#003e80' }}
      >
        <div className="center">
          <table className="table table-600 table-noborder table-hover table-condensed">
            <thead>
              <tr>
                {proposal.illustration && <th className="table-th-blue table-th-bordertopleft">Index Return(%)</th>}
                <th className="table-th-blue table-th-bordertop">Age</th>
                <th className="table-th-blue table-th-bordertop">Year</th>
                <th className="table-th-blue table-th-bordertop">Premium Payment</th>
                <th className="table-th-blue table-th-bordertopleft">Loan Withdrawn</th>
                <th className="table-th-blue table-th-bordertop">Accumulation Value</th>
                <th className="table-th-blue table-th-bordertop">Cash Value*</th>
                <th className="table-th-blue table-th-bordertopright">Death Benefit</th>
              </tr>
            </thead>
            <tbody>
              {investmentData.map((dataRow, index) => {
                return (
                  <tr key={index}>
                    {proposal.illustration && (
                      <td className="currency table-td-borderleft">{numeral(getIndexValue(indexValues, index) / 100).format("0,0.00%")}</td>
                    )}
                    <td className="currency">{dataRow.age}</td>
                    <td className="currency">{moment(illustration.proposalDate).year() + index}</td>
                    <td className="currency">{numeral(dataRow["taxFreePlan"]["invest"]).format("0,0")}</td>
                    <td className="currency table-td-borderleft">{numeral(dataRow["taxFreePlan"]["withdraw"]).format("0,0")}</td>
                    <td className="currency">{numeral(dataRow["taxFreePlan"]["accumulation"]).format("0,0")}</td>
                    <td className="currency">{numeral(dataRow["taxFreePlan"]["surrender"]).format("0,0")}</td>
                    <td className="currency table-td-borderright">{numeral(dataRow["taxFreePlan"]["deathBenefit"]).format("0,0")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
      <div style={{ display: 'flex', justifyContent: 'center' }}>

        <div className="disclaimer-800">
          <p>
            *Cash Value does not reflect carrier surrender charges. It does reflect the net amount available after total loans are deducted from the Accumulation Value.  Numbers can vary significantly from the carrier illustration that includes surrender charges.
          </p>
          <p>
            Index returns assume {illustration.marketIndexDesc.trim()}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TaxFreeIllustration;
