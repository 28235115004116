import { Button, Card, DatePicker, Divider, Form, Popover, Table } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { requestProposalLinks } from '../../actions'
import IPAddressDetail from '../../components/shared_components/ip_address_details'
// import { downloadProposalLinkViews } from '../../services/proposal.service'

const { RangePicker } = DatePicker

const ProposalLinks = (props) => {
    // const [dateRange, setDateRange] = useState([])

    useEffect(() => {
        props.requestProposalLinks({
            page: props.page,
            per_page: props.pageSize,
            sorted: props.sorted,
        })
    }, [])

    const handleSubmit = () => {}

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'linkViewer',
                key: 'linkViewer',
                render: (linkViewer) => linkViewer.name,
            },
            {
                title: 'Email',
                dataIndex: 'linkViewer',
                key: 'linkViewer',
                render: (linkViewer) => linkViewer.email,
            },
            {
                title: 'IP Address',
                dataIndex: 'ipaddress',
                key: 'ipaddress',
                render: (ipaddress) => (
                    <Popover
                        content={<IPAddressDetail {...ipaddress} />}
                        title={ipaddress.ipaddress}
                    >
                        <Button type="link">{ipaddress.ipaddress}</Button>
                    </Popover>
                ),
            },
            {
                title: 'View Date',
                dataIndex: 'viewDate',
                key: 'viewDate',
                render: (viewDate) => moment(viewDate).format('MM/DD/YYYY'),
            },
        ]

        return (
            <Table
                size="small"
                bordered={false}
                columns={columns}
                dataSource={record.linkViews}
                pagination={false}
            />
        )
    }

    const pageChangeHandler = (state) => {
        props.requestProposalLinks({
            page: state.current - 1,
            per_page: state.pageSize,
            sorting: null,
        })
    }

    // const onDownload = () => {
    //     downloadProposalLinkViews({})
    //         .then((res) => {})
    //         .catch((err) => alert('err'))
    // }

    const onDateRangeChange = (date, dateString) => {
        // setDateRange(dateString)
        props.requestProposalLinks({
            page: 0,
            per_page: props.pageSize,
            sorting: null,
            send_date_range: date.length ? dateString : null,
        })
    }

    const { links, count, page, loading } = props

    const curr_pagination = {
        position: 'bottom',
        current: page + 1,
        defaultPageSize: 25,
        total: count,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['25', '50', '100'],
    }

    const columns = [
        {
            title: 'Agent',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (createdBy) => (
                <Button type="link">{createdBy.username}</Button>
            ),
        },
        {
            title: 'Send Date',
            dataIndex: 'sendDate',
            key: 'sendDate',
            render: (sendDate) => moment(sendDate).format('MM/DD/YYYY'),
        },
        {
            title: 'Customer Name',
            dataIndex: 'proposal',
            key: 'proposal',
            render: (proposal) => (proposal ? proposal.customerName : ''),
        },
        { title: 'Send To', dataIndex: 'email', key: 'email' },
        {
            title: 'Action',
            render: (record, index) => (
                <span>
                    <a
                        href={`/proposal/${record.linkUuid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View Link
                    </a>
                    <Divider type="vertical" />
                    <a
                        href={`/proposals/${
                            record.proposal ? record.proposal.id : ''
                        }`}
                    >
                        View Proposal
                    </a>
                </span>
            ),
        },
    ]

    return (
        <Card
            extra={
                <Form layout="inline" onSubmit={handleSubmit}>
                    <Form.Item label={`Send Date`}>
                        <RangePicker onChange={onDateRangeChange} />
                    </Form.Item>
                </Form>
            }
            bordered={false}
        >
            <Table
                columns={columns}
                loading={loading}
                pagination={curr_pagination}
                onChange={(state) => pageChangeHandler(state)}
                expandedRowRender={(record) => expandedRowRender(record)}
                dataSource={links}
            />
        </Card>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.proposals,
    }
}

export default connect(mapStateToProps, { requestProposalLinks })(ProposalLinks)
