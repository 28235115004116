import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { Card } from 'antd'

const getIndexValue = (indexValues, idx) => {
    const indexValuesCount = Object.keys(indexValues).length
    const indexValuesIdx =
        idx >= indexValuesCount
            ? idx - indexValuesCount * Math.floor(idx / indexValuesCount)
            : idx
    return parseFloat(indexValues[indexValuesIdx]) || 0
}

const VsAssetLongevityNonQual = (props) => {
    const { nonqualified, marketValues, illustration } = props

    return (
        <div className="center">
            <Card
                className="container800"
                title={`Asset Longevity Comparison - Non-Qualified`}
                bordered={false}
                headStyle={{
                    color: '#003e80',
                    fontWeight: 'bold',
                    fontSize: 24,
                    textAlign: 'center',
                    borderBottomColor: '#003e80',
                }}
            >
                <div className="center">
                    <table className="table table-800 table-noborder table-hover">
                        <thead>
                            <tr>
                                <th colSpan="7">
                                    <i>Account</i>
                                </th>
                                <th className="table-th-space">&nbsp;</th>
                                <th colSpan="5">
                                    <i>Alternative Tax Free</i>
                                </th>
                            </tr>
                            <tr>
                                <th className="table-th-dk-gray table-th-bordertopleft">
                                    Age
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Index
                                    <br />
                                    Returns
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Gross
                                    <br />
                                    Withdrawals
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Taxes @
                                    <br />
                                    {numeral(illustration.taxRate / 100).format(
                                        '0,0.00%'
                                    )}
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Net income
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Crediting
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Account Value
                                </th>
                                <th className="table-th-line table-th-bordertopright">
                                    &nbsp;
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Index
                                    <br />
                                    Returns
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Crediting
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Tax Free
                                    <br />
                                    Income
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Cash Value
                                </th>
                                <th className="table-th-blue table-th-bordertopright">
                                    Family Benefit
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    colSpan="6"
                                    className="currency table-td-borderleft"
                                >
                                    Beginning Value (
                                    {moment(illustration.proposalDate).format(
                                        'LL'
                                    )}
                                    )
                                </td>
                                <td className="currency">
                                    {numeral(nonqualified.initValue).format(
                                        '0,0'
                                    )}
                                </td>
                                <td className="currency table-td-borderright"></td>
                                <th
                                    colSpan="5"
                                    className="table-th-space table-td-borderright"
                                >
                                    &nbsp;
                                </th>
                            </tr>
                            {nonqualified.rows.map((dataRow, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="currency table-td-borderleft">
                                            {dataRow.age}
                                        </td>
                                        <td className="currency">
                                            {numeral(
                                                getIndexValue(
                                                    marketValues,
                                                    index
                                                ) / 100
                                            ).format('0,0.00%')}
                                        </td>
                                        <td className="currency">
                                            {dataRow.transaction}
                                        </td>
                                        <td className="currency">
                                            {dataRow.taxes}
                                        </td>
                                        <td className="currency">
                                            {dataRow.netIncome}
                                        </td>
                                        <td className="currency">
                                            {dataRow.interest}
                                        </td>
                                        <td className="currency">
                                            {dataRow.accountValue}
                                        </td>
                                        <td className="currency table-td-borderright">
                                            &nbsp;
                                        </td>
                                        <td className="currency">
                                            {dataRow.atfIndexReturns}
                                        </td>
                                        <td className="currency">
                                            {dataRow.atfInterest}
                                        </td>
                                        <td className="currency">
                                            {dataRow.withdraw}
                                        </td>
                                        <td className="currency">
                                            {dataRow.surrender}
                                        </td>
                                        <td className="currency table-td-borderright">
                                            {dataRow.familyBenefit}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="5" className="currency">
                                    Total Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    ${' '}
                                    {numeral(nonqualified.iraTotalDist).format(
                                        '0,0'
                                    )}
                                </td>
                                <td colSpan="4" className="currency">
                                    Total Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    ${' '}
                                    {numeral(nonqualified.tfpTotalDist).format(
                                        '0,0'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="currency">
                                    Total Taxes:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    ${' '}
                                    {numeral(nonqualified.iraTotalTax).format(
                                        '0,0'
                                    )}
                                </td>
                                <td colSpan="4" className="currency">
                                    Total Taxes:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    $ 0
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="currency">
                                    Net Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    ${' '}
                                    {numeral(nonqualified.iraNetDist).format(
                                        '0,0'
                                    )}
                                </td>
                                <td colSpan="4" className="currency">
                                    Net Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    ${' '}
                                    {numeral(nonqualified.tfpTotalDist).format(
                                        '0,0'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="currency">
                                    Family Benefit:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    $ 0
                                </td>
                                <td colSpan="4" className="currency">
                                    Family Benefit:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    ${' '}
                                    {numeral(nonqualified.tfpFamBeni).format(
                                        '0,0'
                                    )}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </Card>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="disclaimer-800">
                    <p>
                        *Cash Value does not reflect carrier surrender charges.
                        It does reflect the net amount available after total
                        loans are deducted from the Accumulation Value. Numbers
                        can vary significantly from the carrier illustration
                        that includes surrender charges.
                    </p>
                    <p>
                        Market returns assume {illustration.marketRateName}{' '}
                        Annual Returns Years 2001-2018 plus 1.5% management fee,
                        excluding dividends
                    </p>
                    <p>
                        Index returns assume{' '}
                        {illustration.marketIndexDesc.trim()}.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VsAssetLongevityNonQual
