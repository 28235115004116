import React from "react";
import Select from "react-select";

const GroupedSelect = props => {
  const { touched, error } = props;
  return (
    <div className={"form-group has-feedback " + (touched ? (error ? " has-error" : " has-success") : "")}>
      <Select {...props} />
      {touched && !error && <span className="glyphicon glyphicon-ok form-control-feedback" />}
      {touched && error && <span className="glyphicon glyphicon-remove form-control-feedback" />}
      {error && touched && <div className="help-block">{error}</div>}
    </div>
  );
};

export default GroupedSelect;
