import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { clientService } from '../services/client.service'

const {
    CLIENTS_GET_REQUEST,
    CLIENTS_GET_SUCCESS,
    CLIENTS_GET_FAILURE,
    CLIENT_GET_REQUEST,
    CLIENT_GET_SUCCESS,
    CLIENT_GET_FAILURE,
    CLIENT_UPDATE_REQUEST,
    CLIENT_CREATE_REQUEST,
    successUpdateClient,
    failedUpdatedClient,
    successCreateClient,
    failedCreateClient,
    successAlert,
    processErrorMessage,
} = actions

export function* getClients() {
    while (true) {
        const { page, perPage, sorted } = yield take(CLIENTS_GET_REQUEST)
        try {
            const response = yield call(clientService.getAllClients, {
                page,
                perPage,
                sorted,
            })
            yield put({ type: CLIENTS_GET_SUCCESS, ...response })
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put({ type: CLIENTS_GET_FAILURE, error })
        }
    }
}

export function* getClient() {
    while (true) {
        const { clientId } = yield take(CLIENT_GET_REQUEST)
        try {
            const response = yield call(clientService.getClient, clientId)
            yield put({ type: CLIENT_GET_SUCCESS, ...response.data })
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put({ type: CLIENT_GET_FAILURE, error })
        }
    }
}

export function* updateClient() {
    while (true) {
        const { clientId, payload } = yield take(CLIENT_UPDATE_REQUEST)
        try {
            const response = yield call(
                clientService.updateClient,
                clientId,
                payload
            )
            yield put(successUpdateClient(response))
            payload.history.push(`/clients/${clientId}`)
            yield put(successAlert('Client updated successfully'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdatedClient(error))
        }
    }
}

export function* createClient() {
    while (true) {
        const { payload } = yield take(CLIENT_CREATE_REQUEST)
        try {
            const response = yield call(clientService.createClient, payload)
            yield put(successCreateClient(response))
            payload.history.push(`/clients/${response.data.id}`)
            yield put(successAlert('Client created successfully'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateClient(error))
        }
    }
}
