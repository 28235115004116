import React from "react";
import { Field } from "formik";
import BSField from "./bs_field";

const TaxForm = props => {
  const { touched, errors } = props;
  return (
    <div className="panel panel-default panel-detail ">
      <div className="panel-heading">
        <h3 className="panel-title">Tax</h3>
      </div>
      <div className="panel-body form-horizontal">
        <BSField touched={touched.currentFederalTaxRate} error={errors.currentFederalTaxRate}>
          <label className="col-md-7 control-label">Current Federal Tax</label>
          <div className="col-md-5">
            <Field name="tax.currentFederalTaxRate" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.futureFederalTaxRate} error={errors.futureFederalTaxRate}>
          <label className="col-md-7 control-label">Future Federal Tax</label>
          <div className="col-md-5">
            <Field name="tax.futureFederalTaxRate" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.currentStateTaxRate} error={errors.currentStateTaxRate}>
          <label className="col-md-7 control-label">Current State Tax</label>
          <div className="col-md-5">
            <Field name="tax.currentStateTaxRate" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.futureStateTaxRate} error={errors.futureStateTaxRate}>
          <label className="col-md-7 control-label">Future State Tax</label>
          <div className="col-md-5">
            <Field name="tax.futureStateTaxRate" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.capitalGainsTax} error={errors.capitalGainsTax}>
          <label className="col-md-7 control-label">Capital Gains Tax</label>
          <div className="col-md-5">
            <Field name="tax.capitalGainsTax" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.currentIncome} error={errors.currentIncome}>
          <label className="col-md-7 control-label">Current Income</label>
          <div className="col-md-5">
            <Field name="tax.currentIncome" className="form-control" />
          </div>
        </BSField>
      </div>
    </div>
  );
};

export default TaxForm;
