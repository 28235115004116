import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AgentList from '../agent/list'
import LoadingField from '../shared_components/loading_field'

const AgencyView = (props) => {
    const { agencyId } = useParams()
    const { data, loading, error, agents, pageChangeHandler } = props
    if (error) return <div>An error Occurred. Support has bbeen notified.</div>
    return (
        <div className="panel panel-default">
            <div className="panel-heading text-center">
                <Link to="/agencies" className="pull-left">
                    <FontAwesomeIcon icon="chevron-left" /> Agencies
                </Link>
                &nbsp;
                <span className="panel-title">
                    {!loading && data.company}
                    {loading && <LoadingField width={60} />}
                </span>
            </div>
            <div className="panel-body">
                <div>
                    {!loading && data.address}
                    {loading && <LoadingField />}
                </div>
                <div>
                    {!loading && data.address2}
                    {loading && <LoadingField />}
                </div>
                <div>
                    {!loading && data.city}
                    {loading && <LoadingField width={60} />},&nbsp;
                    {!loading && data.state}
                    {loading && <LoadingField width={20} />}{' '}
                    {!loading && data.city}
                    {loading && <LoadingField width={40} />}
                </div>
                <div>
                    Phone: {!loading && data.phone}
                    {loading && <LoadingField width={60} />}
                </div>
                <div>
                    Fax: {!loading && data.fax}
                    {loading && <LoadingField width={60} />}
                </div>
                <div>
                    Email: {!loading && data.email}
                    {loading && <LoadingField width={60} />}
                </div>
            </div>
            <div className="panel-heading">
                <span className="h4 panel-title text-center">Agents</span>
                <Link
                    to={`/agencies/${agencyId}/agents/add`}
                    className="btn btn-default btn-xs pull-right"
                >
                    <FontAwesomeIcon icon="plus-square" /> New...
                </Link>
            </div>
            <div className="panel-body">
                <AgentList
                    agents={agents}
                    agencyId={agencyId}
                    pageChangeHandler={pageChangeHandler}
                />
            </div>
        </div>
    )
}

export default AgencyView
