import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableRowActions = ({ viewLink, editLink }) => {
  return (
    <div>
      {viewLink && (
        <Link to={viewLink} className="btn btn-default btn-xs">
          <FontAwesomeIcon icon="eye" /> View
        </Link>
      )}
      {editLink && (
        <Link to={editLink} className="btn btn-default btn-xs">
          <FontAwesomeIcon icon="edit" /> Edit
        </Link>
      )}
    </div>
  );
};

export default TableRowActions;
