import { Card, Col, Row } from "antd";
import numeral from 'numeral';
import React, { Component } from "react";

class EstatePreservation extends Component {
  render() {
    return (
      <Card
        bordered={false}
        title="Estate Preservation"
      >
        <div>*Estate planning, legacy, funeral expenses, etc. = Estate Preservation Need</div>
        <br />
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12} style={{ textAlign: 'center' }}>
            Age Factor (Years)
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            {this.props.factor || '...'}
          </Col>
        </Row>
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12}>
            <b>Estate Preservation Death Benefit (Household)</b>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <b>{numeral(this.props.maxDeathBenefit).format("$ 0,0") || '...'}</b>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default EstatePreservation;
