import React from 'react'
import { connect } from 'react-redux'

import { requestIncomeComps } from '../actions'
import IncomeCompList from '../components/incomeComp/list'

const IncomePage = (props) => {
    return <IncomeCompList {...props} />
}

const mapStateToProps = (state) => {
    return {
        ...state.incomeComps,
    }
}

export default connect(mapStateToProps, { requestIncomeComps })(IncomePage)
