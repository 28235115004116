import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    requestAgent,
    requestAgentClients,
    requestAgentProposals,
} from '../actions'
import AgentView from '../components/agent/view'

const AgentViewPage = (props) => {
    const params = useParams()

    const customersPageChangeHandler = (pagination, filters, sorter) => {
        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        const agentId = params.agentId
        props.requestAgentClients(
            agentId,
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    const handleTableChange = (pagination, filters, sorter) => {
        console.debug('sorter', sorter)

        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        const agentId = params.agentId
        props.requestAgentProposals(
            agentId,
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    useEffect(() => {
        if (params.agentId !== 'add') {
            props.requestAgent(params.agentId)
            props.requestAgentClients(
                params.agentId,
                props.page,
                props.pageSize,
                props.sorted
            )

            props.requestAgentProposals(
                params.agentId,
                props.page,
                props.pageSize,
                props.sorted
            )
        }
    }, [])

    if (params.agentId !== 'add') {
        return (
            <AgentView
                customersPageChangeHandler={customersPageChangeHandler}
                proposalsPageChangeHandler={handleTableChange}
                {...props}
            />
        )
    } else {
        return <></>
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.agent,
        customers: { ...state.clients },
        proposals: { ...state.proposals },
    }
}

export default connect(mapStateToProps, {
    requestAgent,
    requestAgentClients,
    requestAgentProposals,
})(AgentViewPage)
