import React from "react";
import { Field } from "formik";
import BSField from "./bs_field";

const AnnuityForm = props => {
  const { touched, errors } = props;
  return (
    <div className="panel panel-default panel-detail ">
      <div className="panel-heading">
        <h3 className="panel-title">Annuity</h3>
      </div>
      <div className="panel-body form-horizontal">
        <BSField touched={touched.roiIr} error={errors.roiIr}>
          <label className="col-md-7 control-label">ROI Rate</label>
          <div className="col-md-5">
            <Field name="annuity.roiIr" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.rollUpIr} error={errors.rollUpIr}>
          <label className="col-md-7 control-label">Rollup Rate</label>
          <div className="col-md-5">
            <Field name="annuity.rollUpIr" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.premiumBonus} error={errors.premiumBonus}>
          <label className="col-md-7 control-label">Premium Bonus</label>
          <div className="col-md-5">
            <Field name="annuity.premiumBonus" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.incomeBonus} error={errors.incomeBonus}>
          <label className="col-md-7 control-label">Income Bonus</label>
          <div className="col-md-5">
            <Field name="annuity.incomeBonus" className="form-control" />
          </div>
        </BSField>
        <BSField touched={touched.withdrawalRate} error={errors.withdrawalRate}>
          <label className="col-md-7 control-label">Withdrawal Rate</label>
          <div className="col-md-5">
            <Field name="annuity.withdrawalRate" className="form-control" />
          </div>
        </BSField>
      </div>
    </div>
  );
};

export default AnnuityForm;
