import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    requestAriaV2,
    requestResetAriaV2,
    requestUpdateAriaV2,
} from '../actions'
import AriaV2Form from '../components/aria-v2/form'
import { Card, Row, Col, Button } from 'antd'
import { Link, useParams, useHistory } from 'react-router-dom'

const AriaV2EditPage = (props) => {
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        props.requestAriaV2(params.proposalId)
    }, [])

    const updateProposal = (values) => {
        props.requestUpdateAriaV2(params.proposalId, { ...values, history })
    }

    return (
        <Card
            title={
                <Row>
                    <Col span={8}>
                        <Link to="/aria-v2" className="pull-left">
                            <Button type="danger" icon="close">
                                Cancel
                            </Button>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <div style={{ textAlign: 'center' }}>Proposal View</div>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            }
        >
            <AriaV2Form
                {...props}
                submitHandler={updateProposal}
                buttonText="Update Proposal"
            />
        </Card>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.ariav2,
    }
}

export default connect(mapStateToProps, {
    requestAriaV2,
    requestResetAriaV2,
    requestUpdateAriaV2,
})(AriaV2EditPage)
