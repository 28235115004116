import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestProposals } from '../actions'
import ProposalList from '../components/proposal/list'

const ProposalsPage = (props) => {
    useEffect(() => {
        props.requestProposals(props.page, props.pageSize, props.sorted)
    }, [])

    const handleTableChange = (pagination, filters, sorter) => {
        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        props.requestProposals(
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    return <ProposalList pageChangeHandler={handleTableChange} {...props} />
}

const mapStateToProps = (state) => {
    return {
        ...state.proposals,
    }
}

export default connect(mapStateToProps, { requestProposals })(ProposalsPage)
