import React, { Component } from 'react'
import numeral from 'numeral'
import immutable from 'object-path-immutable'
// import * as Yup from 'yup'
import { PieChart, Pie, Cell } from 'recharts'

import './index.css'
import { Button, message, Card } from 'antd'

numeral.nullFormat('N/A')

// const allocValSchema = Yup.object().shape({
//     alloc: Yup.number().positive().integer().max(100).required(),
//     cf_pct: Yup.number().positive().integer().max(100).required(),
// })

// const allocSchema = Yup.object().shape({
//     annuities: allocValSchema,
//     bonds: allocValSchema,
//     cash: allocValSchema,
//     IUL: allocValSchema,
//     stocks: allocValSchema,
// })

// const valSchema = Yup.object().shape({
//     proposed: allocSchema,
//     current: allocSchema,
//     invested: Yup.number().positive().integer().required(),
//     till_retire: Yup.number().positive().integer().max(100).required(),
//     tax_rate: Yup.number().positive().max(100).required(),
// })

export default class Cashflow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initVals: {
                annuities: { alloc: 0.0, cf_pct: 0.0 },
                bonds: { alloc: 35.0, cf_pct: 3.0 },
                cash: { alloc: 5.0, cf_pct: 0.0 },
                IUL: { alloc: 0.0, cf_pct: 11.0 },
                stocks: { alloc: 60.0, cf_pct: 2.0 },
            },
            baseAllocations: [
                { name: 'stocks', add: 1, color: '#6CACDD' },
                { name: 'bonds', add: 1, color: '#F2903F' },
                { name: 'IUL', add: 2, color: '#B4B4B4' },
                { name: 'annuities', add: 1, color: '#EBB510' },
                { name: 'cash', add: 0, color: '#3E6EC4' },
            ],
            invested: 2500000,
            till_retire: 10,
            tax_rate: 25,
            currentVals: {
                values: [],
            },
            proposedVals: {
                values: [],
            },
        }
        this.handleChange = this.handleChange.bind(this)
        this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this)
    }
    calcTaxEquivalent(amt, taxRate, add, flip = false) {
        switch (add) {
            case 1:
                if (flip) {
                    return amt
                } else {
                    return amt * (1 - taxRate / 100)
                }
            case 2:
                if (flip) {
                    return amt / (1 - taxRate / 100)
                } else {
                    return amt
                }
            default:
                return 0
        }
    }
    renderCustomizedLabel({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) {
        if (percent === 0) return null
        const RADIAN = Math.PI / 180
        const radius = outerRadius + 25
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)

        return (
            <text
                x={x}
                y={y}
                fill={this.state.baseAllocations[index].color}
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {this.state.baseAllocations[index].name}
            </text>
        )
    }
    calcCashflow(allocations) {
        let calcVals = [],
            gross = 0,
            net = 0,
            tax_equiv = 0
        const { baseAllocations, invested, tax_rate } = this.state
        baseAllocations.forEach((baseAllocation) => {
            const amt =
                (invested * allocations[baseAllocation.name].alloc) / 100
            const cfAmt = amt * (allocations[baseAllocation.name].cf_pct / 100)
            calcVals.push({
                name: baseAllocation.name,
                alloc: parseFloat(allocations[baseAllocation.name].alloc),
                cf_pct: allocations[baseAllocation.name].cf_pct,
                amt: amt,
                cf_amt: cfAmt,
            })
            gross = gross + cfAmt
            net =
                net +
                this.calcTaxEquivalent(cfAmt, tax_rate, baseAllocation.add)
            tax_equiv =
                tax_equiv +
                this.calcTaxEquivalent(
                    cfAmt,
                    tax_rate,
                    baseAllocation.add,
                    true
                )
        })
        return { values: calcVals, gross, net, tax_equiv }
    }
    handleChange(e) {
        const { value, name } = e.target
        const newState = immutable.set(this.state, name, value)
        this.setState(newState)
    }
    pos_neg(value) {
        if (value < 0) {
            return 'negative'
        } else {
            return 'positive'
        }
    }
    componentDidMount() {
        this.setState({
            current: this.state.initVals,
            proposed: this.state.initVals,
            currentVals: this.calcCashflow(this.state.initVals),
            proposedVals: this.calcCashflow(this.state.initVals),
        })
    }
    render() {
        const {
            current,
            proposed,
            invested,
            till_retire,
            tax_rate,
            proposedVals,
            currentVals,
        } = this.state
        if (current === undefined) {
            return <div>Loading...</div>
        }

        const currentDiff = currentVals.net - proposedVals.net
        const proposedDiff = proposedVals.net - currentVals.net
        let pct_inc = 0
        if (currentVals.net !== 0) {
            pct_inc =
                ((proposedVals.net - currentVals.net) / currentVals.net) * 100
        }
        return (
            <Card title="Cash Flow Analyzer" bordered={false}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="controls form-inline">
                            <label className="control-label" htmlFor="invested">
                                Starting Value
                            </label>{' '}
                            <input
                                className="form-control invested"
                                type="text"
                                id="invested"
                                name="invested"
                                defaultValue={invested}
                                onChange={this.handleChange}
                            />
                            <label
                                className="control-label"
                                htmlFor="till_retire"
                            >
                                Time until Retire
                            </label>{' '}
                            <input
                                className="form-control invested"
                                id="till_retire"
                                type="text"
                                name="till_retire"
                                onChange={this.handleChange}
                                defaultValue={till_retire}
                            />
                            <label className="control-label" htmlFor="tax-rate">
                                Tax Rate
                            </label>{' '}
                            <input
                                className="form-control invested"
                                id="tax-rate"
                                type="text"
                                name="tax_rate"
                                onChange={this.handleChange}
                                defaultValue={tax_rate}
                            />
                            <Button
                                type="primary"
                                onClick={() => {
                                    if (
                                        parseInt(current.annuities.alloc) +
                                            parseInt(current.bonds.alloc) +
                                            parseInt(current.cash.alloc) +
                                            parseInt(current.IUL.alloc) +
                                            parseInt(current.stocks.alloc) !==
                                        100
                                    ) {
                                        message.warning(
                                            'Warning! Allocations must add up to 100'
                                        )
                                        return
                                    }
                                    if (
                                        parseInt(proposed.annuities.alloc) +
                                            parseInt(proposed.bonds.alloc) +
                                            parseInt(proposed.cash.alloc) +
                                            parseInt(proposed.IUL.alloc) +
                                            parseInt(proposed.stocks.alloc) !==
                                        100
                                    ) {
                                        message.warning(
                                            'Warning! Allocations must add up to 100'
                                        )
                                        return
                                    }
                                    const currentVals =
                                        this.calcCashflow(current)
                                    const proposedVals =
                                        this.calcCashflow(proposed)
                                    this.setState({ currentVals, proposedVals })
                                }}
                            >
                                Calculate
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <table className="table table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th />
                                    <th>Allocation</th>
                                    <th />
                                    <th>Cash Flow %</th>
                                    <th>Cash Flow $</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentVals.values.map((currentVal, idx) => (
                                    <tr key={idx}>
                                        <td className={currentVal.name}>
                                            {currentVal.name}
                                        </td>
                                        <td align="right">
                                            <input
                                                className="form-control percententry"
                                                type="text"
                                                name={`current.${currentVal.name}.alloc`}
                                                id="current.{ currentVal.name }.alloc"
                                                onChange={this.handleChange}
                                                defaultValue={currentVal.alloc}
                                            />
                                        </td>
                                        <td className="currency">
                                            {numeral(currentVal.amt).format(
                                                '0,0'
                                            )}
                                        </td>
                                        <td align="right">
                                            <input
                                                className="form-control percententry"
                                                type="text"
                                                name={`current.${currentVal.name}.cf_pct`}
                                                id={`current.${currentVal.name}.cf_pct`}
                                                onChange={this.handleChange}
                                                defaultValue={currentVal.cf_pct}
                                            />
                                        </td>
                                        <td className="currency">
                                            {numeral(currentVal.cf_amt).format(
                                                '0,0'
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="4" align="right">
                                        Gross Income
                                    </td>
                                    <td className="currency totals">
                                        {numeral(currentVals.gross).format(
                                            '0,0'
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" align="right">
                                        Net Income (Take home)
                                    </td>
                                    <td className="currency totals">
                                        {numeral(currentVals.net).format('0,0')}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" align="right">
                                        Income Difference
                                        <br />
                                        <small>(from Proposed)</small>
                                    </td>
                                    <td
                                        className={`currency totals ${this.pos_neg(
                                            currentDiff
                                        )}`}
                                    >
                                        {numeral(currentDiff).format('(0,0)')}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <table className="table table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th />
                                    <th>Allocation</th>
                                    <th />
                                    <th>Cash Flow %</th>
                                    <th>Cash Flow $</th>
                                </tr>
                            </thead>
                            <tbody>
                                {proposedVals.values.map((proposedVal, idx) => (
                                    <tr key={idx}>
                                        <td className={proposedVal.name}>
                                            {proposedVal.name}
                                        </td>
                                        <td align="right">
                                            <input
                                                className="form-control percententry"
                                                type="text"
                                                name={`proposed.${proposedVal.name}.alloc`}
                                                id={`proposed.${proposedVal.name}.alloc`}
                                                onChange={this.handleChange}
                                                defaultValue={proposedVal.alloc}
                                            />
                                        </td>
                                        <td className="currency">
                                            {numeral(proposedVal.amt).format(
                                                '0,0'
                                            )}
                                        </td>
                                        <td align="right">
                                            <input
                                                className="form-control percententry"
                                                type="text"
                                                name={`proposed.${proposedVal.name}.cf_pct`}
                                                id={`proposed.${proposedVal.name}.cf_pct`}
                                                onChange={this.handleChange}
                                                defaultValue={
                                                    proposedVal.cf_pct
                                                }
                                            />
                                        </td>
                                        <td className="currency">
                                            {numeral(proposedVal.cf_amt).format(
                                                '0,0'
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="4" align="right">
                                        Gross Income
                                    </td>
                                    <td className="currency totals">
                                        {numeral(proposedVals.gross).format(
                                            '0,0'
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" align="right">
                                        Net Income (Take home)
                                    </td>
                                    <td className="currency totals">
                                        {numeral(proposedVals.net).format(
                                            '0,0'
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" align="right">
                                        Income Difference
                                        <br />
                                        <small>(from Current Allocation)</small>
                                    </td>
                                    <td
                                        className={`currency totals ${this.pos_neg(
                                            proposedDiff
                                        )}`}
                                    >
                                        {numeral(proposedDiff).format('(0,0)')}
                                        <br />
                                        {numeral(pct_inc).format('0,0.0')}%
                                        Increase
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" align="right">
                                        Taxable Equivalent
                                        <br />
                                        <small>
                                            (Hybrid Grossed for Tax Equivalency)
                                        </small>
                                    </td>
                                    <td className="currency totals">
                                        {numeral(proposedVals.tax_equiv).format(
                                            '0,0'
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12">
                        <div id="currentChart" className="col-md-6 text-center">
                            <PieChart
                                width={350}
                                height={250}
                                style={{ display: 'inline-block' }}
                            >
                                <Pie
                                    data={currentVals.values}
                                    dataKey="alloc"
                                    nameKey="name"
                                    outerRadius={90}
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                >
                                    <Cell fill="#6CACDD" />
                                    <Cell fill="#F2903F" />
                                    <Cell fill="#B4B4B4" />
                                    <Cell fill="#EBB510" />
                                    <Cell fill="#3E6EC4" />
                                </Pie>
                            </PieChart>
                        </div>
                        <div
                            id="proposedChart"
                            className="col-md-6 text-center"
                        >
                            <PieChart
                                width={350}
                                height={250}
                                style={{ display: 'inline-block' }}
                            >
                                <Pie
                                    data={proposedVals.values}
                                    dataKey="alloc"
                                    nameKey="name"
                                    outerRadius={90}
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                >
                                    <Cell fill="#6CACDD" />
                                    <Cell fill="#F2903F" />
                                    <Cell fill="#B4B4B4" />
                                    <Cell fill="#EBB510" />
                                    <Cell fill="#3E6EC4" />
                                </Pie>
                            </PieChart>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <span className="calc-disclaimer">
                            Information and interactive calculators are made
                            available to you as self-help tools for your
                            independent use and are not intended to provide
                            investment advice. We cannot and do not guarantee
                            their applicability or accuracy in regard to your
                            individual circumstances. All examples are
                            hypothetical and are for illustrative purposes. We
                            encourage you to seek personalized advice from
                            qualified professionals regarding all personal
                            finance issues
                        </span>
                    </div>
                </div>
            </Card>
        )
    }
}
