import { Card, Col, Row } from "antd";
import React, { Component } from "react";
import numeral from 'numeral';

class IncomeReplacement extends Component {
  render() {
    return (
      <Card
        bordered={false}
        title="Income Replacement"
      >
        <div>*Spouse or dependents rely on your income = Income Replacement Need</div>
        <br />
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12} style={{ textAlign: 'center' }}>
            Age Factor (Income Factor)
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            {this.props.factor || '...'}
          </Col>
        </Row>
        <Row gutter={8} style={{ paddingBottom: 10 }}>
          <Col span={12}>
            <b>Income Replacement Maximum Death Benefit</b>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <b>{numeral(this.props.maxDeathBenefit).format("$ 0,0") || '...'}</b>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default IncomeReplacement;
