import React from "react";
import moment from "moment";
import { Table, Divider } from 'antd';
import { Link } from "react-router-dom";

const ListClients = props => {
  const { data, loading, count, page, pageChangeHandler } = props;
  const columns = [
    { title: "Name", key: "last_name", dataIndex: "fullName" },
    {
      title: "Age",
      key: "current_age",
      align: 'right',
      dataIndex: "currentAge"
    },
    { title: "Spouse", key: "spouse_last_name", dataIndex: "spouseFullName" },
    {
      title: "Age",
      key: "spouse_age",
      align: 'right',
      dataIndex: "spouseAge"
    },
    { title: "Agent", sortable: false, dataIndex: "agentName" },
    { title: "Agency", sortable: false, dataIndex: "agencyName" },
    {
      title: "Created",
      dataIndex: "createdOn",
      key: "created_on",
      render: created_on => moment(created_on).format("MM/DD/YYYY LT")
    },
    {
      title: "Actions",
      render: (original) => (
        <span>
          <Link to={"/clients/" + original.id}>
            View
          </Link>
          <Divider type="vertical" />
          <Link to={"/clients/edit/" + original.id}>
            Edit
          </Link>
        </span>
      ),
    }
  ];

  const curr_pagination = {
    position: 'bottom',
    current: page + 1,
    defaultPageSize: 25,
    total: count,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['25', '50', '100']
  }

  return (
    <Table
      loading={loading}
      pagination={curr_pagination}
      onChange={pageChangeHandler}
      columns={columns}
      dataSource={data} />
  );
};

export default ListClients;
