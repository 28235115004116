import React from 'react'
import { Provider } from 'react-redux'
import { FlagsmithProvider } from 'flagsmith-react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import store from '../store'
import Main from './Main'
import { AuthProvider } from '../components/use_auth'
import { FLAGSMITH_API_KEY } from '../config/env'

const App = () => {
    const queryClient = new QueryClient()

    return (
        <FlagsmithProvider
            environmentId={FLAGSMITH_API_KEY}
            api="https://flagsmith-2no5t.ondigitalocean.app/api/v1/"
        >
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <Provider store={store}>
                    <Router>
                        <AuthProvider>
                            <Main />
                        </AuthProvider>
                    </Router>
                </Provider>
            </QueryClientProvider>
        </FlagsmithProvider>
    )
}

export default App
