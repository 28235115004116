/*****************************
 *   Author: Sina Khelil
 *   Date: Jun 5, 2018
 ******************************/
import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { isNumber } from 'lodash'
import compose from 'compose-function'
import { Tooltip } from 'antd'
const fnumber = '0,0[.][00]'

/**
 * Safe conversion to numeral object. Numeral crashes with the value is
 * object or function.
 *
 * @param   {any}
 * @returns {numeral}
 */
const toNumeral = (value) => {
    const type = typeof value
    let n

    if (type === 'object' || type === 'function' || type === 'boolean') {
        return null
    }

    n = numeral(value)

    return n
}

/**
 * Convert given value to a number type. If conversion fails, returns NaN.
 *
 * @param   {any}
 * @returns {Number} NaN if conversion fails.
 */
const parseNumber = (value) => {
    const numeral = toNumeral(value)

    if (numeral == null) {
        return NaN
    }

    return numeral.value()
}

/**
 * Apply number formatting to given number value. If given value cannot be
 * converted to a number, returns null.
 *
 * @param   {any}
 * @returns {string}
 */
const formatNumber = (value, format) => {
    const numeral = toNumeral(value)
    return numeral ? numeral.format(format || fnumber) : null
}

/**
 * <NumberInput /> component
 *
 * @param   {Number} value
 * @returns {Component}
 */
export default class NumberInput extends React.Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        format: PropTypes.string,
        error: PropTypes.string,
    }

    static defaultProps() {
        // input[type=tel] allows separators in the input field, changing it
        // to input[type=number] will remove formatting
        return {
            onBlur: function () {},
            onFocus: function () {},
            onChange: function () {},
            type: 'tel',
            value: null,
            format: fnumber,
            error: '',
        }
    }

    constructor(props) {
        super(props)
        const value = parseNumber(this.props.value)

        // focused: keep track of the input's focus state
        // numeral: keep track of the input's current value (numeral object)
        this.state = {
            error: false,
            focused: false,
            value: isNumber(value) ? value : '',
        }
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentWillReceiveProps(props) {
        let value = null

        // Prevent changing the value via external entry when editing.
        if (!this.state.focused && 'value' in props) {
            value = parseNumber(props.value)
            this.setState({ value: isNumber(value) ? value : '' })
        }
    }

    componentDidMount() {
        // focused: check if component is focused after mounting and set state
        // this.setState({
        //   focused: global.document.activeElement === this.refs.input.getDOMNode()
        // });
    }

    onChange(event) {
        this.setState({ value: event.target.value })
        event.persist()
        return event
    }

    onBlur(event) {
        let numeral = toNumeral(event.target.value)
        this.setState({
            focused: false,
            value: numeral ? numeral.format(this.props.format) : '',
        })
        event.persist()
        return event
    }

    onFocus(event) {
        let numeral = toNumeral(event.target.value)
        this.setState({ focused: true, value: numeral ? numeral.value() : '' })
        event.persist()
        return event
    }

    valueAsFormatted() {
        let value = this.state.value
        let numeral = toNumeral(value)

        return numeral ? numeral.format(this.props.format) : ''
    }

    render() {
        let props = this.props

        let onChange = compose(props.onChange, this.onChange)
        let onFocus = compose(props.onFocus, this.onFocus)
        let onBlur = compose(props.onBlur, this.onBlur)
        let value = this.state.focused
            ? this.state.value
            : this.valueAsFormatted()

        return (
            <div
                className={`has-feedback ${
                    this.props.error ? 'has-error' : ''
                }`}
            >
                <Tooltip title={this.props.error} placement="right">
                    <input
                        ref="input"
                        {...props}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        value={value}
                        style={{
                            textAlign: 'right',
                            borderTopLeftRadius: '4px',
                            borderBottomLeftRadius: '4px',
                            paddingRight: `${
                                this.props.error ? '25px' : '10px'
                            }`,
                        }}
                    />
                </Tooltip>
                {this.props.error && (
                    <span
                        className="form-control-feedback"
                        aria-hidden="true"
                        title={this.props.error}
                    >
                        <i className="glyphicon glyphicon-warning-sign" />
                    </span>
                )}
            </div>
        )
    }
}

NumberInput.statics = {
    isNumber: isNumber,
    formatNumber: formatNumber,
    parseNumber: parseNumber,
}
