import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    requestClient,
    requestClientAriaV2s,
    requestClientProposals,
    requestClientIllustrations,
} from '../actions'
import ClientView from '../components/client/view'

const ClientViewPage = (props) => {
    const params = useParams()

    useEffect(() => {
        if (params.clientId !== 'add') {
            props.requestClient(params.clientId)
            const clientId = params.clientId
            const page = props.proposals.page
            const perPage = props.proposals.pageSize
            props.requestClientAriaV2s(
                clientId,
                page,
                perPage,
                props.ariav2s.sorted
            )
            props.requestClientProposals(
                clientId,
                page,
                perPage,
                props.proposals.sorted
            )
            props.requestClientIllustrations(
                clientId,
                page,
                perPage,
                props.proposals.sorted
            )
        }
    }, [])

    const handleAriaV2TableChange = (pagination, filters, sorter) => {
        console.debug('sorter', sorter)

        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        const clientId = params.clientId
        props.requestClientProposals(
            clientId,
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    // deprecated
    const handleTableChange = (pagination, filters, sorter) => {
        console.debug('sorter', sorter)

        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        const clientId = params.clientId
        props.requestClientProposals(
            clientId,
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    // deprecated
    const handleIllustrationTableChange = (pagination, filters, sorter) => {
        console.debug('sorter', sorter)

        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        const clientId = params.clientId
        alert(clientId)
        props.requestClientIllustrations(
            clientId,
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    if (params.clientId !== 'add') {
        return (
            <ClientView
                clientId={params.clientId}
                ariav2sPageChangeHandler={handleAriaV2TableChange}
                proposalsPageChangeHandler={handleTableChange}
                illustrationsPageChangeHandler={handleIllustrationTableChange}
                {...props}
            />
        )
    } else {
        return <></>
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.client,
        ariav2s: state.ariav2s,
        proposals: state.proposals,
        illustrations: state.illustrations,
    }
}

export default connect(mapStateToProps, {
    requestClient,
    requestClientAriaV2s,
    requestClientProposals,
    requestClientIllustrations,
})(ClientViewPage)
