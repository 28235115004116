import React from 'react'
import NumberInput from '../../../../components/shared_components/numberinput'
import { BucketType } from '../../../..'
import { UseMutationResult } from 'react-query'
import { AxiosError } from 'axios'
import numeral from 'numeral'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { debounce } from 'lodash'

interface Props {
    bucket: BucketType
    updateBucket: UseMutationResult<
        BucketType | undefined,
        AxiosError,
        BucketType
    >
}

const BucketIncomeSources = (props: Props) => {
    const { bucket, updateBucket } = props

    const incomeSourcesSchema = Yup.object().shape({
        definedContributionPlanIncome: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        savingsInvestmentsIncome: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        qualifiedPlanIncome: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        pensionIncome: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        employmentIncome: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        socialSecurityIncome: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
    })

    return (
        <Formik
            initialValues={bucket}
            validationSchema={incomeSourcesSchema}
            onSubmit={(values: BucketType) => {
                debounce(
                    () => updateBucket.mutate({ ...bucket, ...values }),
                    500
                )()
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <Form>
                    <div className="col-md-8" style={{ paddingBottom: '2px' }}>
                        Social Security
                    </div>
                    <div className="col-md-4 form-inline text-right">
                        <div className="form-group">
                            <div className="input-group">
                                <NumberInput
                                    className="form-control input-sm"
                                    error={errors.socialSecurityIncome}
                                    format="$0,0"
                                    name="socialSecurityIncome"
                                    value={values.socialSecurityIncome}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            'socialSecurityIncome',
                                            parseFloat(event.target.value)
                                        )
                                    }
                                    onBlur={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className="input-group-addon">
                                    <input
                                        type="checkbox"
                                        checked={
                                            values.socialSecurityIncomeDeduct
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'socialSecurityIncomeDeduct',
                                                !values.socialSecurityIncomeDeduct
                                            )
                                            handleSubmit()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8" style={{ paddingBottom: '2px' }}>
                        Pension
                    </div>
                    <div className="col-md-4 form-inline text-right">
                        <div className="form-group">
                            <div className="input-group">
                                <NumberInput
                                    className="form-control input-sm"
                                    error={errors.pensionIncome}
                                    format="$0,0"
                                    name="pensionIncome"
                                    value={values.pensionIncome}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            'pensionIncome',
                                            parseInt(event.target.value)
                                        )
                                    }
                                    onBlur={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className="input-group-addon">
                                    <input
                                        type="checkbox"
                                        checked={values.pensionIncomeDeduct}
                                        onChange={() => {
                                            setFieldValue(
                                                'pensionIncomeDeduct',
                                                !values.pensionIncomeDeduct
                                            )
                                            handleSubmit()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8" style={{ paddingBottom: '2px' }}>
                        Employment
                    </div>
                    <div className="col-md-4 form-inline text-right">
                        <div className="form-group">
                            <div className="input-group">
                                <NumberInput
                                    className="form-control input-sm"
                                    error={errors.employmentIncome}
                                    format="$0,0"
                                    name="employmentIncome"
                                    value={values.employmentIncome}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            'employmentIncome',
                                            parseInt(event.target.value)
                                        )
                                    }
                                    onBlur={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className="input-group-addon">
                                    <input
                                        type="checkbox"
                                        checked={values.employmentIncomeDeduct}
                                        onChange={() => {
                                            setFieldValue(
                                                'employmentIncomeDeduct',
                                                !values.employmentIncomeDeduct
                                            )
                                            handleSubmit()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8" style={{ paddingBottom: '2px' }}>
                        401(k), IRA or Other Qualified Plans
                    </div>
                    <div className="col-md-4 form-inline text-right">
                        <div className="form-group">
                            <div className="input-group">
                                <NumberInput
                                    className="form-control input-sm"
                                    error={errors.qualifiedPlanIncome}
                                    format="$0,0"
                                    name="qualifiedPlanIncome"
                                    value={values.qualifiedPlanIncome}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            'qualifiedPlanIncome',
                                            parseFloat(event.target.value)
                                        )
                                    }
                                    onBlur={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className="input-group-addon">
                                    <input
                                        type="checkbox"
                                        checked={
                                            values.qualifiedPlanIncomeDeduct
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'qualifiedPlanIncomeDeduct',
                                                !values.qualifiedPlanIncomeDeduct
                                            )
                                            handleSubmit()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8" style={{ paddingBottom: '2px' }}>
                        Savings and Investments
                    </div>
                    <div className="col-md-4 form-inline text-right">
                        <div className="form-group">
                            <div className="input-group">
                                <NumberInput
                                    className="form-control input-sm"
                                    error={errors.savingsInvestmentsIncome}
                                    format="$0,0"
                                    name="savingsInvestmentsIncome"
                                    value={values.savingsInvestmentsIncome}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            'savingsInvestmentsIncome',
                                            parseFloat(event.target.value)
                                        )
                                    }
                                    onBlur={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className="input-group-addon">
                                    <input
                                        type="checkbox"
                                        checked={
                                            values.savingsInvestmentsIncomeDeduct
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'savingsInvestmentsIncomeDeduct',
                                                !values.savingsInvestmentsIncomeDeduct
                                            )
                                            handleSubmit()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8" style={{ paddingBottom: '2px' }}>
                        Defined Benefit or Defined Contribution Plan
                    </div>
                    <div className="col-md-4 form-inline text-right">
                        <div className="form-group">
                            <div className="input-group">
                                <NumberInput
                                    className="form-control input-sm"
                                    error={errors.definedContributionPlanIncome}
                                    format="$0,0"
                                    name="definedContributionPlanIncome"
                                    value={values.definedContributionPlanIncome}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            'definedContributionPlanIncome',
                                            parseFloat(event.target.value)
                                        )
                                    }
                                    onBlur={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className="input-group-addon">
                                    <input
                                        type="checkbox"
                                        checked={
                                            values.definedContributionPlanIncomeDeduct
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'definedContributionPlanIncomeDeduct',
                                                !values.definedContributionPlanIncomeDeduct
                                            )
                                            handleSubmit()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-8"
                        style={{
                            paddingBottom: '2px',
                            paddingTop: '6px',
                            fontWeight: 700,
                        }}
                    >
                        Total
                    </div>
                    <div
                        className="col-md-4 currency"
                        style={{
                            paddingRight: '68px',
                            paddingBottom: '2px',
                            paddingTop: '6px',
                            textAlign: 'right',
                            fontWeight: 700,
                        }}
                    >
                        {numeral(
                            bucket.socialSecurityIncome +
                                bucket.pensionIncome +
                                bucket.employmentIncome +
                                bucket.qualifiedPlanIncome +
                                bucket.savingsInvestmentsIncome +
                                bucket.definedContributionPlanIncome
                        ).format('$0,0')}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default BucketIncomeSources
