import React from 'react'
import numeral from 'numeral'
import { Card } from 'antd'

const getIndexValue = (indexValues, idx) => {
    const indexValuesCount = Object.keys(indexValues).length
    const indexValuesIdx =
        idx >= indexValuesCount
            ? idx - indexValuesCount * Math.floor(idx / indexValuesCount)
            : idx
    return parseFloat(indexValues[indexValuesIdx]) || 0
}

const RequiredMinDist = (props) => {
    const { rmd, maxBenefit, marketValues, illustration } = props
    return (
        <div className="center">
            <Card
                className="container800"
                title={`Required Minimum Distribution (RMD) Summary`}
                bordered={false}
                headStyle={{
                    color: '#003e80',
                    fontWeight: 'bold',
                    fontSize: 24,
                    textAlign: 'center',
                    borderBottomColor: '#003e80',
                }}
            >
                <div className="center">
                    <table className="table table-600 table-noborder table-condensed">
                        <tbody>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Projected Distributions
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(rmd.rmdTotalDist).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder red-text">
                                    Less: Total Estimated Income Taxes @{' '}
                                    {numeral(illustration.taxRate / 100).format(
                                        '0,0.00%'
                                    )}
                                </td>
                                <td className="table-td-red currency">
                                    {numeral(rmd.taxOnRmdDist).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Projected Net (After Tax
                                    Distributions){' '}
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(rmd.rmdNetDist).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder" colSpan="2">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Projected Inheritance (Gross)
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(rmd.rmdInherit).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder red-text">
                                    Less: Total Estimated Lump Sum Tax @{' '}
                                    {numeral(illustration.taxRate / 100).format(
                                        '0,0.00%'
                                    )}
                                </td>
                                <td className="table-td-red currency">
                                    {numeral(rmd.taxOnInherit).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Projected After Lump Sum Tax
                                    Inheritance (Net){' '}
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(rmd.rmdNetInherit).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder" colSpan="2">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder" colSpan="2">
                                    Proposed Alternative Tax Free Plan
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className="table-td-noborder"
                                    colSpan="2"
                                ></td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Projected Tax Free Distributions{' '}
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(maxBenefit.totalIncome).format(
                                        '$0,0'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Projected Tax Free Family Benefit
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(maxBenefit.familyBenefit).format(
                                        '$0,0'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Taxes Paid from RMDs{' '}
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(rmd.rmdTaxes).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Tax with Alternative Tax Free Plan
                                </td>
                                <td className="table-td-blue currency">
                                    {numeral(maxBenefit.taxes).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-td-noborder">
                                    Total Tax Difference
                                </td>
                                <td className="currency table-td-blue">
                                    {numeral(
                                        rmd.rmdTaxes - maxBenefit.taxes
                                    ).format('$0,0')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="center">
                    <table className="table table-600 table-noborder table-condensed">
                        <tbody>
                            <tr>
                                <td
                                    colspan="2"
                                    class="table-th-dk-gray table-td-noborder text-centered bold"
                                >
                                    ACCUMULATION BENEFIT DIFFERENCE
                                </td>
                            </tr>
                            <tr>
                                <th className="table-td-blue col-md-6 bold">
                                    ALTERNATIVE TAX FREE PLAN
                                </th>
                                <th className="table-td-red col-md-6 bold">
                                    IRA
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL NET INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        {numeral(maxBenefit.totalIncome).format(
                                            '$0,0'
                                        )}
                                    </p>
                                </td>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL NET INCOME
                                    </p>
                                    <p className="boxed-currency">
                                        {numeral(rmd.rmdNetDist).format('$0,0')}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        FAMILY BENEFIT
                                    </p>
                                    <p className="boxed-currency">
                                        {numeral(
                                            maxBenefit.familyBenefit
                                        ).format('$0,0')}
                                    </p>
                                </td>
                                <td>
                                    <p className="boxed-label">
                                        FAMILY BENEFIT
                                    </p>
                                    <p className="boxed-currency">
                                        {numeral(rmd.rmdNetInherit).format(
                                            '$0,0'
                                        )}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL TAXES PAID
                                    </p>
                                    <p className="boxed-currency">
                                        {numeral(maxBenefit.taxes).format(
                                            '$0,0'
                                        )}
                                    </p>
                                </td>
                                <td>
                                    <p className="boxed-label">
                                        TOTAL TAXES PAID
                                    </p>
                                    <p className="boxed-currency">
                                        {numeral(rmd.rmdTaxes).format('$0,0')}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="center">
                    <h3 class="dark_blue-600">
                        Required Minimum Distribution (RMD)
                    </h3>
                </div>
                <div className="center">
                    <table className="table table-600 table-noborder table-hover">
                        <thead>
                            <tr>
                                <th className="table-th-blue table-th-bordertopleft">
                                    Age
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Market Returns
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    RMD Factor
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    RMDs
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Growth
                                </th>
                                <th className="table-th-blue table-th-bordertopright">
                                    Plan Balance
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rmd.rows.map((dataRow, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="currency">
                                            {dataRow.age}
                                        </td>
                                        <td className="currency">
                                            {numeral(
                                                getIndexValue(
                                                    marketValues,
                                                    index
                                                ) / 100
                                            ).format('0,0.00%')}
                                        </td>
                                        <td className="currency">
                                            {numeral(dataRow.factor).format(
                                                '0.0'
                                            )}
                                        </td>
                                        <td className="currency">
                                            {numeral(
                                                dataRow.transaction
                                            ).format('0,0')}
                                        </td>
                                        <td className="currency">
                                            {numeral(dataRow.interest).format(
                                                '0,0'
                                            )}
                                        </td>
                                        <td className="currency">
                                            {numeral(
                                                dataRow.accountValue
                                            ).format('0,0')}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="disclaimer-800">
                    <p>
                        Market returns assume {illustration.marketRateName}{' '}
                        Annual Returns Years 2001-2018 plus 1.5% management fee,
                        excluding dividends
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RequiredMinDist
