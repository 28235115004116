/*
 * Vinod <vmohanan@coretechs.com>
 * Thu Aug 15 2019
 */
import React from 'react';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { Statistic, Card } from 'antd';

export default class VolatilityChart extends React.Component {

    customTooltipOnYourLine(e) {
        console.debug(e);
        if (e.active && e.payload != null && e.payload[0] != null) {
            const value = e.payload[0].payload["income"].toFixed(2);
            return (
                <Card title={null}>
                    <Statistic
                        valueStyle={{ color: value > 0 ? '#3f8600' : '#cf1322', fontSize: 16 }}
                        title="" value={value} />
                </Card>
            );
        }
        else {
            return "";
        }
    }

    render() {
        const data = this.props.data || [];

        const dataMax = Math.max(...data.map(i => i.income));
        const dataMin = Math.min(...data.map(i => i.income));

        let off = 0;
        if (dataMax <= 0) {
            off = 0;
        } else if (dataMin >= 0) {
            off = 1;
        } else
            off = dataMax / (dataMax - dataMin);

        return (
            <AreaChart
                width={400}
                height={200}
                data={data || []}
                margin={{
                    top: 10, left: 30, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" showtooltip={false} />
                <YAxis tickFormatter={(value, name, props) => `$${(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                <Tooltip content={this.customTooltipOnYourLine} formatter={(value, name, props) => `$ ${(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                <defs>
                    <linearGradient id={"splitColor" + this.props.id} x1="0" y1="0" x2="0" y2="1">
                        <stop offset={off} stopColor="#3f8600" stopOpacity={1} />
                        <stop offset={off} stopColor="#cf1322" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <Area type="monotone" dataKey="income" stroke="#000" fill={`url(#splitColor${this.props.id})`} />
            </AreaChart>
        );
    }
}
