import numeral from 'numeral'
import React from 'react'
import { IncomeCompModelData } from '../..'

interface ICInheritedIraProps {
    data: IncomeCompModelData
}

interface InheritedIRATableProps {
    data: IncomeCompModelData,
    distribOption: string,
    distribOptionText: string | JSX.Element
}

const InheritedIRATable = (props: InheritedIRATableProps) => {
    const { data, distribOption, distribOptionText } = props
    const dataRows = data.inheritedCalcData[distribOption]
    return (
        <div className="inherited-table">
            <table className="table-inherited table-cell-pad">
                <thead>
                    <tr>
                        <th className="text-bold ic-left-border bold ic-top-border ic-bottom-border ic-yellow-bgnd">
                            Year
                        </th>
                        <th className="text-bold ic-top-border ic-bottom-border ic-yellow-bgnd">
                            IRA Balance<br/>BOY @ {data.inheritedIraGrowthRate}%
                        </th>
                        <th className="text-bold ic-top-border ic-bottom-border ic-yellow-bgnd">
                            {distribOptionText}
                        </th>
                        <th className="text-bold ic-top-border ic-bottom-border ic-right-border ic-yellow-bgnd">
                            Est. Tax<br/>@ {data.inheritedIraTaxRate}%
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataRows.calcs.map((dataRow, idx) => {                                                                         
                        return (
                            <tr key={idx}>
                                <td className="text-number ic-left-border">
                                    {dataRow.year}
                                </td>
                                <td className="text-number">
                                    {dataRow.currentPlanBalance ? numeral(dataRow.currentPlanBalance).format('0,0') : '-'}
                                </td>
                                <td className="text-number">
                                    {dataRow.distributionAmount ? numeral(dataRow.distributionAmount).format('0,0') : '-'}
                                </td>
                                <td className="text-number ic-right-border">
                                    {dataRow.distributionTaxAmount ? numeral(dataRow.distributionTaxAmount).format('0,0') : '-'}
                                </td>
                            </tr>
                        )}
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="ic-top-border ic-bottom-border text-number text-bold ic-left-border">
                            TOTALS
                        </td>
                        <td className="ic-top-border ic-bottom-border text-number"></td>
                        <td className="ic-top-border ic-bottom-border text-number text-bold">
                            {numeral(dataRows.totals.distributionAmountTotal).format('0,0')}
                        </td>
                        <td className="ic-top-border ic-bottom-border text-number text-bold ic-right-border">
                            {numeral(dataRows.totals.distributionTaxAmountTotal).format('0,0')}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}


export const ICInheritedIra = (props: ICInheritedIraProps) => {
    const { data } = props
    return (
        <div>
            <h1 className="text-blue text-center text-h1-spacing">Inherited IRA Income Tax Analysis</h1>
            <h2 className="text-blue text-center">Distribution Within 10 Years ‐ Death at Age 85</h2>
            <div>Non‐spousal heir is required to completely distribute over a maximum of 10 years</div>
            <div>No requirements for timing or magnitude of distributions</div>
            <table className="inherited-summary-table">
                <thead>
                    <tr>
                        <th className="text-bold text-blue" style={{paddingRight: '40px'}}>
                            Results ‐ Common Methods
                        </th>
                        <th className="text-bold ic-blue-bgnd ic-bottom-border">
                            Distribution Method
                        </th>
                        <th className="text-bold ic-blue-bgnd ic-bottom-border">
                            Total<br/>Distribution
                        </th>
                        <th className="text-bold ic-blue-bgnd ic-bottom-border">
                            Tax @ {numeral(data.inheritedIraTaxRate/100).format('0%')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td className="text-center">
                            Level
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['level'].totals.distributionAmountTotal).format('0,0')}
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['level'].totals.distributionTaxAmountTotal).format('0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-center">
                            Increasing
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['incr'].totals.distributionAmountTotal).format('0,0')}
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['incr'].totals.distributionTaxAmountTotal).format('0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-center">
                        Lump Sum Year 1
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['pmul'].totals.distributionAmountTotal).format('0,0')}
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['pmul'].totals.distributionTaxAmountTotal).format('0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-center">
                        Lump Sum Year 10
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['lump'].totals.distributionAmountTotal).format('0,0')}
                        </td>
                        <td className="text-number">
                            {numeral(data.inheritedCalcData['lump'].totals.distributionTaxAmountTotal).format('0,0')}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="inherited-tables">
                <InheritedIRATable distribOption={'level'} distribOptionText={<span>Level<br/>Distribution</span>} data={data} />
                <InheritedIRATable distribOption={'incr'} distribOptionText="Increasing" data={data} />
                <InheritedIRATable distribOption={'pmul'} distribOptionText={<span>Lump Sum<br/>Year 1</span>} data={data} />
                <InheritedIRATable distribOption={'lump'} distribOptionText={<span>Lump Sum<br/>Year 10</span>} data={data} />
            </div>
            <div style={{ marginTop: 30 }}>
            <div>Assumptions:</div>
                <div style={{ paddingLeft: 20 }}>Initial qualified plan balance: {numeral(data.qualifiedPlanBalance).format('$0,000')}</div>
            </div>
        </div>
    )
}
