import React from "react";
import moment from "moment";
import numeral from "numeral";
import LoadingField from "../../shared_components/loading_field";
import { Row, Col, Card } from "antd";

const HouseholdData = props => {
  const { client, tax, account, iraRoi, illustration, annuity, loading, proposal } = props;
  return (
    <div className="center">

      <Card className="container800" title={`Client Household Data`}
        bordered={false}
        headStyle={{ color: '#003e80', fontWeight: 'bold', fontSize: 24, textAlign: 'center', borderBottomColor: '#003e80' }}
      >
        <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'center' }}>
          <Col span={8}>
            <h4>Client Info</h4>
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td className="currency">
                    {(!loading && client.fullName) || ""}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Age:</td>
                  <td className="currency">
                    {!loading && client.currentAge}
                    {loading && <LoadingField width={30} />}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col span={4}></Col>
          <Col span={8}>
            <h4>Spouse Info</h4>
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td className="currency">
                    {(!loading && client.spouseFullName) || ""}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Age:</td>
                  <td className="currency">
                    {!loading && client.spouseAge}
                    {loading && <LoadingField width={30} />}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'center' }}>
          <Col span={8}>
            <h4>Analysis Info</h4>
            <div>
              Analysis as of {!loading && moment(proposal.proposalDate).format("MM/DD/Y")}
              {loading && <LoadingField />}
              <br />
              Illustrate to age: 100
              <br />
              Illustrate to year: {!loading && moment(illustration.proposalDate).year() - client.currentAge + 99}
              {loading && <LoadingField width={30} />}
            </div>
          </Col>
          <Col span={4}></Col>
          <Col span={8}></Col>
        </Row>
        <br />
        <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'center' }}>
          <Col span={8}>
            <h4>Estimated Taxes</h4>
            <h5>Current / Conversion</h5>
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>Tax Filing status</td>
                  <td className="currency">
                    {(!loading && client.filingStatusText) || ""}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Current Income</td>
                  <td className="currency">
                    {!loading && numeral(tax.currentIncome).format("$0,0")}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Federal Tax Rate</td>
                  <td className="currency">
                    {!loading && numeral(tax.currentFederalTaxRate).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
                <tr>
                  <td>State & Local Tax Rate</td>
                  <td className="currency">
                    {!loading && numeral(tax.currentStateTaxRate).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
                <tr>
                  <td>Capital Gains Tax Rate</td>
                  <td className="currency">
                    {!loading && numeral(tax.capitalGainsTax).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col span={4}></Col>
          <Col span={8}>
            <h4>&nbsp;</h4>
            <h5>Retirement</h5>
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>Tax Filing status</td>
                  <td className="currency">
                    {!loading && client.filingStatusText}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Target Income</td>
                  <td className="currency">
                    {!loading && numeral(account.targetIncome).format("$0,0")}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Federal Tax Rate</td>
                  <td className="currency">
                    {!loading && numeral(tax.futureFederalTaxRate || tax.currentFederalTaxRate).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
                <tr>
                  <td>State & Local Tax Rate</td>
                  <td className="currency">
                    {!loading && numeral(tax.futureStateTaxRate || tax.currentStateTaxRate).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
                <tr>
                  <td>Capital Gains Tax Rate</td>
                  <td className="currency">
                    {!loading && numeral(tax.capitalGainsTax).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'center' }}>
          {!loading && illustration.qualified && (
            <Col span={8}>
              <h4>Qualified Retirement Plan</h4>
              <h5>Account Information</h5>
              <table className="table table-condensed">
                <tbody>
                  <tr>
                    <td>Retirement Plan Value</td>
                    <td className="currency">
                      {!loading && numeral(account.accountBalance).format("$0,0")}
                      {loading && <LoadingField width={60} />}
                    </td>
                  </tr>
                  <tr>
                    <td># of Premium Payments</td>
                    <td className="currency">
                      {!loading && account.numOfPremium}
                      {loading && <LoadingField width={20} />}
                    </td>
                  </tr>
                  {(!illustration || loading) && (
                    <tr>
                      <td>Assumed ROI</td>
                      <td className="currency">
                        {!loading && iraRoi}
                        {loading && <LoadingField width={30} />}%
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          )}
          <Col span={4}></Col>

          <Col span={8}>
            <h4>&nbsp;</h4>
            <h5>&nbsp;</h5>
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>Begin Income at Age</td>
                  <td className="currency">
                    {!loading && account.incomeStartAge}
                    {loading && <LoadingField width={30} />}
                  </td>
                </tr>
                <tr>
                  <td>Begin Income Year</td>
                  <td className="currency">
                    {!loading &&
                      moment(illustration.proposalDate).year() +
                      (parseInt(account.incomeStartAge) - client.currentAge)}
                    {loading && <LoadingField width={30} />}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'center' }}>
          <Col span={8}>
            <h4>FIA Lifetime Income Rider Account</h4>
            <h5>Income</h5>
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>Annuity Value</td>
                  <td className="currency">
                    {!loading && numeral(account.accountBalance).format("$0,0")}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Premium Bonus</td>
                  <td className="currency">
                    {!loading && numeral(annuity.premiumBonus).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
                <tr>
                  <td>Income Bonus</td>
                  <td className="currency">
                    {!loading && numeral(annuity.incomeBonus).format("0.00")}
                    {loading && <LoadingField width={30} />}
                    {!loading && numeral(account.accountBalance * (annuity.incomeBonus / 100)).format("0,0")}
                    {loading && <LoadingField width={60} />}
                  </td>
                </tr>
                <tr>
                  <td>Roll Up %</td>
                  <td className="currency">
                    {!loading && numeral(annuity.rollUpIr).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
                <tr>
                  <td>Income Factor</td>
                  <td className="currency">
                    {!loading && numeral(annuity.withdrawalRate).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col span={4}></Col>
          <Col span={8}>
            <h4>&nbsp;</h4>
            <h5>Accumulation</h5>
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <td>Premium Bonus</td>
                  <td className="currency">
                    {!loading && numeral(annuity.premiumBonus).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Assumed ROI</td>
                  <td className="currency">
                    {!loading && numeral(annuity.roiIr).format("0.00")}
                    {loading && <LoadingField width={30} />}%
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Card>
    </div >
  );
};

export default HouseholdData;
