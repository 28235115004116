import React from "react";
import LoadingField from "../../shared_components/loading_field";

const ProposalClientPanel = props => {
  const { client, loading } = props;
  return (
    <div className="panel panel-default panel-detail">
      <div className="panel-heading">
        <h3 className="panel-title">Customer</h3>
      </div>
      <div className="panel-body">
        <div className="col-lg-6 col-md-6">
          {!loading && client.lastName}
          {loading && <LoadingField />}
          {!loading && <span>, {client.firstName}</span>}
          {loading && <LoadingField />}
          {!loading && (
            <a href={`/customers/${client.id}`}>
              <i className="fa fa-pencil fa-1x" />
            </a>
          )}
          <br />
          {!loading && client.address && (
            <span>
              {client.address}
              <br />
            </span>
          )}
          {loading && (
            <span>
              <LoadingField />
              <br />
            </span>
          )}
          {!loading && client.address2 && (
            <span>
              {client.address2}
              <br />
            </span>
          )}
          {!loading && client.city && (
            <span>
              {client.city}, {client.state} {client.postal}
              <br />
            </span>
          )}
          {loading && (
            <span>
              <LoadingField width={60} /> , <LoadingField width={20} /> <LoadingField width={0} />
              <br />
            </span>
          )}
          {!loading && client.phone && (
            <span>
              Phone: {client.phone}
              <br />
            </span>
          )}
          {!loading && client.fax && (
            <span>
              Fax: {client.fax}
              <br />
            </span>
          )}
          {!loading && client.email && (
            <span>
              Email: {client.email}
              <br />
            </span>
          )}
        </div>
        <div className="col-lg-6 col-md-6">
          Filing Status: {!loading && client.filingStatusText}
          {loading && <LoadingField width={40} />}
          <br />
          Gender: {!loading && client.genderText}
          {loading && <LoadingField width={30} />}
          <br />
          Age: {!loading && client.currentAge}
          {loading && <LoadingField width={20} />}
          {!loading && client.spouseFullName.length > 1 && (
            <span>
              <br />
              Spouse: {client.spouseFullName || "None"}
            </span>
          )}
          {!loading && client.spouseAge && (
            <span>
              <br />
              Spouse Age: {client.spouseAge}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProposalClientPanel;
