import React from 'react'
import numeral from 'numeral'
import { Card } from 'antd'
import {
    Bar,
    BarChart,
    CartesianGrid,
    LabelList,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { PolicyDataSummary } from '../../..'
import { max } from 'lodash'

interface AtapSummaryProps {
    policyDataSummary: PolicyDataSummary
}

const AtapSummary = (props: AtapSummaryProps) => {
    const { policyDataSummary: pds } = props

    const chartData = [
        { category: 'Premium', blue: pds.totalPremium, green: 0 },
        {
            category: 'Income',
            blue: pds.projectedTaxAdvantagedDist,
            green: pds.totalPreTaxEquivalentDistributions,
        },
        {
            category: 'Death Benefit',
            blue: pds.nonGuaranteedDeathBenefit,
            green: pds.totalPreTaxEquivalentDeathBenefit,
        },
        {
            category: 'Total Benefits',
            blue: pds.totalBenefitsTaxAdvantaged,
            green: pds.combinedPreTaxEquivalentBenefits,
        },
    ]

    let maxValue =
        max([
            pds.totalBenefitsTaxAdvantaged,
            pds.totalPremium,
            pds.projectedTaxAdvantagedDist,
            pds.totalPreTaxEquivalentDistributions,
            pds.nonGuaranteedDeathBenefit,
            pds.combinedPreTaxEquivalentBenefits,
            pds.totalPreTaxEquivalentDeathBenefit,
        ]) || 0

    maxValue = (Math.trunc(maxValue / 500000) + 2) * 500000

    return (
        <Card title="Alternative Tax‐Advantaged Plan Summary">
            <table className="atap-summary">
                <tbody>
                    <tr>
                        <td className="h3" colSpan={2}>
                            All income taken from the alternative plan is income
                            tax advantaged.*
                        </td>
                    </tr>
                    <tr>
                        <td className="h3" colSpan={2}>
                            The amount received by heirs at death is also
                            delivered free of income tax.
                        </td>
                    </tr>
                    <tr>
                        <td className="h3" colSpan={2}>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td className="h3" colSpan={2}>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td className="h3 fill-blue">
                            Total Projected Tax Advantaged Distributions**{' '}
                        </td>
                        <td className="h3 fill-blue text-right">
                            {numeral(pds.projectedTaxAdvantagedDist).format(
                                '$0,0'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="h3 fill-blue">
                            Total Projected Tax Free Death Benefit{' '}
                        </td>
                        <td className="h3 fill-blue text-right">
                            {numeral(pds.nonGuaranteedDeathBenefit).format(
                                '$0,0'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="h3 fill-blue">
                            Total Projected Combined Tax Advantaged Benefits{' '}
                        </td>
                        <td className="h3 fill-blue text-right">
                            {numeral(pds.totalBenefitsTaxAdvantaged).format(
                                '$0,0'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="h3 fill-gray">
                            Total Tax with Alternative Tax Advantaged Plan{' '}
                        </td>
                        <td className="h3 fill-gray text-right">
                            {numeral(pds.totalTaxWithAtap).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td className="h3" colSpan={2}>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td className="h3 fill-green">
                            Total Pre-tax Equivalent Distributions (
                            {numeral(pds.combinedTaxRate).format('0,0%')})***{' '}
                        </td>
                        <td className="h3 fill-green text-right">
                            {numeral(
                                pds.totalPreTaxEquivalentDistributions
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td className="h3 fill-green">
                            Total Pre-tax Equivalent Death Benefit (
                            {numeral(pds.combinedTaxRate).format('0,0%')}){' '}
                        </td>
                        <td className="h3 fill-green text-right">
                            {numeral(
                                pds.totalPreTaxEquivalentDeathBenefit
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td className="h3 fill-green">
                            Combined Pre-tax Equivalent Benefits{' '}
                        </td>
                        <td className="h3 fill-green text-right">
                            {numeral(
                                pds.combinedPreTaxEquivalentBenefits
                            ).format('$0,0')}
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <table
                style={{
                    width: '80%',
                    margin: 'auto',
                    border: '1px solid black',
                }}
            >
                <tbody>
                    <tr>
                        <td
                            className="h2 text-center"
                            style={{ padding: '12px' }}
                        >
                            Indexed Universal Life Projected Total Cash Flows To
                            Age 100
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '12px', margin: 'auto' }}>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    barGap={40}
                                    barSize={40}
                                    data={chartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid vertical={false} />
                                    <XAxis
                                        type="category"
                                        minTickGap={1}
                                        dataKey="category"
                                        interval={0}
                                    />
                                    <YAxis
                                        type="number"
                                        dataKey="green"
                                        scale="linear"
                                        tickFormatter={(value) =>
                                            numeral(value).format('0,0')
                                        }
                                        domain={[0, maxValue]}
                                    />
                                    <Tooltip
                                        allowEscapeViewBox={{
                                            x: true,
                                            y: true,
                                        }}
                                        formatter={(value: number) =>
                                            numeral(value).format('0,0')
                                        }
                                    />
                                    <Legend />
                                    <Bar
                                        dataKey="blue"
                                        fill="#305496"
                                        name="Aftertax Cashflow"
                                    >
                                        <LabelList
                                            dataKey="blue"
                                            position="top"
                                            offset={10}
                                            formatter={(value: number) =>
                                                value > 0
                                                    ? numeral(value).format(
                                                          '0,0'
                                                      )
                                                    : ''
                                            }
                                        />
                                    </Bar>
                                    <Bar
                                        dataKey="green"
                                        fill="#00b050"
                                        name={`Pre-tax Equivalent (${numeral(
                                            pds.combinedTaxRate
                                        ).format('0,0%')})`}
                                    >
                                        <LabelList
                                            dataKey="green"
                                            position="top"
                                            offset={10}
                                            formatter={(value: number) =>
                                                value > 0
                                                    ? numeral(value).format(
                                                          '0,0'
                                                      )
                                                    : ''
                                            }
                                        />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <span className="disclaimer">
                * The Policy Design you choose may impact the tax status of your
                policy. If you pay too much premiums your policy could become a
                modified endowment contract (MEC). Distributions from a MEC may
                be taxable and if the taxpayer is under the age of 59 1⁄2 may
                also be subject to an additional 10% penalty tax. Check the
                attached illustration to determine if the policy illustrated is
                a MEC.
                <br />
                ** Assumes death benefit paid at age 100 and income payments
                continue to age 100.
                <br />
                *** Pre‐tax equivalent values are the amounts required to attain
                the after‐tax benefits shown, assuming a{' '}
                {numeral(pds.combinedTaxRate).format('0,0.0%')} tax bracket.
                <br />
                Hypothetical example is for illustrative purposes only. This is
                not a prediction or guarantee of actual results, which will vary
                from those described.
                <br />
                Depending upon actual policy experience, the Owner may need to
                increase premium payments to maintain coverage for the desired
                duration. Any policy loans and partial surrenders will affect
                policy values and may require additional premiums to avoid
                policy termination.
                <br />
                Life insurance guarantees rely on the fiscal strength and claims
                paying ability of the issuing insurer. Universal life insurance
                products are not bank or FDIC insured. Indexed Universal Life
                insurance products are not an investment in the stock market and
                are subject to all policy fees and charges associated with
                Universal Life policies.
                <br />
                ARIA Financial Software, LLC does not provide tax, legal or
                accounting advice, nor is it designed ‐ nor intended ‐ to be
                appliable to any person's individual circumstances. This
                material has been prepared for information purposes only, and is
                not intended to provide, and should not be relied on for, tax,
                legal or accounting advice. You should consult your own
                qualified tax, legal and accounting advisors before engaging in
                any transaction.
            </span>
        </Card>
    )
}

export default AtapSummary
