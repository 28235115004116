import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestAgencies } from '../actions'
import AgencyList from '../components/agency/list'

const AgenciesPage = (props) => {
    useEffect(() => {
        props.requestAgencies(props.page, props.pageSize, props.sorted)
    }, [])

    const handleTableChange = (pagination, filters, sorter) => {
        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        props.requestAgencies(
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    return <AgencyList pageChangeHandler={handleTableChange} {...props} />
}

const mapStateToProps = (state) => {
    return {
        ...state.agencies,
    }
}

export default connect(mapStateToProps, { requestAgencies })(AgenciesPage)
