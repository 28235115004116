import numeral from 'numeral'
import React from 'react'
import { IncomeCompModelData } from '../..'

interface ICRmdCompProps {
    data: IncomeCompModelData
}

export const ICRmdComp = (props: ICRmdCompProps) => {
    const { data } = props
    const taxPaidOnReallocAmount =
        data.amountToReallocate * (data.taxBracket / 100)
    const afterReallocTaxesPaid =
        taxPaidOnReallocAmount +
        (data.rmdResult.after.totalTaxPaid +
            data.rmdResult.after.totalInheritanceTaxPaid) +
        (data.rmdResult.taxFree.totalTaxPaid +
            data.rmdResult.taxFree.totalInheritanceTaxPaid)
    return (
        <div>
            <h1 className="text-blue text-center text-h1-spacing">
                Required Minimum Distribution (RMD) Comparison
            </h1>
            <h2 className="text-blue text-center">
                Initial Qualified Plan Balance:{' '}
                {numeral(data.qualifiedPlanBalance).format('$0,0')}
            </h2>
            <div>
                Different strategies for accumulating funds for retirement
                income have advantages and disadvantages.
            </div>
            <div className="text-bold">
                How do the assets grow? How and when will you be taxed? When can
                you access funds?
            </div>
            <div className="row center-content">
                <table className="ic-rmdcomp-advantages">
                    <thead>
                        <tr>
                            <td className="text-center text-bold ic-bottom-border">
                                Tax Deferred Strategies
                            </td>
                            <td className="empty-col" />
                            <td className="text-center text-bold ic-bottom-border">
                                Tax-Advantaged Strategies
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">IRA, 401(k)</td>
                            <td className="empty-col" />
                            <td className="text-center">
                                Roth, Other Tax‐Advantaged
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                Fund with pre‐tax dollars
                            </td>
                            <td className="empty-col" />
                            <td className="text-center">
                                Fund with after‐tax dollars
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center text-bold">
                                RMDs ‐ age 72
                            </td>
                            <td className="empty-col" />
                            <td className="text-center text-bold">No RMDs</td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                Penalty for withdrawals before age 59 1/2
                            </td>
                            <td className="empty-col" />
                            <td className="text-center">
                                Limited or no penalty*
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center text-bold">
                                Taxable withdrawals
                            </td>
                            <td className="empty-col" />
                            <td className="text-center text-bold">
                                Tax‐free withdrawals*
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center text-bold">
                                Remainder at death is taxable
                            </td>
                            <td className="empty-col" />
                            <td className="text-center text-bold">
                                Remainder at death tax‐advantaged or tax‐free**
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table className="ic-rmdcomp-table" style={{ width: 800 }}>
                <thead>
                    <tr>
                        <td colSpan={8} className="text-bold ic-gray-bgnd">
                            IMPACT OF REALLOCATION OF QUALIFIED PLAN ASSETS
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-bold" colSpan={3}></th>
                        <th className="text-bold ic-4-borders ic-yellow-bgnd">
                            Qualified Plan
                            <br />
                            Current
                        </th>
                        <th className="empty-col"></th>
                        <th className="text-bold ic-4-borders ic-green-bgnd">
                            Remainder After
                            <br />
                            Reallocation of
                            <br />
                            {numeral(data.amountToReallocate).format('$0,0')}
                        </th>
                        <th className="empty-col"></th>
                        <th className="text-bold ic-4-borders ic-green-bgnd">
                            Tax-Advantaged
                            <br />
                            Plan
                        </th>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td className="text-bold" colSpan={3}>
                            Initial Qualified Plan Balance:{' '}
                            {numeral(data.qualifiedPlanBalance).format('$0,0')}
                        </td>
                        <td className="text-number text-bold ic-4-borders ic-yellow-bgnd">
                            {numeral(data.qualifiedPlanBalance).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number text-bold ic-4-borders ic-green-bgnd">
                            {numeral(
                                data.qualifiedPlanBalance -
                                    data.amountToReallocate
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number text-bold ic-4-borders ic-green-bgnd">
                            {numeral(
                                data.amountToReallocate *
                                    (1 - data.taxBracket / 100)
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>Total Projected Distributions</td>
                        <td className="text-number ic-top-border ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedDistributions
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-top-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after.totalProjectedDistributions
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-top-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree
                                    .totalProjectedDistributions
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-red" colSpan={3}>
                            Less: Total Estimated Income Taxes @{' '}
                            {data.taxBracket}%
                        </td>
                        <td className="text-red text-number ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current.totalTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-red text-number ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(data.rmdResult.after.totalTaxPaid).format(
                                '$0,0'
                            )}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-red text-number ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree.totalTaxPaid
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            Total Projected Net (After Tax Distributions)
                        </td>
                        <td className="text-number ic-bottom-border ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedDistributions -
                                    data.rmdResult.current.totalTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-bottom-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedDistributions -
                                    data.rmdResult.after.totalTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-bottom-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree
                                    .totalProjectedDistributions -
                                    data.rmdResult.taxFree.totalTaxPaid
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>Total Projected Inheritance (Gross)</td>
                        <td className="text-number ic-top-border ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedInheritanceGross
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-top-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedInheritanceGross
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-top-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree
                                    .totalProjectedInheritanceGross
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-red" colSpan={3}>
                            Less: Total Estimated Income Taxes @{' '}
                            {data.taxBracket}%
                        </td>
                        <td className="text-red text-number ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current.totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-red text-number ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after.totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-red text-number ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree.totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            Total Projected After Lump Sum Taxable Inheritance
                            (Net)
                        </td>
                        <td className="text-number ic-bottom-border ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedInheritanceGross -
                                    data.rmdResult.current
                                        .totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-bottom-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedInheritanceGross -
                                    data.rmdResult.after.totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-number ic-bottom-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree
                                    .totalProjectedInheritanceGross -
                                    data.rmdResult.taxFree
                                        .totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td className="text-bold" colSpan={3}>
                            Total Distributions
                        </td>
                        <td className="text-bold text-number ic-top-border ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedDistributions +
                                    data.rmdResult.current
                                        .totalProjectedInheritanceGross
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-number ic-top-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedDistributions +
                                    data.rmdResult.after
                                        .totalProjectedInheritanceGross
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-number ic-top-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree
                                    .totalProjectedDistributions +
                                    data.rmdResult.taxFree
                                        .totalProjectedInheritanceGross
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-red text-bold" colSpan={3}>
                            Total Taxes
                        </td>
                        <td className="text-red text-bold text-number ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current.totalTaxPaid +
                                    data.rmdResult.current
                                        .totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-red text-bold text-number ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after.totalTaxPaid +
                                    data.rmdResult.after.totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-red text-bold text-number ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree.totalTaxPaid +
                                    data.rmdResult.taxFree
                                        .totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-bold" colSpan={3}>
                            Total Net Distributions
                        </td>
                        <td className="text-bold text-number ic-bottom-border ic-left-border ic-right-border ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedDistributions +
                                    data.rmdResult.current
                                        .totalProjectedInheritanceGross -
                                    (data.rmdResult.current.totalTaxPaid +
                                        data.rmdResult.current
                                            .totalInheritanceTaxPaid)
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-number ic-bottom-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedDistributions +
                                    data.rmdResult.after
                                        .totalProjectedInheritanceGross -
                                    (data.rmdResult.after.totalTaxPaid +
                                        data.rmdResult.after
                                            .totalInheritanceTaxPaid)
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-number ic-bottom-border ic-left-border ic-right-border ic-green-bgnd">
                            {numeral(
                                data.rmdResult.taxFree
                                    .totalProjectedDistributions +
                                    data.rmdResult.taxFree
                                        .totalProjectedInheritanceGross -
                                    (data.rmdResult.taxFree.totalTaxPaid +
                                        data.rmdResult.taxFree
                                            .totalInheritanceTaxPaid)
                            ).format('$0,0')}
                        </td>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td colSpan={8} className="text-bold ic-gray-bgnd">
                            BENEFIT DIFFERENTIAL
                        </td>
                    </tr>
                    <tr>
                        <th className="text-bold"></th>
                        <th className="text-bold ic-4-borders ic-yellow-bgnd">
                            Qualified Plan
                            <br />
                            Current
                        </th>
                        <th className="empty-col"></th>
                        <th className="text-bold ic-4-borders ic-green-bgnd">
                            After
                            <br />
                            Reallocation
                        </th>
                        <th className="empty-col"></th>
                        <th className="text-bold ic-4-borders ic-blue-bgnd">
                            Difference
                        </th>
                        <th></th>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td className="text-bold">Total Net Income</td>
                        <td className="text-bold text-center ic-4-borders ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedDistributions -
                                    data.rmdResult.current.totalTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-center ic-4-borders ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedDistributions -
                                    data.rmdResult.after.totalTaxPaid +
                                    (data.rmdResult.taxFree
                                        .totalProjectedDistributions -
                                        data.rmdResult.taxFree.totalTaxPaid)
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-center ic-4-borders ic-blue-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedDistributions -
                                    data.rmdResult.after.totalTaxPaid +
                                    (data.rmdResult.taxFree
                                        .totalProjectedDistributions -
                                        data.rmdResult.taxFree.totalTaxPaid) -
                                    (data.rmdResult.current
                                        .totalProjectedDistributions -
                                        data.rmdResult.current.totalTaxPaid)
                            ).format('$0,0')}
                        </td>
                        <td></td>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td className="text-bold">Family Benefit</td>
                        <td className="text-bold text-center ic-4-borders ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current
                                    .totalProjectedInheritanceGross -
                                    data.rmdResult.current
                                        .totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-center ic-4-borders ic-green-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedInheritanceGross -
                                    data.rmdResult.after
                                        .totalInheritanceTaxPaid +
                                    (data.rmdResult.taxFree
                                        .totalProjectedInheritanceGross -
                                        data.rmdResult.taxFree
                                            .totalInheritanceTaxPaid)
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-center ic-4-borders ic-blue-bgnd">
                            {numeral(
                                data.rmdResult.after
                                    .totalProjectedInheritanceGross -
                                    data.rmdResult.after
                                        .totalInheritanceTaxPaid +
                                    (data.rmdResult.taxFree
                                        .totalProjectedInheritanceGross -
                                        data.rmdResult.taxFree
                                            .totalInheritanceTaxPaid) -
                                    (data.rmdResult.current
                                        .totalProjectedInheritanceGross -
                                        data.rmdResult.current
                                            .totalInheritanceTaxPaid)
                            ).format('$0,0')}
                        </td>
                        <td></td>
                    </tr>
                    <tr className="empty-row">
                        <td colSpan={8}></td>
                    </tr>
                    <tr>
                        <td className="text-bold">Taxes Paid</td>
                        <td className="text-bold text-center ic-4-borders ic-yellow-bgnd">
                            {numeral(
                                data.rmdResult.current.totalTaxPaid +
                                    data.rmdResult.current
                                        .totalInheritanceTaxPaid
                            ).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-center ic-4-borders ic-green-bgnd">
                            {numeral(afterReallocTaxesPaid).format('$0,0')}
                        </td>
                        <td className="empty-col"></td>
                        <td className="text-bold text-center ic-4-borders ic-blue-bgnd">
                            {numeral(
                                afterReallocTaxesPaid -
                                    (data.rmdResult.current.totalTaxPaid +
                                        data.rmdResult.current
                                            .totalInheritanceTaxPaid)
                            ).format('$0,0')}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <br />
            <div>
                Distributions from RMDs and tax‐advantaged plan occur to age
                100.
            </div>
            <br />
            <div>
                * The Policy Design you choose may impact the tax status of your
                policy. If you pay too much premiums your policy could
                <br />
                become a modified endowment contract (MEC). Distributions from a
                MEC may be taxable and if the taxpayer is under the
                <br />
                age of 59 1⁄2 may also be subject to an additional 10% penalty
                tax. Check the attached illustration to determine if the
                <br />
                policy illustrated is a MEC.
            </div>
            <div>
                ** The earnings on certain tax‐advantaged plans are taxable to
                heirs.
            </div>
        </div>
    )
}
