import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { agencyService } from '../services/agency.service'

const {
    AGENCIES_GET_REQUEST,
    successAgencies,
    failedAgencies,
    AGENCY_GET_REQUEST,
    successAgency,
    failedAgency,
    AGENCY_AGENTS_GET_REQUEST,
    successAgencyAgents,
    failedAgencyAgents,
    AGENCIES_AGENTS_GET_REQUEST,
    successAgenciesAgents,
    failedAgenciesAgents,
    AGENCY_UPDATE_REQUEST,
    failedUpdateAgency,
    AGENCY_CREATE_REQUEST,
    failedCreateAgency,
    processErrorMessage,
    successAlert,
    AGENCY_AGENT_CREATE_REQUEST,
} = actions

export function* getAgencies() {
    while (true) {
        const { page, perPage, sorted } = yield take(AGENCIES_GET_REQUEST)
        try {
            const response = yield call(agencyService.getAllAgencies, {
                page,
                perPage,
                sorted,
            })
            yield put(successAgencies(response))
        } catch (error) {
            yield put(failedAgencies(error))
        }
    }
}

export function* getAgency() {
    while (true) {
        const { agencyId } = yield take(AGENCY_GET_REQUEST)
        try {
            const response = yield call(agencyService.getAgency, agencyId)
            yield put(successAgency(response))
        } catch (error) {
            yield put(failedAgency(error))
        }
    }
}

export function* getAgencyAgents() {
    while (true) {
        const { agencyId, page, perPage, sorted } = yield take(
            AGENCY_AGENTS_GET_REQUEST
        )
        try {
            const response = yield call(
                agencyService.getAgencyAgents,
                agencyId,
                page,
                perPage,
                sorted
            )
            yield put(successAgencyAgents(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAgencyAgents(error))
        }
    }
}

export function* getAgenciesAgents() {
    while (true) {
        yield take(AGENCIES_AGENTS_GET_REQUEST)
        try {
            const response = yield call(agencyService.getAgenciesAgents)
            yield put(successAgenciesAgents(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAgenciesAgents(error))
        }
    }
}

export function* updateAgency() {
    while (true) {
        const { agencyId, payload } = yield take(AGENCY_UPDATE_REQUEST)
        try {
            yield call(agencyService.updateAgency, { agencyId, payload })
            payload.history.push(`/agencies/${agencyId}`)
            yield put(successAlert('Agency updated'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdateAgency(error))
        }
    }
}

export function* createAgency() {
    while (true) {
        const { payload } = yield take(AGENCY_CREATE_REQUEST)
        try {
            const response = yield call(agencyService.createAgency, payload)
            payload.history.push(`/agencies/${response.data.id}`)
            yield put(successAlert('Agency created'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateAgency(error))
        }
    }
}

export function* createAgencyAgent() {
    while (true) {
        const { agencyId, payload } = yield take(AGENCY_AGENT_CREATE_REQUEST)
        try {
            yield call(agencyService.addAgencyAgent, {
                agencyId,
                payload,
            })
            payload.history.push(`/agencies/${agencyId}`)
            yield put(successAlert('Agent created'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(actions.failedCreateAgencyAgent(error))
        }
    }
}
