import React from 'react'
import LoadingField from '../../shared_components/loading_field'

const ProposalClientPanel = (props) => {
    const {
        agentName,
        customer,
        clientName,
        clientAge,
        clientGender,
        loading,
    } = props
    return (
        <div className="panel panel-default panel-detail">
            <div className="panel-heading">
                <h3 className="panel-title">Customer</h3>
            </div>
            <div className="panel-body">
                <div className="col-lg-6 col-md-6">
                    {!loading ? clientName : <LoadingField />}
                    {loading && <LoadingField />}
                    {!loading && (
                        <a href={`/customers/${customer}`}>
                            <i className="fa fa-pencil fa-1x" />
                        </a>
                    )}
                </div>
                <div className="col-lg-6 col-md-6">
                    Gender:{' '}
                    {!loading ? clientGender : <LoadingField width={30} />}
                    <br />
                    Age: {!loading ? clientAge : <LoadingField width={20} />}
                    <br />
                    Agent: {!loading ? agentName : <LoadingField width={20} />}
                </div>
            </div>
        </div>
    )
}

export default ProposalClientPanel
