import FileSaver from 'file-saver'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import {
    requestIncomeComp,
    requestUpdateIncomeComp,
    requestUpdateIncomeCompTaxCalc,
} from '../actions'
import IncomeCompView from '../components/incomeComp/view'
import Loading from '../components/shared_components/loading'
import { get } from '../services/api'

const IncomeViewPage = (props) => {
    const params = useParams()

    const {
        loading,
        data,
        requestIncomeComp,
        requestUpdateIncomeComp,
        requestUpdateIncomeCompTaxCalc,
    } = props

    useEffect(() => {
        requestIncomeComp(params.incomeCompId)
    }, [])

    const downloadPDF = (custName) => {
        get(`/api/v1/income-comps/${params.incomeCompId}/pdf/`).then(
            (response) => {
                var blobData = new Blob([response.data], {
                    type: 'application/pdf',
                })
                FileSaver.saveAs(
                    blobData,
                    `income_tax_comparison_${moment().format(
                        'YYYY-MM-DD'
                    )}_${custName}.pdf`
                )
            }
        )
    }

    const updateIncomeComp = (payload) => {
        requestUpdateIncomeComp(params.incomeCompId, payload)
    }

    const updateIncomeCompTaxCalc = (payload) => {
        requestUpdateIncomeCompTaxCalc(params.incomeCompId, payload)
    }

    if (loading || data === undefined)
        return <Loading title="Income Tax Comparison" />

    return (
        <IncomeCompView
            downloadPDF={downloadPDF}
            updateHandler={updateIncomeComp}
            updateTaxCalcHandler={updateIncomeCompTaxCalc}
            {...props}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.incomeComp,
        taxCalcStatus: { ...state.taxCalcStatus },
    }
}

export default connect(mapStateToProps, {
    requestIncomeComp,
    requestUpdateIncomeComp,
    requestUpdateIncomeCompTaxCalc,
})(IncomeViewPage)
