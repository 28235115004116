import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, message } from 'antd'
import AgeAndDob from './age_and_dob'
import IncomeCalc from './income_calc'
import NetWorthCalc from './networth_calc'
import moment from 'moment'
import OtherFactors from './other_factor'
import IncomeReplacement from './income_replacement'
import EstatePreservation from './estate_preservation'
import MaxAnnualPremium from './max_annual_premium'
import { incomeFactors, estateFactors, lnwPremFactors } from './constants'

const PreQualInsurancePage = () => {
    const [data, setData] = useState({
        dob: undefined,
        incomeFactor: undefined,
        estateFactor: undefined,
        lnwPremFactor: undefined,
        totalIncome: 0,
        totalNetWorth: 0,
        totalLiquidNetWorth: 0,
        insInforce: 0,
        numPremPay: 3,
        currentAge: '...',
    })

    const _dobChange = (d) => {
        const age = moment().diff(d, 'years', true).toFixed(1)
        if (age < 15) {
            message.warn('Age must be greater than 15 years.')
            return
        }

        var incFac = incomeFactors.find(
            (incFac) => incFac.age === Math.round(age)
        )
        var estFac = estateFactors.find(
            (estFac) => estFac.age === Math.round(age)
        )
        var lnwFac = lnwPremFactors.find(
            (lnwFac) => lnwFac.age === Math.round(age)
        )

        setData({
            ...data,
            dob: d,
            currentAge: age,
            incomeFactor: incFac.factor,
            estateFactor: estFac.years,
            lnwPremFactor: lnwFac.rate,
        })
    }

    const _totalIncomeChanges = (totalIncome) => {
        setData({ ...data, totalIncome })
    }

    const _totalNetWorthChanges = (totalNetWorth, totalLiquidNetWorth) => {
        setData({ ...data, totalNetWorth, totalLiquidNetWorth })
    }

    const _otherFactorsChanged = (numPremPay, insInforce) => {
        setData({ ...data, numPremPay, insInforce })
    }

    const totalIncome = data.totalIncome
    var incMaxBenefit =
        totalIncome > 0 && data.incomeFactor
            ? totalIncome * data.incomeFactor - data.insInforce
            : null
    var incMaxPremium = incMaxBenefit ? totalIncome * 0.2 : null
    var estMaxBenefit =
        data.totalNetWorth > 0 && data.estateFactor
            ? Math.round(
                  (data.totalNetWorth *
                      (Math.pow(1.06, data.estateFactor) / 2) -
                      data.insInforce) *
                      100
              ) / 100
            : null
    var estMaxPremium =
        data.totalLiquidNetWorth > 0 && data.lnwPremFactor
            ? data.totalLiquidNetWorth * (data.lnwPremFactor / 100)
            : null
    var estAnnPremium = estMaxPremium
        ? Math.round((estMaxPremium / data.numPremPay) * 100) / 100
        : null

    return (
        <Card title="Pre-Qualify Insurance" bordered={false}>
            <Row>
                <Col span={12}>
                    <AgeAndDob
                        dobChanged={_dobChange}
                        currentAge={data.currentAge}
                    />
                    <IncomeCalc totalIncomeChanges={_totalIncomeChanges} />
                    <br />
                    <NetWorthCalc
                        totalNetWorthChanges={_totalNetWorthChanges}
                    />
                    <br />
                    <OtherFactors
                        onChange={_otherFactorsChanged}
                        numPremPay={data.numPremPay}
                        insInforce={data.insInforce}
                    />
                </Col>
                <Col span={12}>
                    <IncomeReplacement
                        factor={data.incomeFactor}
                        maxDeathBenefit={incMaxBenefit}
                    />
                    <EstatePreservation
                        factor={data.estateFactor}
                        maxDeathBenefit={estMaxBenefit}
                    />
                    <MaxAnnualPremium
                        factor={data.lnwPremFactor}
                        totalLiquidNetWorth={data.totalLiquidNetWorth}
                        incomeSource={incMaxPremium}
                        incomeLiquid={estAnnPremium}
                    />
                </Col>
            </Row>
            <Row>
                <h4>Insurable Interest</h4>
                <p>
                    The death benefit must approximate the financial loss of the
                    beneficiary created by the death of the insured. The amount
                    of insurance is based on the financial details of the sale,
                    not by the affordability of the premium. Insurable interest
                    is usually straightforward. If you have an unusual
                    situation, partner with New Business.
                </p>
                <p>
                    <b>Note:</b> The primary wage earner should have more
                    insurance, unless they are uninsurable, due to health.
                </p>
            </Row>
        </Card>
    )
}

export default connect()(PreQualInsurancePage)
