import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { requestIllustration, requestIndexes } from '../actions'
import IllustrationView from '../components/illustration/view'

const IllustrationViewPage = (props) => {
    const params = useParams()

    useEffect(() => {
        props.requestIndexes()
        props.requestIllustration(params.illustrationId)
    }, [])

    return <IllustrationView {...props} />
}

const mapStateToProps = (state) => {
    return {
        ...state.illustration,
        indexes: state.indexes,
    }
}

export default connect(mapStateToProps, {
    requestIllustration,
    requestIndexes,
})(IllustrationViewPage)
