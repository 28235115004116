import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestIllustrations, requestClients } from '../actions'
import IllustrationList from '../components/illustration/list'

const IllustrationsPage = (props) => {
    useEffect(() => {
        props.requestIllustrations(props.page, props.pageSize, props.sorted)
        props.requestClients()
    }, [])

    const pageChangeHandler = (pagination, filters, sorter) => {
        console.debug('sorter', sorter)

        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        props.requestIllustrations(
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    return <IllustrationList pageChangeHandler={pageChangeHandler} {...props} />
}

const mapStateToProps = (state) => {
    return { ...state.illustrations }
}

export default connect(mapStateToProps, {
    requestIllustrations,
    requestClients,
})(IllustrationsPage)
