import React from "react";

class Container extends React.Component {

    render() {
        return (
            <div style={{ marginLeft: 20, marginRight: 20 }}>
                {this.props.children}
            </div>
        )
    }
}

export default Container;