import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestAriaV2, requestAriaV2StaticPDFs } from '../actions'
import FileSaver from 'file-saver'
import moment from 'moment'
import { get } from '../services/api'
import AriaV2View from '../components/aria-v2/view'
import { useParams } from 'react-router-dom'

const ArialV2ViewPage = (props) => {
    const params = useParams()

    useEffect(() => {
        if (params.proposalId !== 'add') {
            props.requestAriaV2(params.proposalId)
            props.requestAriaV2StaticPDFs()
        }
    }, [])

    const downloadPDFv2 = (custName, document_id) => {
        get(`/api/v1/aria-v2/${params.proposalId}/pdf/${document_id}/`).then(
            (response) => {
                var data = new Blob([response.data], {
                    type: 'application/pdf',
                })
                FileSaver.saveAs(
                    data,
                    `proposal_${document_id}_${moment().format(
                        'YYYY-MM-DD'
                    )}_${custName}.pdf`
                )
            }
        )
    }

    if (params.proposalId !== 'add') {
        return <AriaV2View {...props} downloadPDFv2={downloadPDFv2} />
    } else {
        return <></>
    }
}

const mapStateToProps = (state) => {
    return { ...state.ariav2, pdfs: state.ariaV2StaticPDFs, ...state.auth }
}

export default connect(mapStateToProps, {
    requestAriaV2,
    requestAriaV2StaticPDFs,
})(ArialV2ViewPage)
