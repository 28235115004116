import { Button, Form, Select, Tooltip, Icon } from 'antd';
import React from "react";
const { Option } = Select;

class ProposalEmailForm extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        /*this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.props.onSend(values);
            }
        });*/
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const options = this.props.email ? this.props.emails.map(d => <Option key={d.email}>{d.email}</Option>) : [];

        return (
            <Form
                title="Test" onSubmit={this.handleSubmit}>
                <Form.Item
                    label={
                        <span>
                            Email Addresses&nbsp;
                            <Tooltip title="Multiple email address can be entered separated by ; or space">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }>
                    {getFieldDecorator('toEmails', {
                        rules: [{ required: true, message: 'Please input at least one email address!' }],
                    })(
                        <Select
                            mode="tags"
                            size={'large'}
                            // value={this.props.emails}
                            tokenSeparators={[';', ' ']}
                            placeholder="Please select"
                            // onChange={(value) => this.setState({ toEmails: value })}
                            style={{ width: '100%' }}
                        >
                            {options}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            CC Email Addresses&nbsp;
                        <Tooltip title="Multiple email address can be entered separated by ; or space">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }>
                    {getFieldDecorator('ccEmails', {
                        rules: [{ required: false, message: '' }],
                    })(
                        <Select
                            mode="tags"
                            size={'large'}
                            // value={emails}
                            tokenSeparators={[';', ' ']}
                            placeholder="Please select"
                            // onChange={(value) => this.setState({ ccEmails: value })}
                            style={{ width: '100%' }}
                        >
                            {options}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button disabled={true} type="primary" htmlType="submit">
                        Send Link
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedProposalEmailForm = Form.create({ name: 'send_proposal_link_form' })(ProposalEmailForm);
export default WrappedProposalEmailForm;