/**
 * Created by Sina Khelil on 11/15/16.
 */

import React, { Component } from 'react'
import request from 'axios'
import saveAs from 'file-saver'
import moment from 'moment'
import numeral from 'numeral'
import is from 'is_js'

export default class BizPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agentName: '',
            planYear: moment().year(),
            targetPremiumPerSaleYear1: 35000,
            annualIncomeGoal: 1000000,
            annuityCompLevel: 7,
            annuityPremiumCollected: 0,
            earnedTargetPremiumYTD: 0,
            expenses: 0,
            taxRate: 0,
            lifeCommissionLevel: 85,
            avgClosingRate: 15,
            seminarMailers: 7500,
            seminarMailersCost: 0.55,
            seminarMailersRespRate: 1.2,
            seminarCloseRate: 15,
            seminarRestaurantCostPer: 30,
            seminarAttendeesActual: 75,
            seminarAttendeesAppt: 20,
        }
        this.handleChange = this.handleChange.bind(this)
        this.downloadPDF = this.downloadPDF.bind(this)
    }

    handleChange(e) {
        const val = e.currentTarget.value
        const key = e.currentTarget.name
        if (!is.empty(val)) {
            let param = {}
            param[key] = val
            this.setState(param)
        }
    }

    downloadPDF() {
        const {
            agentName,
            planYear,
            annuityPremiumCollected,
            annuityCompLevel,
            annualIncomeGoal,
            lifeCommissionLevel,
            earnedTargetPremiumYTD,
            expenses,
            seminarMailers,
            seminarMailersRespRate,
            seminarMailersCost,
            seminarRestaurantCostPer,
            seminarAttendeesAppt,
            seminarCloseRate,
            seminarAttendeesActual,
            avgClosingRate,
            targetPremiumPerSaleYear1,
            taxRate,
        } = this.state
        const data = {
            agentName: agentName,
            planYear: planYear,
            targetPremiumPerSaleYear1: targetPremiumPerSaleYear1,
            annualIncomeGoal: annualIncomeGoal,
            annuityCompLevel: annuityCompLevel,
            annuityPremiumCollected: annuityPremiumCollected,
            earnedTargetPremiumYTD: earnedTargetPremiumYTD,
            expenses: expenses,
            taxRate: taxRate,
            lifeCommissionLevel: lifeCommissionLevel,
            avgClosingRate: avgClosingRate,
            seminarMailers: seminarMailers,
            seminarMailersCost: seminarMailersCost,
            seminarMailersRespRate: seminarMailersRespRate,
            seminarCloseRate: seminarCloseRate,
            seminarRestaurantCostPer: seminarRestaurantCostPer,
            seminarAttendeesActual: seminarAttendeesActual,
            seminarAttendeesAppt: seminarAttendeesAppt,
        }
        request
            .post('/api/v1/bizplan/', data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
                xsrfHeaderName: 'X-CSRFTOKEN',
                xsrfCookieName: 'csrftoken',
            })
            .then((resp) => {
                if (resp.statusText === 'OK') {
                    const data = new Blob([resp.data], {
                        type: 'application/pdf',
                    })
                    saveAs(
                        data,
                        `${agentName}_${moment().format('X')}_bizplan.pdf`
                    )
                }
            })
            .catch((err) => {
                // TODO: log errors
                console.error(err)
            })
    }

    render() {
        const {
            agentName,
            planYear,
            annuityPremiumCollected,
            annuityCompLevel,
            annualIncomeGoal,
            lifeCommissionLevel,
            earnedTargetPremiumYTD,
            expenses,
            seminarMailers,
            seminarMailersRespRate,
            seminarMailersCost,
            seminarRestaurantCostPer,
            seminarAttendeesAppt,
            seminarCloseRate,
            seminarAttendeesActual,
            avgClosingRate,
            targetPremiumPerSaleYear1,
            taxRate,
        } = this.state
        const earnings = annuityPremiumCollected * (1 + annuityCompLevel / 100),
            remainingTargetToEarn =
                (annualIncomeGoal * 100.0001) / lifeCommissionLevel -
                earnedTargetPremiumYTD -
                earnings,
            incomeMonthly = annualIncomeGoal / 12,
            incomeWeekly = annualIncomeGoal / 52,
            incomeDaily = annualIncomeGoal / 240,
            incomeHourly = annualIncomeGoal / 1923,
            incomeNet = annualIncomeGoal - expenses,
            seminarAttendees = seminarMailers * (seminarMailersRespRate / 100),
            closedPerSeminar = (seminarAttendees / 4) * (avgClosingRate / 100),
            revenuePerSeminar =
                targetPremiumPerSaleYear1 *
                closedPerSeminar *
                (lifeCommissionLevel / 100),
            profitNet = incomeNet * ((100 - taxRate) / 100),
            annualTargetPremiumAmount =
                (annualIncomeGoal * 100.0001) / lifeCommissionLevel -
                (earnedTargetPremiumYTD + earnings),
            costPerSeminar =
                seminarMailers * seminarMailersCost +
                seminarRestaurantCostPer * seminarAttendees,
            avgTargetPremiumPerSeminar =
                targetPremiumPerSaleYear1 *
                (seminarAttendeesAppt * (seminarCloseRate / 100)),
            incomePerSeminar =
                (lifeCommissionLevel / 100) * avgTargetPremiumPerSeminar,
            seminarsPerYear = annualIncomeGoal / incomePerSeminar,
            remainingPremiumGoal =
                ((annualIncomeGoal * 100.0001) / lifeCommissionLevel) * 3.4,
            annualProspectsToClose =
                annualIncomeGoal /
                (targetPremiumPerSaleYear1 * (lifeCommissionLevel / 100))

        return (
            <div>
                <h2 className="text-center">Agent Business Plan</h2>
                <div className="row">
                    <div className="col-md-offset-5 col-md-2 text-center">
                        <div className="form-group">
                            <input
                                type="text"
                                name="agentName"
                                className="form-control"
                                value={agentName}
                                onChange={this.handleChange}
                                placeholder="Agent Name or Blank"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="planYear"
                                className="form-control text-center"
                                value={planYear}
                                onChange={this.handleChange}
                                placeholder="Plan Year"
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={this.downloadPDF}
                        >
                            Download PDF
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>Premium and Target Goals</h3>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-md-offset-1 col-md-5 text-right form-group"
                        style={{ zIndex: 0 }}
                    >
                        <div className="col-md-8">
                            Agents Avg. First Year Target Premium / Sale:{' '}
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">$</div>
                                <input
                                    type="number"
                                    name="targetPremiumPerSaleYear1"
                                    className="form-control number"
                                    value={targetPremiumPerSaleYear1}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            Earned Target Premium YTD:{' '}
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">$</div>
                                <input
                                    type="number"
                                    name="earnedTargetPremiumYTD"
                                    className="form-control number"
                                    value={earnedTargetPremiumYTD}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            Your Life Commission Level:{' '}
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">%</div>
                                <input
                                    type="number"
                                    name="lifeCommissionLevel"
                                    className="form-control number"
                                    value={lifeCommissionLevel}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">Annual Income Goal: </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">$</div>
                                <input
                                    type="number"
                                    name="annualIncomeGoal"
                                    className="form-control number"
                                    value={annualIncomeGoal}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">Annuity Comp Level: </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">%</div>
                                <input
                                    type="number"
                                    name="annuityCompLevel"
                                    className="form-control number"
                                    value={annuityCompLevel}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            Annuity Premium Collected:{' '}
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">$</div>
                                <input
                                    type="number"
                                    name="annuityPremiumCollected"
                                    className="form-control number"
                                    value={annuityPremiumCollected}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">Expenses:</div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">$</div>
                                <input
                                    type="number"
                                    name="expenses"
                                    className="form-control number"
                                    value={expenses}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">Tax Rate: </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">%</div>
                                <input
                                    type="number"
                                    name="taxRate"
                                    className="form-control number"
                                    value={taxRate}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5" style={{ zIndex: 0 }}>
                        <div className="col-md-8">
                            Cost per direct mail piece:{' '}
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">$</div>
                                <input
                                    type="number"
                                    name="seminarMailersCost"
                                    className="form-control number"
                                    value={seminarMailersCost}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">Number of pieces:</div>
                        <div className="col-md-4">
                            <input
                                type="number"
                                name="seminarMailers"
                                className="form-control number"
                                value={seminarMailers}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-8">
                            Restaurant Cost per head:
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">$</div>
                                <input
                                    type="number"
                                    name="seminarRestaurantCostPer"
                                    className="form-control number"
                                    value={seminarRestaurantCostPer}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            Actual # of attendees (all nights):
                        </div>
                        <div className="col-md-4">
                            <input
                                type="number"
                                name="seminarAttendeesActual"
                                className="form-control number"
                                value={seminarAttendeesActual}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-8">
                            Attendees that made appointments:
                        </div>
                        <div className="col-md-4">
                            <input
                                type="number"
                                name="seminarAttendeesAppt"
                                className="form-control number"
                                value={seminarAttendeesAppt}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-8">Average Closing Rate: </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">%</div>
                                <input
                                    type="number"
                                    name="avgClosingRate"
                                    className="form-control number"
                                    value={avgClosingRate}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            Average Mailer Return Rate:{' '}
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <div className="input-group-addon">%</div>
                                <input
                                    type="number"
                                    name="seminarMailersRespRate"
                                    className="form-control number"
                                    value={seminarMailersRespRate}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-row12 text-center">
                        <h4>TARGET PREMIUM NEEDED TO MEET YOUR INCOME GOAL^</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-offset-1 col-md-6 text-right form-group">
                        <div className="col-md-8">
                            Your annual income goal is:{' '}
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(annualIncomeGoal).format('$0,000')}
                        </div>
                        <div className="col-md-8">
                            You must produce an annual target premium amount of:{' '}
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(annualTargetPremiumAmount).format(
                                '$0,000'
                            )}
                        </div>
                        <div className="col-md-8">
                            Semi-Annual Target Premium:{' '}
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(annualTargetPremiumAmount / 2).format(
                                '$0,000'
                            )}
                        </div>
                        <div className="col-md-8">
                            Quarterly Target Premium:{' '}
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(annualTargetPremiumAmount / 4).format(
                                '$0,000'
                            )}
                        </div>
                        <div className="col-md-8">Monthly Target Premium: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(annualTargetPremiumAmount / 12).format(
                                '$0,000'
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-md-8">
                            Remaining Target to Earn:{' '}
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(remainingTargetToEarn).format('$0,000')}
                        </div>
                        <div className="col-md-8">Monthly Income: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(incomeMonthly).format('$0,000')}
                        </div>
                        <div className="col-md-8">Weekly Income: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(incomeWeekly).format('$0,000')}
                        </div>
                        <div className="col-md-8">Daily Income: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(incomeDaily).format('$0,000')}
                        </div>
                        <div className="col-md-8">Hourly Income: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(incomeHourly).format('$0,000')}
                        </div>
                        <div className="col-md-8">Earnings: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(earnings).format('$0,000')}
                        </div>
                        <div className="col-md-8">Gross Income: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(annualIncomeGoal).format('$0,000')}
                        </div>
                        <div className="col-md-8">Net Income: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(incomeNet).format('$0,000')}
                        </div>
                        <div className="col-md-8">Net Profit: </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(profitNet).format('$0,000')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-row12 text-center">
                        <h4>CASES NEEDED TO MEET YOUR INCOME GOAL</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-offset-1 col-md-10 text-center">
                        <p>
                            Your annual income goal is{' '}
                            <span className="num-block-green">
                                {numeral(annualIncomeGoal).format('$0,000')}
                            </span>{' '}
                            using your average target premium per sale, you
                            must…
                        </p>
                        <p>
                            close{' '}
                            <span className="num-block-green">
                                {numeral(
                                    annualTargetPremiumAmount /
                                        targetPremiumPerSaleYear1
                                ).format('0')}
                            </span>{' '}
                            cases per year to produce{' '}
                            <span className="num-block-green">
                                {numeral(annualTargetPremiumAmount).format(
                                    '$0,000'
                                )}
                            </span>{' '}
                            in target
                        </p>
                        <p>
                            close{' '}
                            <span className="num-block-green">
                                {numeral(
                                    annualTargetPremiumAmount /
                                        2 /
                                        targetPremiumPerSaleYear1
                                ).format('0')}
                            </span>{' '}
                            cases semi-annually to produce{' '}
                            <span className="num-block-green">
                                {numeral(annualTargetPremiumAmount / 2).format(
                                    '$0,000'
                                )}
                            </span>{' '}
                            in target
                        </p>
                        <p>
                            close{' '}
                            <span className="num-block-green">
                                {numeral(
                                    annualTargetPremiumAmount /
                                        4 /
                                        targetPremiumPerSaleYear1
                                ).format('0')}
                            </span>{' '}
                            cases per quarter to produce{' '}
                            <span className="num-block-green">
                                {numeral(annualTargetPremiumAmount / 4).format(
                                    '$0,000'
                                )}
                            </span>{' '}
                            in target
                        </p>
                        <p>
                            close{' '}
                            <span className="num-block-green">
                                {numeral(
                                    annualTargetPremiumAmount /
                                        12 /
                                        targetPremiumPerSaleYear1
                                ).format('0')}
                            </span>{' '}
                            cases per month to produce{' '}
                            <span className="num-block-green">
                                {numeral(annualTargetPremiumAmount / 12).format(
                                    '$0,000'
                                )}
                            </span>{' '}
                            in target
                        </p>
                        <p>
                            To reach{' '}
                            <span className="num-block-green">
                                {numeral(annualTargetPremiumAmount).format(
                                    '$0,000'
                                )}
                            </span>{' '}
                            in total target and reach your goal of{' '}
                            <span className="num-block-green">
                                {numeral(annualIncomeGoal).format('$0,000')}
                            </span>{' '}
                            in commissions
                        </p>
                        <p>
                            You must produce an annual amount of premium of{' '}
                            <span className="num-block-green">
                                {numeral(remainingPremiumGoal).format('$0,000')}
                            </span>{' '}
                            on average.
                        </p>
                        <p>
                            Semi-Annual Premium{' '}
                            <span className="num-block-green">
                                {numeral(remainingPremiumGoal / 2).format(
                                    '$0,000'
                                )}
                            </span>
                        </p>
                        <p>
                            Quaterly Premium{' '}
                            <span className="num-block-green">
                                {numeral(remainingPremiumGoal / 4).format(
                                    '$0,000'
                                )}
                            </span>
                        </p>
                        <p>
                            Monthly Premium{' '}
                            <span className="num-block-green">
                                {numeral(remainingPremiumGoal / 12).format(
                                    '$0,000'
                                )}
                            </span>
                        </p>
                        <p>
                            Remaining Premium Goal{' '}
                            <span className="num-block-green">
                                {numeral(remainingTargetToEarn * 3.4).format(
                                    '$0,000'
                                )}
                            </span>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>
                            How Many Prospects Must You Meet (and close) to Make
                            Your Income Goal?+
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-offset-1 col-md-10 text-center">
                        <p>
                            If your average closing rate is{' '}
                            <span className="num-block-green">
                                {numeral(avgClosingRate / 100).format(
                                    '0,000.00%'
                                )}
                            </span>{' '}
                            how many prospects must you see to achieve your
                            goals?
                        </p>
                        <p>
                            Yearly income goal{' '}
                            <span className="num-block-green">
                                {numeral(annualIncomeGoal).format('$0,000')}
                            </span>
                        </p>
                        <p>
                            Yearly Prospects to see{' '}
                            <span className="num-block-green">
                                {numeral(
                                    annualProspectsToClose *
                                        (100 / avgClosingRate)
                                ).format('0')}
                            </span>{' '}
                            to close:{' '}
                            <span className="num-block-green">
                                {numeral(annualProspectsToClose).format('0')}
                            </span>
                        </p>
                        <p>
                            Quarterly Prospects to see{' '}
                            <span className="num-block-green">
                                {numeral(
                                    (annualProspectsToClose / 4) *
                                        (100 / avgClosingRate)
                                ).format('0')}
                            </span>{' '}
                            to close:{' '}
                            <span className="num-block-green">
                                {numeral(annualProspectsToClose / 4).format(
                                    '0'
                                )}
                            </span>
                        </p>
                        <p>
                            Monthly Prospects to see{' '}
                            <span className="num-block-green">
                                {numeral(
                                    (annualProspectsToClose / 12) *
                                        (100 / avgClosingRate)
                                ).format('0')}
                            </span>{' '}
                            to close:{' '}
                            <span className="num-block-green">
                                {numeral(annualProspectsToClose / 12).format(
                                    '0'
                                )}
                            </span>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>Seminar Return Values+</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-offset-1 col-md-10 text-center">
                        <p>
                            If you mail{' '}
                            <span className="num-block-green">
                                {numeral(seminarMailers).format('0,000')}
                            </span>{' '}
                            seminar invitations, and receive the average rate of
                            return of
                        </p>
                        <p>
                            <span className="num-block-green">
                                {numeral(seminarMailersRespRate).format(
                                    '0,000.00%'
                                )}
                            </span>{' '}
                            meaning{' '}
                            <span className="num-block-green">
                                {numeral(seminarAttendees).format('0')}
                            </span>
                            people, or{' '}
                            <span className="num-block-green">
                                {numeral(seminarAttendees / 2).format('0')}
                            </span>{' '}
                            buying units.
                        </p>
                        <p>
                            Suppose you are only able to make appointments with
                            1/2 or{' '}
                            <span className="num-block-green">
                                {numeral(seminarAttendees / 4).format('0')}
                            </span>{' '}
                            buying units.
                        </p>
                        <p>
                            If you close{' '}
                            <span className="num-block-green">
                                {numeral(seminarCloseRate).format('0,000.00%')}
                            </span>{' '}
                            you will have closed{' '}
                            <span className="num-block-green">
                                {numeral(closedPerSeminar).format('0')}
                            </span>{' '}
                            sales.
                        </p>
                        <p>
                            That means you've just made{' '}
                            <span className="num-block-green">
                                {numeral(revenuePerSeminar).format('$0,000')}
                            </span>{' '}
                            from one seminar!
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>
                            Now let's take a look at the cost per appointment
                            assumptions
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-offset-1 col-md-10 text-center">
                        <p>
                            On average, the cost of the{' '}
                            <span className="num-block-green">
                                {numeral(seminarMailers).format('0,000')}
                            </span>{' '}
                            piece mailer and the cost of the restaurant is
                        </p>
                        <p>
                            <span className="num-block-green">
                                {numeral(costPerSeminar).format('$0,000')}
                            </span>
                            , divided by{' '}
                            <span className="num-block-green">
                                {numeral(seminarAttendees / 4).format('0')}
                            </span>{' '}
                            appointments, the cost becomes{' '}
                            <span className="num-block-green">
                                {numeral(
                                    costPerSeminar / (seminarAttendees / 4)
                                ).format('$0,000')}
                            </span>
                        </p>
                        <p>
                            per appointment. This brings the cost per{' '}
                            <span className="num-block-green">
                                {numeral(closedPerSeminar).format('0')}
                            </span>{' '}
                            sales up to{' '}
                            <span className="num-block-green">
                                {numeral(
                                    (costPerSeminar / (seminarAttendees / 4)) *
                                        ((seminarAttendees / 4) *
                                            (avgClosingRate / 100))
                                ).format('$0,000')}
                            </span>
                        </p>
                        <p>
                            This brings your total return on this seminar
                            investment to:{' '}
                            <span className="num-block-green">
                                {numeral(
                                    revenuePerSeminar / costPerSeminar
                                ).format('0,000%')}
                            </span>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>Seminar Cost Calculator+:</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-offset-1 col-md-10 text-right">
                        <p className="text-left">
                            On this page, we will break down total seminars
                            costs current as of January 2015. However, some of
                            this depends on your personal seminar preferences --
                            type of mailer, method of mailing, mail house, type
                            and location of restaurant, food served, etc. These
                            are round numbers, but they give you a close idea of
                            what a seminar costs -- and what you get in return!
                        </p>
                        <br />
                        <div className="col-md-8">
                            Cost per direct mail piece:{' '}
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarMailersCost).format('$0,000.00')}
                        </div>
                        <br />
                        <div className="col-md-8">Number of pieces:</div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarMailers).format('0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">Cost per mailing:</div>
                        <div className="col-md-4 num-block-green">
                            {numeral(
                                seminarMailers * seminarMailersCost
                            ).format('$0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Restaurant Cost per head:
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarRestaurantCostPer).format('$0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">Number of attendees:</div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarAttendees).format('0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">Total Restaurant Cost:</div>
                        <div className="col-md-4 num-block-green">
                            {numeral(
                                seminarRestaurantCostPer * seminarAttendees
                            ).format('$0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">Total cost per seminar:</div>
                        <div className="col-md-4 num-block-green">
                            {numeral(costPerSeminar).format('$0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Actual # of attendees (all nights):
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarAttendeesActual).format('0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Attendees that made appointments:
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarAttendeesAppt).format('0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">Conversion Rate:</div>
                        <div className="col-md-4 num-block-green">
                            {numeral(
                                seminarAttendeesAppt / seminarAttendeesActual
                            ).format('0,000.00%')}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Conservative closing Rate:
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarCloseRate).format('0,000.00%')}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Closed Sales (Based on closing rate):
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(
                                seminarAttendeesAppt * (seminarCloseRate / 100)
                            ).format('0')}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Income per seminar (Based on Average Income per
                            sale):
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(incomePerSeminar).format('$0,000')}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Average Target Premium per seminar (Based on Average
                            Target per sale):
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(avgTargetPremiumPerSeminar).format(
                                '$0,000'
                            )}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Cost per Seminar vs. Income per seminar
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(incomePerSeminar / costPerSeminar).format(
                                '0,000%'
                            )}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Seminars Per Year to Meet Income Goal
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(seminarsPerYear).format('0')}
                        </div>
                        <br />
                        <div className="col-md-8">Seminar Cost Per Year</div>
                        <div className="col-md-4 num-block-green">
                            {numeral(costPerSeminar * seminarsPerYear).format(
                                '$0,000'
                            )}
                        </div>
                        <br />
                        <div className="col-md-8">
                            Cost of Seminars vs. Income from Seminars
                        </div>
                        <div className="col-md-4 num-block-green">
                            {numeral(
                                (incomePerSeminar * seminarsPerYear) /
                                    (costPerSeminar * seminarsPerYear)
                            ).format('0,000%')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-offset-1 col-md-10">
                        &copy; 2016, PeakPro Financial - All Rights Reserved
                    </div>
                </div>
            </div>
        )
    }
}
