import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    requestDetailProposal,
    requestProposalLinkByEmail,
    requestProposalLinkEmails,
} from '../actions'
import FileSaver from 'file-saver'
import moment from 'moment'
import { get } from '../services/api'
import ProposalView from '../components/proposal/view'
import { useParams } from 'react-router-dom'

const ProposalViewPage = (props) => {
    const params = useParams()

    useEffect(() => {
        props.requestDetailProposal(params.proposalId)
        props.requestProposalLinkEmails()
    }, [])

    const requestProposalLinkByEmail = (email) => {
        props.requestProposalLinkByEmail(params.proposalId, email)
    }

    // const requestProposalLinkEmails = () => {
    //     props.requestProposalLinkEmails()
    // }

    const downloadPDF = (custName) => {
        get(`/api/v1/proposals/${params.proposalId}/pdf/`).then((response) => {
            var data = new Blob([response.data], {
                type: 'application/pdf',
            })
            FileSaver.saveAs(
                data,
                `proposal_${moment().format('YYYY-MM-DD')}_${custName}.pdf`
            )
        })
    }

    return (
        <ProposalView
            {...props}
            downloadPDF={downloadPDF}
            proposalEmails={props.emails}
            requestProposalLinkByEmail={requestProposalLinkByEmail}
        />
    )
}

const mapStateToProps = (state) => {
    return { ...state.proposal, emails: state.proposals.emails, ...state.auth }
}

export default connect(mapStateToProps, {
    requestDetailProposal,
    requestProposalLinkByEmail,
    requestProposalLinkEmails,
})(ProposalViewPage)
