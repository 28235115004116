import { Tabs } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ProposalLinks from './links'
import ProposalLinkViews from './link_views'

const { TabPane } = Tabs

const ReportsPage = (props) => {
    const history = useHistory()

    return (
        <Tabs
            defaultActiveKey={props.active}
            onChange={(key) => history.push(key)}
        >
            <TabPane tab="Link Views" key="/reports/link_views">
                <ProposalLinkViews />
            </TabPane>
            <TabPane tab="Proposal Links" key="/reports/links">
                <ProposalLinks />
            </TabPane>
        </Tabs>
    )
}

export default ReportsPage
