import React from 'react'
import { Link } from 'react-router-dom' //useHistory
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListProposals from '../shared_components/list_proposals'
import ListAriaV2Proposals from '../shared_components/list_aria_v2_proposals'
import ListIllustrations from '../shared_components/list_illustrations'
import { Card, Row, Col, Button } from 'antd'
import { STAFF_USER } from '../../constants'
// import { useFlagsmith } from 'flagsmith-react'
// import BucketList from '../bucket/list'
// import { useMutation, useQuery, useQueryClient } from 'react-query'
// import { bucketService } from '../../services/bucket.service'
// import { get } from '../../services/api'
import { useAuth } from '../use_auth'

export default function ClientView(props) {
    // const history = useHistory()
    const auth = useAuth()
    // const queryClient = useQueryClient()
    const {
        client,
        clientId,
        ariav2s,
        proposals,
        illustrations,
        ariav2sPageChangeHandler,
        proposalsPageChangeHandler,
        illustrationsPageChangeHandler,
    } = props

    // const [bucketPage, setBucketPage] = React.useState(0)
    // const [bucketPerPage, setBucketPerPage] = React.useState(25)
    // const [bucketSorted, setBucketSorted] = React.useState('')

    // const { hasFeature } = useFlagsmith()

    /* Incomplete - turning off for now to resolve bug
    const buckets = useQuery(
        ['buckets', clientId, bucketPage, bucketPerPage],
        () =>
            get('/api/v1/buckets/', {
                params: {
                    page: bucketPage,
                    per_page: bucketPerPage,
                    sorted: bucketSorted,
                    filters: JSON.stringify({
                        customer_id: clientId,
                    }),
                },
            }).then((response) => response.data),
        { keepPreviousData: true }
    )*/

    // const newBucket = useMutation(() => bucketService.createBucket(clientId), {
    //     onSuccess: (data) => {
    //         queryClient.setQueryData(['buckets', data.id], data)
    //     },
    // })

    /* if (!buckets || !buckets.data) {
        return null
    }

    const handleBucketsTableChange = (pagination, filters, sorter) => {
        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        setBucketPage(pagination.current - 1)
        setBucketPerPage(pagination.pageSize)
        setBucketSorted(sorted)
    } */

    if (client.loading) {
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <Link to={'/clients'} className="pull-left">
                        <FontAwesomeIcon icon="chevron-left" /> Clients
                    </Link>
                </div>
                <div className="panel-body">Loading...</div>
            </div>
        )
    } else {
        return (
            <Card
                title={
                    <Row>
                        <Col span={8}>
                            <Link to="/clients" className="pull-left">
                                <Button icon="arrow-left">Clients</Button>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <div style={{ textAlign: 'center' }}>
                                {client.fullName}
                            </div>
                        </Col>
                        <Col span={8}>
                            <Link
                                to={`/clients/edit/${clientId}`}
                                className="pull-right"
                            >
                                <Button
                                    type="primary"
                                    icon="edit"
                                    style={{ margin: 5 }}
                                >
                                    Edit
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                }
            >
                <div className="panel-body">
                    <table className="table table-condensed">
                        <tbody>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>State</th>
                                <th>Age</th>
                            </tr>
                            <tr>
                                <td>{client.firstName}</td>
                                <td>{client.lastName}</td>
                                <td>{client.email}</td>
                                <td align="center">{client.state}</td>
                                <td align="center">{client.currentAge}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Card
                    title="ARIA Proposals"
                    type="inner"
                    extra={
                        <Link
                            to={{
                                pathname: '/aria-v2/add',
                                state: { client: client },
                            }}
                            className="pull-right"
                        >
                            <Button
                                type="primary"
                                icon="plus"
                                style={{ margin: 5 }}
                            >
                                New ARIA...
                            </Button>
                        </Link>
                    }
                >
                    <ListAriaV2Proposals
                        data={ariav2s.data}
                        loading={ariav2s.loading}
                        count={ariav2s.count}
                        minRows={0}
                        page={ariav2s.page}
                        sorted={ariav2s.sorted}
                        pageChangeHandler={ariav2sPageChangeHandler}
                    />
                </Card>
                {/* {hasFeature('buckets') && (
                    <Card
                        title="Buckets"
                        type="inner"
                        extra={
                            <Button
                                type="primary"
                                icon="plus"
                                className="pull-right"
                                style={{ margin: 5 }}
                                onClick={() => {
                                    newBucket.mutate(null, {
                                        onSuccess: (data) => {
                                            history.push(`/buckets/${data.id}`)
                                        },
                                    })
                                }}
                            >
                                New Buckets...
                            </Button>
                        }
                    >
                        <BucketList
                            data={buckets.data.data}
                            loading={buckets.isLoading || !buckets.data}
                            count={buckets.data.count}
                            minRows={0}
                            page={buckets.data.page}
                            sorted={buckets.data.sorted}
                            pageChangeHandler={handleBucketsTableChange}
                        />
                    </Card>
                )} */}
                {auth.roles.includes(STAFF_USER) && (
                    <Card title="Proposals" type="inner">
                        <ListProposals
                            data={proposals.data}
                            loading={proposals.loading}
                            count={proposals.count}
                            minRows={0}
                            page={proposals.page}
                            sorted={proposals.sorted}
                            pageChangeHandler={proposalsPageChangeHandler}
                        />
                    </Card>
                )}
                {auth.roles.includes(STAFF_USER) && (
                    <Card
                        title="Illustrations"
                        type="inner"
                        extra={
                            <Link
                                to={`/illustrations/add/${clientId}`}
                                className="pull-right"
                            >
                                <Button
                                    type="primary"
                                    icon="plus"
                                    style={{ margin: 5 }}
                                >
                                    New Illustration...
                                </Button>
                            </Link>
                        }
                    >
                        <ListIllustrations
                            data={illustrations.data}
                            loading={illustrations.loading}
                            count={illustrations.count}
                            minRows={0}
                            page={illustrations.page}
                            sorted={illustrations.sorted}
                            pageChangeHandler={illustrationsPageChangeHandler}
                        />
                    </Card>
                )}
            </Card>
        )
    }
}
