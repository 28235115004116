import { number } from 'prop-types'

enum Payee {
    Client = 'client',
    Spouse = 'spouse',
    Other = 'other',
}

export enum BucketNeedEnum {
    Income = 'income',
    Value = 'value',
    Custom = 'custom',
}

interface Paged<T> {
    page: number
    perPage: number
    count: number
    data: T[]
}

interface BucketDetailType {
    id: number
    bucketProposal: number
    order: number
    duration: number
    startYear: number
    startingValue: number
    withdrawRate: number
    rate: number
    deferral: number
    bonus: number
    inflationAdjusted: number
    monthlyIncome: number
}

interface BucketTypeNew {
    customer: Client
    assumedInflation: number
    returnedFunds: number
    returnedInvest: number
    returnedDeferral: number
    baseRateOfReturn: number
    socialSecurityIncome: number
    socialSecurityIncomeDeduct: boolean
    pensionIncome: number
    pensionIncomeDeduct: boolean
    employmentIncome: number
    employmentIncomeDeduct: boolean
    qualifiedPlanIncome: number
    qualifiedPlanIncomeDeduct: boolean
    savingsInvestmentsIncome: number
    savingsInvestmentsIncomeDeduct: boolean
    definedContributionPlanIncome: number
    definedContributionPlanIncomeDeduct: boolean
    needType: BucketNeedEnum
    needAssetValue: number
    needMonthlyIncome: number
    buckets: BucketDetailType[]
}

interface BucketType extends BucketTypeNew {
    id: number
    proposalDate: Date
    createdOn: Date
    modifiedOn: Date
    isActive: boolean
}

interface BucketTypePaged extends Paged<BucketType> {}

interface IncomeCompAddModel {
    loading: boolean
    data: IncomeCompCreate
}

interface IncomeCompModel {
    loading: boolean
    data: IncomeCompModelData
}

interface IncomeCompCreate {
    amountToReallocate: number
    annualTaxFreeIncome: number
    customer: CustomerModel | undefined
    customerId: number
    customerAge: number
    customerFilingStatus: number
    distributionOption: string
    estateTaxExemption: number
    estateTaxExemptionAmount: number
    estateTaxExemptionRollbackAmount: number
    estateTaxRate: number
    growthRate: number
    growthRateType: 'fixed' | 'historical'
    incomeStartYear: number
    inheritedIraGrowthRate: number
    inheritedIraTaxRate: number
    nonQualifiedAssets: number
    propertyValue: number
    propertyValueGrowthRate: number
    qualifiedPlanBalance: number
    showReinvestedTaxFreeLoan: boolean
    spreadReallocYears: number
    startReallocYears: number
    stretchIraAgeAtDeath: number
    stretchIraNonSpouseBeneficiaryAge: number
    stretchIraSpouseAge: number
    stretchIraSpouseAgeAtDeath: number
    taxBracket: number
    taxCalcFutureYear: number
    taxCalcStartYear: number
    taxFreeDbAge100: number
}

interface IncomeCompModelData extends IncomeCompCreate {
    createdOn: Date
    customerFilingStatusText: string
    distributionOptionText: string
    estateTaxExemptionText: string
    id: number
    modifiedOn: Date
    taxCalcData: TaxCalcData
    stretchResult: StretchResult
    rmdResult: RmdResult
    estateCalcData: {
        current: ICEstateTaxData[]
        after: ICEstateTaxData[]
    }
    inheritedCalcData: {
        [key: string]: ICInheritedTaxData
    }
}

interface IncomeComps extends Paged<IncomeCompModelData> {}

interface ICInheritedTaxData {
    calcs: ICInheritedTaxTableData[]
    totals: {
        distributionAmountTotal: number
        distributionTaxAmountTotal: number
    }
}

interface ICInheritedTaxTableData {
    year: number
    currentPlanBalance: number
    distributionAmount: number
    distributionTaxAmount: number
}

interface ICEstateTaxData {
    taxYear: number
    eoyAge: number
    growthRate: number
    planBalance: number
    nonQualifiedAssetsBalance: number
    reinvestedNetRmdAmount: number
    reinvestedTaxFreeLoanAmount: number
    estimatedPropertyValueBalance: number
    estimatedEstateValue: number
    taxableEstateAmount: number
    estateTax: number
    rollbackTaxableEstateAmount: number
    rollbackEstateTax: number
}

interface RmdResult {
    current: ComparisonClass
    baseCalculations: BaseCalculation[]
    after: ComparisonClass
    taxFree: ComparisonClass
}

interface CustomerFormValues {
    name: string
    filingStatus: number
    age: number
    qualifiedPlanBalance: number
}

interface CustomerModel extends CustomerFormValues {
    filingStatusText: string
    createdOn: Date
    id: number
}

interface ComparisonClass {
    totalProjectedDistributions: number
    totalTaxPaid: number
    totalInheritanceTaxPaid: number
    totalProjectedInheritanceGross: number
}

interface BaseCalculation {
    current: CalculatedClass
    eoyAge: number
    rmdFactor: number
    after: CalculatedClass
    year: number
}

interface CalculatedClass {
    growth: number
    planBalance: number
    reallocation?: number
    rmdAmount: number
}

interface RmdResulCurrent {
    totalProjectedDistributions: number
    totalProjectedInheritanceGross: number
}

interface StretchResult {
    stretch: StretchResultTotals
    baseCalculations: StretchBaseCalculations[]
    secure: StretchResultTotals
}

interface StretchBaseCalculations {
    secure: StretchBaseCalculation
    stretch: StretchBaseCalculation
    payee: Payee
    year: number
    otherEoyAge: number
    spouseEoyAge: number
    clientEoyAge: number
}

interface StretchBaseCalculation {
    planBalance: number
    distributionAmount: number
    taxAmount: number
}

interface StretchResultTotals {
    totalDistributions: number
    totalTaxes: number
    otherStartYear?: number
}

interface TaxCalcDataSub {
    year: number
    wages1: number
    taxableInterest2B: number
    ordinaryDividends3B: number
    iraDistrib4B: number
    pensions4D: number
    socialSecurity5B: number
    capitalGainLoss6: number
    otherIncome7A: number
    irs7702Distribs: number
    adjustments8A: number
    standardDeduc9: number
    qualBizIncome10: number
    stateTaxRate: number
    [key: string]: number
}

interface TaxCalcData {
    doNothing: TaxCalcDataSub
    reallocate: TaxCalcDataSub
    [key: string]: TaxCalcDataSub
}

interface CalculatedTaxCalcDataSub {
    totalIncome7B: number
    adjustedIncome8B: number
    addLines11A: number
    taxableIncome11B: number
    federalTax12A: number
    stateTax: number
    netIncome: number
    effectiveTaxRate: number
    cashFlowTaxRate: number
    preTaxEquivalentTotalIncome: number
    afterTaxIncome: number
    [key: string]: number
}

interface CalculatedTaxCalcData {
    [key: string]: CalculatedTaxCalcDataSub
    doNothing: CalculatedTaxCalcDataSub
    reallocate: CalculatedTaxCalcDataSub
}

interface StatesPayload {
    id: string
    name: string
}

interface IndexPayload {
    id: number
    name: string
    desc: string
    market_rate_index: string
}

interface PolicyData {
    policyYear: number
    beginningOfYearAge: number
    premium_outlay: number
    net_outlay: number
    guaranteed_surrender_value: number
    guaranteed_death_benefit: number
    nonGuaranteed_accumulation_value: number
    nonGuaranteed_surrender_value: number
    nonGuaranteed_death_benefit: number
    premium_charge: number
    coi_charge: number
    policy_issue_charge: number
    additional_charges: number
    bonus_interest_credit: number
    additonal_policy_credits: number
    other_transactions: number
    interest_earned: number
    annual_loan_amount: number
    loan_interest: number
    total_loan_balance: number
    partial_surrender: number
}

interface PolicyDataCamel {
    policyYear: number
    beginningOfYearAge: number
    premiumOutlay: number
    netOutlay: number
    guaranteedSurrenderValue: number
    guaranteedDeathBenefit: number
    nonGuaranteedAccumulationValue: number
    nonGuaranteedSurrenderValue: number
    nonGuaranteedDeathBenefit: number
    premiumCharge: number
    coiCharge: number
    policyIssueCharge: number
    additionalCharges: number
    bonusInterestCredit: number
    additonalPolicyCredits: number
    additionalPolicyCredits: number
    otherTransactions: number
    interestEarned: number
    annualLoanAmount: number
    loanInterest: number
    totalLoanBalance: number
    partialSurrender: number
}

interface PolicyDataSummary {
    combinedTaxRate: number
    totalTaxWithAtap: number
    totalPremium: number
    projectedTaxAdvantagedDist: number
    nonGuaranteedDeathBenefit: number
    totalBenefitsTaxAdvantaged: number
    totalPreTaxEquivalentDistributions: number
    totalPreTaxEquivalentDeathBenefit: number
    combinedPreTaxEquivalentBenefits: number
}

interface AriaV2Payload {
    agentName: string
    clientName: string
    clientAge: number
    clientGender: string
    indexCreditingRate: number
    initialFaceamount: number
    qualified: boolean
    qualifiedFundsAmount: number
    incomeStartAge: number
    federalTaxRate: number
    stateTaxRate: number
    policyData: PolicyData
}

interface ArbitragePayload {
    arbitrage_year: number
    moodys_rate: number
    sp_return: number
}

interface AuthSuccessPayload {
    token: string
    refresh_token: string
    username: string
    roles: string[]
}

interface Auth {
    loggedIn: boolean
    username: string
    roles: string[]
    token: string
    refreshToken: string
}

interface Payloads {
    data:
        | AriaV2Payload[]
        | StatesPayload[]
        | IndexPayload[]
        | ArbitragePayload[]
}

interface PageParams {
    page: number
    per_page: number
    sorted: string
    view_date_range?: string
    send_date_range?: string
}

interface TaxCalcStatus {
    isSaving: boolean
    isInError: boolean
}

interface Client {
    address: string
    address2: string
    agent: number
    city: string
    createdOn: string
    currentAge: number
    email: string
    fax: string
    filingStatus: number
    filingStatusText: string
    firstName: string
    fullName: string
    gender: number
    genderText: string
    id: number
    lastName: string
    loading: boolean
    phone: string
    postal: string
    selectedAgent: { value: number; label: string }
    spouseAge: number | null
    spouseFirstName: string
    spouseFullName: string
    spouseLastName: string
    state: string
}

export interface Agency {
    id: number
    company: string
    address?: any
    address2?: any
    city?: any
    state: string
    phone?: any
    fax?: any
    email?: any
    postal?: any
}

export interface Agent {
    id: number
    firstName: string
    lastName: string
    fullName: string
    phone?: any
    fax?: any
    email: string
    agency: Agency
}
