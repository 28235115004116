import React from 'react'
import { Client } from '../../index.d'

interface ClientInfoProps {
    customer: Client
}

const ClientInfo = (props: ClientInfoProps) => {
    const { customer } = props

    return (
        <>
            <div className="col-lg-6 col-md-6">
                {customer.fullName && (
                    <span>
                        {customer.fullName}
                        <br />
                    </span>
                )}
                {customer.address && (
                    <span>
                        {customer.address}
                        <br />
                    </span>
                )}
                {customer.address2 && (
                    <span>
                        {customer.address2}
                        <br />
                    </span>
                )}
                {customer.phone && (
                    <span>
                        {customer.phone}
                        <br />
                    </span>
                )}
                {customer.email && (
                    <span>
                        {customer.email}
                        <br />
                    </span>
                )}
            </div>
            <div className="col-lg-6 col-md-6">
                {customer.filingStatusText && (
                    <span>
                        Filing Status: {customer.filingStatusText}
                        <br />
                    </span>
                )}
                {customer.genderText && (
                    <span>
                        Gender: {customer.genderText}
                        <br />
                    </span>
                )}
                {customer.currentAge && (
                    <span>
                        Age: {customer.currentAge}
                        <br />
                    </span>
                )}
                {customer.spouseFullName && (
                    <span>
                        Spouse: {customer.spouseFullName}
                        <br />
                    </span>
                )}
                {customer.spouseAge && (
                    <span>
                        Spouse Age: {customer.spouseAge}
                        <br />
                    </span>
                )}
            </div>
        </>
    )
}

ClientInfo.displayName = 'ClientInfo'

export default ClientInfo
