import React from "react";
import IllustrationForm from "./form";

import "./illustration.css";
import { Link } from "react-router-dom";

import { Card, Row, Col, Typography, Button } from "antd";
const { Title } = Typography;

const IllustrationEdit = props => {
  return (
    <Card
    title={
      <Row>
        <Col span={8}>
          <Link to="/illustrations">
            <Button type="danger dashed" icon="arrow-left">
              Back
            </Button>
          </Link>
        </Col>
        <Col span={8}>
          <Title level={4} style={{ textAlign: "center" }}>Illustrations - Edit</Title>
        </Col>
        <Col span={8}>
        </Col>
      </Row>
    }>
      <IllustrationForm {...props} />
    </Card>
  );
};

export default IllustrationEdit;
