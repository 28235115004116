import React from 'react'
import numeral from 'numeral'
import { Card } from 'antd'

const VsIndexedAnnuity = (props) => {
    const { annuities, illustration } = props
    return (
        <div className="center">
            <Card
                className="container1000"
                title={`Detailed Comparison: Indexed Annuity & Tax Free Plan`}
                bordered={false}
                headStyle={{
                    color: '#003e80',
                    fontWeight: 'bold',
                    fontSize: 24,
                    textAlign: 'center',
                    borderBottomColor: '#003e80',
                }}
            >
                <div className="center">
                    <table className="table table-800 table-hover table-noborder table-condensed">
                        <thead>
                            <tr>
                                <th colSpan="2">&nbsp;</th>
                                <th colSpan="5">Annuity - Income</th>
                                <th colSpan="3">Annuity - Accumulation</th>
                                <th colSpan="5">Alternative Tax Free</th>
                            </tr>
                            <tr>
                                <th className="table-th-lt-gray table-th-bordertopleft">
                                    Age
                                </th>
                                <th className="table-th-lt-gray table-th-bordertop">
                                    Year
                                </th>
                                <th className="table-th-lt-gray table-th-bordertop">
                                    Roll
                                    <br />
                                    Up{' '}
                                    {numeral(annuities.rollUpIr / 100).format(
                                        '0,0.00%'
                                    )}
                                </th>
                                <th className="table-th-lt-gray table-th-bordertop">
                                    Income
                                    <br />
                                    Factor{' '}
                                    {numeral(
                                        annuities.incomeFactor / 100
                                    ).format('0,0.00%')}
                                </th>
                                <th className="table-th-lt-gray table-th-bordertop">
                                    Taxes @
                                    <br />
                                    {numeral(illustration.taxRate / 100).format(
                                        '0,0.00%'
                                    )}
                                </th>
                                <th className="table-th-lt-gray table-th-bordertop">
                                    Net
                                    <br />
                                    After Tax
                                </th>
                                <th className="table-th-lt-gray table-th-bordertop">
                                    Income
                                    <br />
                                    Account Value
                                </th>
                                <th className="table-th-space table-th-bordertop">
                                    &nbsp;
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    ROI
                                    <br />
                                    {numeral(annuities.roiIr / 100).format(
                                        '0,0.00%'
                                    )}
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Family
                                    <br />
                                    Benefit
                                </th>
                                <th className="table-th-line table-th-bordertopright"></th>
                                <th className="table-th-blue table-th-bordertop">
                                    Tax Free
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Accumulation Value
                                </th>
                                <th className="table-th-blue table-th-bordertop">
                                    Cash Value*
                                </th>
                                <th className="table-th-blue table-th-bordertopright">
                                    Death
                                    <br />
                                    Benefit
                                </th>
                                <th className="table-th-blue table-th-bordertopright">
                                    Cumulative Benefit
                                    <br />
                                    Differential
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
            <td colSpan="6" className="currency">
              Beginning Value ({moment(illustration.proposalDate).format("LL")})
            </td>
            <td className="currency">{annuities.accountVal}</td>
            <th colSpan="9" className="table-th-space">
              &nbsp;
            </th>
          </tr> */}
                            {annuities.rows.map((dataRow, index) => {
                                return (
                                    <tr key={index}>
                                        <td
                                            className="currency table-td-borderleft"
                                            style={{ width: 40 }}
                                        >
                                            {dataRow.age}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 40 }}
                                        >
                                            {dataRow.year}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.rollUpInterest}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.transaction}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.taxes}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.netAfterTax}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.accountValue}
                                        </td>
                                        <td className="currency"></td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.roiInterest}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.familyBenefit}
                                        </td>
                                        <td className="currency table-td-borderright"></td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.withdraw}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.accumulation}
                                        </td>
                                        <td
                                            className="currency"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.surrender}
                                        </td>
                                        <td
                                            className="currency table-td-borderright"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.deathBenefit}
                                        </td>
                                        <td
                                            className="currency table-td-borderright table-td-sum"
                                            style={{ width: 90 }}
                                        >
                                            {dataRow.beniDiff}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="8" className="currency">
                                    Total Distribution:
                                </td>
                                <td
                                    colSpan="3"
                                    className="table-th-dk-gray currency bold"
                                >
                                    ${' '}
                                    {numeral(annuities.totalFiaDist).format(
                                        '0,0'
                                    )}
                                </td>
                                <td colSpan="3" className="currency">
                                    Total Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    ${' '}
                                    {numeral(annuities.tfpTotalDist).format(
                                        '0,0'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="8" className="currency">
                                    Total Taxes:
                                </td>
                                <td
                                    colSpan="3"
                                    className="table-th-dk-gray currency bold"
                                >
                                    ${' '}
                                    {numeral(annuities.totalFiaTaxes).format(
                                        '0,0'
                                    )}
                                </td>
                                <td colSpan="3" className="currency">
                                    Total Taxes:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    $ 0
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="8" className="currency">
                                    Net Distribution:
                                </td>
                                <td
                                    colSpan="3"
                                    className="table-th-dk-gray currency bold"
                                >
                                    ${' '}
                                    {numeral(annuities.totalFiaPmts).format(
                                        '0,0'
                                    )}
                                </td>
                                <td colSpan="3" className="currency">
                                    Net Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    ${' '}
                                    {numeral(annuities.tfpTotalDist).format(
                                        '0,0'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="8" className="currency">
                                    Family Benefit:
                                </td>
                                <td
                                    colSpan="3"
                                    className="table-th-dk-gray currency bold"
                                >
                                    $ 0
                                </td>
                                <td colSpan="3" className="currency">
                                    Family Benefit:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    ${' '}
                                    {numeral(annuities.tfpFamBeni).format(
                                        '0,0'
                                    )}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </Card>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="disclaimer-800">
                    <p>
                        Annuity values, including Family Benefit, are calculated
                        based on a hypothetical annuity policy. The family
                        survivor benefit may or may not be available depending
                        on specific product provisions and/or riders.
                    </p>
                    <p>
                        Annuities are long-term products particularly suitable
                        for retirement assets. Annuities held within qualified
                        plans do not provide any additional tax benefit. Early
                        withdrawals may be subject to surrender charges.
                        Withdrawals are subject to ordinary income tax, and if
                        taken prior to age 59½, a 10% IRS penalty may also
                        apply.
                    </p>
                    <p>
                        *Cash Value does not reflect carrier surrender charges.
                        It does reflect the net amount available after total
                        loans are deducted from the Accumulation Value. Numbers
                        can vary significantly from the carrier illustration
                        that includes surrender charges.
                    </p>
                    <p>
                        Index returns assume{' '}
                        {illustration.marketIndexDesc.trim()}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VsIndexedAnnuity
