/*
 * Created by Sina Khelil on 2/1/16.
 */

import React from "react";
import ListClients from "../shared_components/list_clients";
import ErrorBoundary from "../shared_components/error_boundary";
import "react-table/react-table.css";
import { Card } from "antd";

export default class ClientList extends React.Component {
  render() {
    const { data, loading, perPage, count, page, sorted, error, pagination, pageChangeHandler } = this.props;
    if (error) {
      return (
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">Clients</h3>
          </div>
          <div className="panel-body">
            <div>Something broke | {error.message}</div>
          </div>
        </div>
      );
    } else {
      return (
        <Card
          bodyStyle={{
            padding: 0,
            margin: 0
          }}
          bordered={false}
          title={""}
        >
          <ErrorBoundary>
            <ListClients
              data={data}
              page={page}
              loading={loading}
              pagination={pagination}
              perPage={perPage}
              sorted={sorted}
              count={count}
              pageChangeHandler={pageChangeHandler}
            />
          </ErrorBoundary>
        </Card>
      );
    }
  }
}
