import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { agentService } from '../services/agent.service'

const {
    AGENT_GET_REQUEST,
    successAgent,
    failedAgent,
    AGENT_CLIENTS_GET_REQUEST,
    successAgentClients,
    failedAgentClients,
    AGENT_PROPOSALS_GET_REQUEST,
    successAgentProposals,
    failedAgentProposals,
    AGENT_UPDATE_REQUEST,
    successUpdateAgent,
    failedUpdateAgent,
    AGENT_CREATE_REQUEST,
    successCreateAgent,
    failedCreateAgent,
    processErrorMessage,
    successAlert,
    AGENTS_GET_REQUEST,
    successAgents,
    failedAgents,
} = actions

export function* getAgents() {
    while (true) {
        yield take(AGENTS_GET_REQUEST)
        try {
            const response = yield call(agentService.getAgents)
            yield put(successAgents(response))
        } catch (error) {
            yield put(failedAgents(error))
        }
    }
}

export function* getAgent() {
    while (true) {
        const { agencyId } = yield take(AGENT_GET_REQUEST)
        try {
            const response = yield call(agentService.getAgent, agencyId)
            yield put(successAgent(response))
        } catch (error) {
            yield put(failedAgent(error))
        }
    }
}

export function* getAgentClients() {
    while (true) {
        const { agentId, page, perPage, sorted } = yield take(
            AGENT_CLIENTS_GET_REQUEST
        )
        try {
            const response = yield call(
                agentService.getAgentClients,
                agentId,
                page,
                perPage,
                sorted
            )
            yield put(successAgentClients(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAgentClients(error))
        }
    }
}

export function* getAgentProposals() {
    while (true) {
        const { agentId, page, perPage, sorted } = yield take(
            AGENT_PROPOSALS_GET_REQUEST
        )
        try {
            const response = yield call(
                agentService.getAgentProposals,
                agentId,
                page,
                perPage,
                sorted
            )
            yield put(successAgentProposals(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAgentProposals(error))
        }
    }
}

export function* updateAgent() {
    while (true) {
        const { agentId, payload } = yield take(AGENT_UPDATE_REQUEST)
        try {
            const response = yield call(agentService.updateAgent, {
                agentId,
                payload,
            })
            yield put(successUpdateAgent(response))
            payload.history.push(`/agencies/${response.data.data.agency.id}`)
            yield put(successAlert('Agent updated'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdateAgent(error))
        }
    }
}

export function* createAgent() {
    while (true) {
        const { payload } = yield take(AGENT_CREATE_REQUEST)
        try {
            const response = yield call(agentService.createAgent, payload)
            yield put(successCreateAgent(response))
            payload.history.push(`/agencies/${response.data.id}`)
            yield put(successAlert('Agent created'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateAgent(error))
        }
    }
}
