import React from 'react'
import { Link, useParams } from 'react-router-dom'
import ProposalForm from './form.js'

const ProposalAdd = (props) => {
    const { illustrationId } = useParams()

    if (illustrationId) {
        return <ProposalForm {...props} />
    }

    return (
        <div className="h4">
            To create a proposal, first select an{' '}
            <Link to="/illustrations">illustration</Link>.
        </div>
    )
}

export default ProposalAdd
