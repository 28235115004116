import React from 'react'
import { BucketNeedEnum, BucketType } from '../../../../index.d'
import NumberInput from '../../../../components/shared_components/numberinput'
import { UseMutationResult } from 'react-query'
import { AxiosError } from 'axios'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { debounce } from 'lodash'

interface BucketNeedsProps {
    bucket: BucketType
    updateBucket: UseMutationResult<
        BucketType | undefined,
        AxiosError,
        BucketType
    >
}

const BucketNeeds = (props: BucketNeedsProps) => {
    const { bucket, updateBucket } = props

    const handleChange = (fieldName: string, newValue: number | string) => {
        debounce(
            () => updateBucket.mutate({ ...bucket, [fieldName]: newValue }),
            500
        )()
    }

    const needsSchema = Yup.object().shape({
        needAssetValue: Yup.number()
            .min(0, 'Maximum ${max}')
            .required('Required'),
        needMonthlyIncome: Yup.number()
            .min(0, 'Maximum ${max}')
            .required('Required'),
    })

    return (
        <Formik
            initialValues={bucket}
            validationSchema={needsSchema}
            onSubmit={(values) => {
                updateBucket.mutate({ ...bucket, ...values })
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <Form>
                    <div className="col-md-6 needs-label">
                        <input
                            type="radio"
                            id="needType"
                            name="needType"
                            value={BucketNeedEnum.Value}
                            checked={bucket.needType === BucketNeedEnum.Value}
                            onChange={() =>
                                handleChange('needType', BucketNeedEnum.Value)
                            }
                        />{' '}
                        <label>Present Value of Assets</label>
                    </div>
                    <div className="col-md-6 needs-label">
                        <NumberInput
                            className="form-control input-sm"
                            error={errors.needAssetValue}
                            format="$0,0"
                            id="needAssetValue"
                            name="needAssetValue"
                            value={bucket.needAssetValue}
                            disabled={bucket.needType !== BucketNeedEnum.Value}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setFieldValue(
                                    'needAssetValue',
                                    parseInt(event.target.value)
                                )
                            }}
                            onBlur={() => {
                                handleSubmit()
                            }}
                        />
                    </div>
                    <div className="col-md-6 needs-label">
                        <input
                            type="radio"
                            id="needType"
                            name="needType"
                            value={BucketNeedEnum.Income}
                            checked={bucket.needType === BucketNeedEnum.Income}
                            onChange={() =>
                                handleChange('needType', BucketNeedEnum.Income)
                            }
                        />{' '}
                        <label>Desired Monthly Income</label>
                    </div>
                    <div className="col-md-6 needs-label">
                        <NumberInput
                            className="form-control input-sm"
                            error={errors.needMonthlyIncome}
                            format="$0,0"
                            id="needMonthlyIncome"
                            name="needMonthlyIncome"
                            value={bucket.needMonthlyIncome}
                            disabled={bucket.needType !== BucketNeedEnum.Income}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setFieldValue(
                                    'needMonthlyIncome',
                                    parseInt(event.target.value)
                                )
                            }}
                            onBlur={() => {
                                handleSubmit()
                            }}
                        />
                    </div>
                    <div className="col-md-12 needs-label">
                        <input
                            type="radio"
                            id="needType"
                            name="needType"
                            value={BucketNeedEnum.Custom}
                            checked={bucket.needType === BucketNeedEnum.Custom}
                            onChange={() =>
                                handleChange('needType', BucketNeedEnum.Custom)
                            }
                        />{' '}
                        <label>
                            Custom (clicking the others will reset your bucket
                            values)
                        </label>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

BucketNeeds.displayName = 'BucketNeeds'

export default BucketNeeds
