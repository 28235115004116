import { Card, Col, Row } from 'antd'
import numeral from 'numeral'
import React from 'react'
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

class CustomizedAxisTick extends React.PureComponent {
    render() {
        const { x, y, index, payload } = this.props

        if (index % 2 === 1)
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={20} y={0} dy={16} textAnchor="end" fill="#666">
                        {payload.value}
                    </text>
                </g>
            )
        return <g />
    }
}

class CustomizedLabel extends React.PureComponent {
    render() {
        const { x, y, value, index, data } = this.props

        let space = 0

        const vals = data[index]
        if (value === vals.market) {
            vals.market < vals.index ? (space = 20) : (space = -20)
        } else {
            vals.index < vals.market ? (space = 20) : (space = -20)
        }

        if (index % 3 === 1)
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={30}
                        y={space}
                        fontSize={10}
                        textAnchor="end"
                        fill="#666"
                    >
                        {numeral(value).format('$0,0')}
                    </text>
                </g>
            )
        return <g />
    }
}

const HypotheticalTaxFree = (props) => {
    const { hpt, illustration } = props

    if (!hpt) return <div>Loading...</div>
    return (
        <div className="center">
            <Card
                className="container1000"
                title="Hypothetical Tax Free Illustration"
                bordered={false}
                headStyle={{
                    color: '#003e80',
                    fontWeight: 'bold',
                    fontSize: 24,
                    textAlign: 'center',
                    borderBottomColor: '#003e80',
                }}
            >
                <Row
                    gutter={[16, 16]}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <div className="col-md-12 text-center">
                        <LineChart
                            width={800}
                            height={400}
                            data={hpt.chartApi}
                            style={{ display: 'inline-block' }}
                            margin={{ top: 5, right: 40, left: 40, bottom: 15 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="name"
                                interval={0}
                                height={40}
                                tick={<CustomizedAxisTick />}
                            />
                            <YAxis
                                tickFormatter={(value) =>
                                    numeral(value).format('$0,0')
                                }
                            />
                            <Tooltip
                                formatter={(value, name, props) =>
                                    numeral(value).format('$0,0')
                                }
                            />
                            <Legend height={40} margin={{ top: 20 }} />
                            <Line
                                type="linear"
                                strokeWidth={2}
                                label={<CustomizedLabel data={hpt.chartApi} />}
                                dot={false}
                                name="Market"
                                dataKey="market"
                                stroke="#FF0000"
                                legendType="square"
                            />
                            <Line
                                type="linear"
                                strokeWidth={2}
                                dot={false}
                                name="Index"
                                dataKey="index"
                                stroke="#000280"
                                legendType="square"
                                label={<CustomizedLabel data={hpt.chartApi} />}
                            />
                        </LineChart>
                    </div>
                </Row>

                <Row
                    gutter={[16, 16]}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Col span={8} className="col-fill-blue col-full-border">
                        <h4 className="h4-blue" style={{ fontWeight: 'bold' }}>
                            Index
                        </h4>
                        <table className="table table-condensed">
                            <tbody style={{ fontWeight: 'bold' }}>
                                <tr>
                                    <td className="table-td-noborder">
                                        Beginning Value
                                    </td>
                                    <td className="currency table-td-noborder">
                                        ${numeral(hpt.beginValue).format('0,0')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        Final Value
                                    </td>
                                    <td className="currency table-td-noborder">
                                        {numeral(hpt.indexReturn).format('0,0')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        &nbsp;
                                    </td>
                                    <td className="table-td-noborder">
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        Largest Index Return
                                    </td>
                                    <td className="currency table-td-noborder">
                                        {numeral(hpt.highestIndex).format(
                                            '0.00'
                                        )}
                                        %
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        Lowest Index Return
                                    </td>
                                    <td className="currency table-td-noborder">
                                        {numeral(hpt.lowestIndex).format(
                                            '0.00'
                                        )}
                                        %
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={8} className="col-fill-red col-full-border">
                        <h4 className="h4-red" style={{ fontWeight: 'bold' }}>
                            Market
                        </h4>
                        <table className="table table-condensed">
                            <tbody style={{ fontWeight: 'bold' }}>
                                <tr>
                                    <td className="table-td-noborder">
                                        Beginning Value
                                    </td>
                                    <td className="currency table-td-noborder">
                                        ${numeral(hpt.beginValue).format('0,0')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        Final Value
                                    </td>
                                    <td className="currency table-td-noborder">
                                        {numeral(hpt.marketReturn).format(
                                            '0,0'
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        &nbsp;
                                    </td>
                                    <td className="table-td-noborder">
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        Largest Index Return
                                    </td>
                                    <td className="currency table-td-noborder">
                                        {numeral(hpt.highestMarket).format(
                                            '0.00'
                                        )}
                                        %
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-td-noborder">
                                        Lowest Index Return
                                    </td>
                                    <td className="currency table-td-noborder">
                                        {numeral(hpt.lowestMarket).format(
                                            '0.00'
                                        )}
                                        %
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row
                    gutter={[32, 32]}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 30,
                        marginBottom: 10,
                    }}
                >
                    <Col span={6}>
                        <h4 style={{ fontWeight: 'bold' }}>
                            Index & Market Returns
                        </h4>
                    </Col>
                </Row>
                <Row
                    gutter={[32, 32]}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Col span={4}>
                        <table className="table table-noborder col-full-border table-condensed">
                            <thead>
                                <tr>
                                    <th className="table-th-borderbottom">
                                        Year
                                    </th>
                                    <th className="blue table-th-borderbottomright table-th-borderleft">
                                        Index
                                    </th>
                                    <th className="red table-th-borderbottomright">
                                        Market
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {hpt.returnsListA.map((values, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-centered table-td-borderright">
                                                {values[0]}
                                            </td>
                                            <td className="currency blue table-td-borderright">
                                                {numeral(values[1]).format(
                                                    '0,0.00%'
                                                )}
                                            </td>
                                            <td className="currency red">
                                                {numeral(values[2]).format(
                                                    '0,0.00%'
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={4}>
                        <table className="table table-noborder col-full-border table-condensed">
                            <thead>
                                <tr>
                                    <th className="table-th-borderbottom">
                                        Year
                                    </th>
                                    <th className="blue table-th-borderbottomright table-th-borderleft">
                                        Index
                                    </th>
                                    <th className="red table-th-borderbottomright">
                                        Market
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {hpt.returnsListB.map((values, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-centered table-td-borderright">
                                                {values[0]}
                                            </td>
                                            <td className="currency blue table-td-borderright">
                                                {numeral(values[1]).format(
                                                    '0,0.00%'
                                                )}
                                            </td>
                                            <td className="currency red">
                                                {numeral(values[2]).format(
                                                    '0,0.00%'
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="disclaimer-800">
                        <p>
                            Market returns assume {illustration.marketRateName}{' '}
                            Annual Returns Years 2001-2018 plus 1.5% management
                            fee, excluding dividends
                        </p>
                        <p>
                            Index returns assume{' '}
                            {illustration.marketIndexDesc.trim()}.
                        </p>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default HypotheticalTaxFree
