import React from 'react'
import Table, { PaginationConfig, SorterResult } from 'antd/lib/table'
import { BucketType } from '../../index.d'
import TableRowActions from '../shared_components/table-row-actions'
import moment from 'moment'
import { Link } from 'react-router-dom'

interface BucketListProps {
    data: BucketType[]
    page: number
    count: number
    loading: boolean
    handleTableChange: (
        pagination: PaginationConfig,
        filters: Partial<Record<keyof BucketType, string[]>>,
        sorter: SorterResult<BucketType>
    ) => void
}

const BucketList = (props: BucketListProps) => {
    const { data, page, count, loading, handleTableChange } = props

    const columns = [
        { title: 'Client', dataIndex: 'customer.fullName', key: 'last_name' },
        { title: 'Type', dataIndex: 'needType', key: 'need_type' },
        {
            title: 'Asset Value',
            dataIndex: 'needAssetValue',
            key: 'need_asset_value',
        },
        {
            title: 'Monthly Income',
            dataIndex: 'needMonthlyIncome',
            key: 'need_monthly_income',
        },
        {
            title: 'Modified',
            sortable: true,
            dataIndex: 'modifiedOn',
            render: (modifiedOn: string) =>
                moment.utc(modifiedOn).format('MM/DD/YYYY LT'),
            sorter: (a: BucketType, b: BucketType) =>
                a.modifiedOn.valueOf() - b.modifiedOn.valueOf(),
        },
        {
            title: 'Actions',
            sortable: false,
            key: 'original',
            render: (original: BucketType) => (
                <span>
                    <Link to={'/buckets/' + original.id}>View</Link>
                </span>
            ),
        },
    ]

    const curr_pagination: PaginationConfig = {
        position: 'bottom',
        current: page + 1,
        defaultPageSize: 25,
        total: count,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['25', '50', '100'],
    }

    return (
        <Table
            dataSource={data}
            rowKey="id"
            loading={loading}
            columns={columns}
            pagination={curr_pagination}
            rowClassName={(record, index) => {
                return (index + 1) % 2 === 0 ? 'table-alt-row' : ''
            }}
            onChange={handleTableChange}
        />
    )
}

export default BucketList
