import React from "react";

const LoadingField = props => {
  const { width } = props;
  return (
    <span style={{ width: width || 120, height: 15, display: "inline-block" }} className="animated-background">
      &nbsp;
    </span>
  );
};

export default LoadingField;
