import React from 'react'
import { useIsFetching, useIsMutating } from 'react-query'
import logo from './pp-logo-updated.png'

const FullPageLoading = () => {
    const isFetching = useIsFetching()
    const isMutating = useIsMutating()

    if (!isFetching && !isMutating) return null

    return (
        <div className="loader-container">
            <div className="loader">
                <img src={logo} alt="Loading..." />
            </div>
        </div>
    )
}

export default FullPageLoading
