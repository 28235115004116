/*
 * Vinod <vmohanan@coretechs.com>
 * Thu Aug 15 2019
 */
export const default_sheet = [
    {
        year: '20xx',
        return: 0.14,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: 0.14,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: -0.2,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: 0.16,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: 0.16,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: 0.16,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: 0.16,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: 0.16,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: 0.16,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
    {
        year: '20xx',
        return: -0.2,
        gain_loss: 42000,
        total: 342000,
        ira_value: 287714,
    },
]

export const default_lost_sheet = [
    {
        year: '2000',
        return: -0.09,
        gain_loss: -27095.0,
        total: 272905.0,
        ira_value: 218619.0,
    },
    {
        year: '2001',
        return: -0.118,
        gain_loss: -25906.0,
        total: 192713.0,
        ira_value: 138427.0,
    },
    {
        year: '2002',
        return: -0.2,
        gain_loss: -30407.0,
        total: 108020.0,
        ira_value: 53735.0,
    },
    {
        year: '2003',
        return: 0.284,
        gain_loss: 15237.0,
        total: 68971.0,
        ira_value: 14686.0,
    },
    {
        year: '2004',
        return: 0.107,
        gain_loss: 1578.0,
        total: 16263.0,
        ira_value: -38022.0,
    },
    {
        year: '2005',
        return: 0.048,
        gain_loss: -1838.0,
        total: -39860.0,
        ira_value: -94146.0,
    },
    {
        year: '2006',
        return: 0.156,
        gain_loss: -14699.0,
        total: -108845.0,
        ira_value: -163131.0,
    },
    {
        year: '2007',
        return: 0.055,
        gain_loss: -8947.0,
        total: -172078.0,
        ira_value: -226364.0,
    },
    {
        year: '2008',
        return: -0.366,
        gain_loss: 82741.0,
        total: -143622.0,
        ira_value: -197908,
    },
    {
        year: '2009',
        return: 0.259,
        gain_loss: -51328.0,
        total: -249236.0,
        ira_value: -303522,
    },
]

export const vola_calc = (input, sheet) => {
    let year = 0
    let new_sheet = []
    let chart_data = []
    let snp500_data = []
    let run_value = parseFloat(input['starting'])
    let last_year = null
    input['gross_income'] =
        parseFloat(input['net_income']) /
        (1 - parseFloat(input['tax_rate']) / 100)
    sheet.forEach((row) => {
        const starting = last_year || parseFloat(input['starting'])
        row['gain_loss'] = starting * parseFloat(row['return'])
        row['total'] = starting + row['gain_loss']
        row['ira_value'] = row['total'] - input['gross_income']
        last_year = row['ira_value']
        chart_data.push({
            year: year + '',
            income: row['ira_value'],
        })
        run_value = run_value * (1 + row['return'])
        snp500_data.push({
            year: year + '',
            income: run_value,
        })
        year = year + 1
        row['gross_income'] = input['gross_income']
        new_sheet.push(row)
    })

    return { new_sheet, chart_data, snp500_data }
}
