import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestClients } from '../actions'
import ClientList from '../components/client/list'

const ClientsPage = (props) => {
    useEffect(() => {
        props.requestClients(props.page, props.pageSize, props.sorted)
    }, [])

    const pageChangeHandler = (pagination, filters, sorter) => {
        console.debug('sorter', sorter)

        let sorted
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        props.requestClients(
            pagination.current - 1,
            pagination.pageSize,
            sorted
        )
    }

    return <ClientList pageChangeHandler={pageChangeHandler} {...props} />
}

const mapStateToProps = (state) => {
    return {
        ...state.clients,
    }
}

export default connect(mapStateToProps, { requestClients })(ClientsPage)
