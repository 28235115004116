import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { requestAgency, requestStates, requestUpdateAgency } from '../actions'
import AgencyEdit from '../components/agency/edit'

function AgencyEditPage(props) {
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        props.requestStates()
        props.requestAgency(params.agencyId)
    }, [])

    const updateAgency = (values) => {
        props.requestUpdateAgency(params.agencyId, { ...values, history })
    }

    return <AgencyEdit {...props} handleSubmit={updateAgency} />
}

const mapStateToProps = (state) => {
    return {
        ...state.agency,
        // data: state.agency,
        states: state.states,
    }
}

export default connect(mapStateToProps, {
    requestAgency,
    requestStates,
    requestUpdateAgency,
})(AgencyEditPage)
