import { Button, Card, Modal, Row, Spin, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { Field, Form, Formik, FormikValues } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import BSField from './shared_components/bs_field'
import * as yup from 'yup'
import { CustomerModel } from '..'

interface ClientSelectProps {
    title: string
    setSelectedClient: Function
    requestCalcClients: Function
    requestCreateCalcClient: Function
    clients: {
        loading: boolean
        data: CustomerModel[]
    }
}

const ClientSelect = (props: ClientSelectProps) => {
    const {
        title,
        requestCalcClients,
        setSelectedClient,
        requestCreateCalcClient,
        clients,
    } = props

    const [addCalcClientModal, setAddCalcClientModal] = useState(false)

    useEffect(() => {
        requestCalcClients()
    }, [])

    useEffect(() => {
        if (!addCalcClientModal) requestCalcClients()
    }, [addCalcClientModal])

    const pageChangeHandler = (page: number, pageSize?: number | undefined) => {
        requestCalcClients(page, pageSize)
    }

    const columns: ColumnProps<any>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Age',
            align: 'right',
            dataIndex: 'age',
        },
        {
            title: 'Filing Status',
            key: 'filing_status',
            dataIndex: 'filingStatusText',
        },
        {
            title: 'Qualified Plan Balance',
            align: 'right',
            dataIndex: 'qualifiedPlanBalance',
        },
        {
            title: 'Created',
            dataIndex: 'createdOn',
            key: 'created_on',
            render: (created_on) => moment(created_on).format('MM/DD/YYYY LT'),
        },
    ]

    const submitHandler = async (values: FormikValues) => {
        await requestCreateCalcClient(values)
        setAddCalcClientModal(false)
    }

    const ageValidate = yup
        .number()
        .typeError('Not a valid number, please correct')
        .min(18, 'must be greater than or equal to 18')
        .max(100, 'must be less than or equal to 100')
        .required('This field is required')

    const posNumValidate = yup
        .number()
        .typeError('Not a valid number, please correct')
        .min(0, 'must be greater than or equal to 0')
        .required('This field is required')

    const valSchema = yup.object().shape({
        qualifiedPlanBalance: posNumValidate,
        age: ageValidate,
        filingStatus: yup
            .number()
            .oneOf([1, 2], 'please select a filing status')
            .required('This field is required'),
        name: yup.string().required('This field is required'),
    })

    return (
        <Card
            title={
                `${title} - Select Client` ||
                'Income Tax Comparison Add - Select Client'
            }
            style={{ minHeight: 300 }}
            extra={
                <Row>
                    <Button
                        type="default"
                        shape="round"
                        icon="plus"
                        onClick={() => setAddCalcClientModal(true)}
                    />
                </Row>
            }
        >
            <Modal
                title="Create Client"
                footer={null}
                visible={addCalcClientModal}
            >
                <Formik
                    validationSchema={valSchema}
                    initialValues={{
                        name: '',
                        age: '',
                        qualifiedPlanBalance: '',
                        filingStatus: '',
                    }}
                    onSubmit={(values: FormikValues, actions) => {
                        submitHandler(values)
                        actions.setSubmitting(false)
                    }}
                >
                    {({ values, touched, errors, isValid, isSubmitting }) => {
                        return (
                            <Form>
                                <BSField
                                    touched={touched.name}
                                    error={errors.name}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="name"
                                    >
                                        Name
                                    </label>
                                    <Field
                                        type="text"
                                        name="name"
                                        className="form-control"
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.age}
                                    error={errors.age}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="age"
                                    >
                                        Age
                                    </label>
                                    <Field
                                        name="age"
                                        className="form-control input-num"
                                    />
                                </BSField>
                                <BSField
                                    touched={touched.filingStatus}
                                    error={errors.filingStatus}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="filingStatus"
                                    >
                                        Filing Status
                                    </label>
                                    <Field
                                        name="filingStatus"
                                        className="form-control"
                                        component="select"
                                    >
                                        {!values.filingStatus && (
                                            <option key={0} value={0}>
                                                ---
                                            </option>
                                        )}
                                        <option key={1} value={1}>
                                            Single
                                        </option>
                                        <option key={2} value={2}>
                                            Married / Joint
                                        </option>
                                    </Field>
                                </BSField>
                                <BSField
                                    touched={touched.qualifiedPlanBalance}
                                    error={errors.qualifiedPlanBalance}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="qualifiedPlanBalance"
                                    >
                                        Qualified Plan Balance
                                    </label>
                                    <Field
                                        name="qualifiedPlanBalance"
                                        className="form-control input-num"
                                    />
                                </BSField>
                                <div className="form-group">
                                    <Button
                                        onClick={() =>
                                            setAddCalcClientModal(false)
                                        }
                                        title="Cancel"
                                        type="danger"
                                        loading={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary pull-right"
                                        disabled={!isValid}
                                    >
                                        <Spin spinning={isSubmitting} />
                                        Save
                                    </button>
                                    {isValid}
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal>
            <Table
                loading={clients.loading}
                columns={columns}
                dataSource={clients.data}
                pagination={{
                    pageSize: 25,
                    hideOnSinglePage: true,
                    onChange: pageChangeHandler,
                }}
                rowKey={(record) => String(record.id)}
                rowSelection={{
                    type: 'radio',
                    onSelect: (record: CustomerModel) => {
                        setSelectedClient(record)
                    },
                }}
            />
        </Card>
    )
}

export default ClientSelect
