import React from "react";

const Account = props => {
  const { values, handleChange } = props;
  return (
    <div className="panel panel-default panel-detail ">
      <div className="panel-heading">
        <h3 className="panel-title">Account</h3>
      </div>
      <div className="panel-body form-horizontal">
        <div className="form-group">
          <label className="col-md-7 control-label">Account Balance</label>
          <div className="col-md-5">
            <input
              type="text"
              name="accountBalance"
              className="form-control"
              value={values.account.accountBalance}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-7 control-label"># of Premiums</label>
          <div className="col-md-5">
            <input
              type="text"
              name="numOfPremium"
              className="form-control"
              value={values.account.numOfPremium}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-7 control-label">Income Start Age</label>
          <div className="col-md-5">
            <input
              type="text"
              name="incomeStartAge"
              className="form-control"
              value={values.account.incomeStartAge}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-7 control-label">Income Amount</label>
          <div className="col-md-5">
            <input
              type="text"
              name="targetIncome"
              className="form-control"
              value={values.account.targetIncome}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
