/*
 * Created by Sina Khelil on 2/1/16.
 */

import React from 'react'
import ListProposals from '../shared_components/list_proposals'
import ErrorBoundary from '../shared_components/error_boundary'

import 'react-table/react-table.css'
import { Card } from 'antd'

export default class ProposalList extends React.Component {
    render() {
        const {
            data,
            page,
            perPage,
            count,
            sorted,
            loading,
            error,
            pagination,
            pageChangeHandler,
        } = this.props
        return (
            <Card
                bodyStyle={{
                    padding: 0,
                    margin: 0,
                }}
                bordered={false}
                title={''}
            >
                <ErrorBoundary>
                    {loading && <div>Loading...</div>}
                    {error && <div>Something broke | {error}</div>}
                    {data && (
                        <ListProposals
                            data={data}
                            loading={loading}
                            count={count}
                            perPage={perPage}
                            minRows={0}
                            page={page}
                            pagination={pagination}
                            sorted={sorted}
                            pageChangeHandler={pageChangeHandler}
                        />
                    )}
                </ErrorBoundary>
            </Card>
        )
    }
}
