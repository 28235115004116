import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const CancelButton = (props: any): JSX.Element => {
    const { name, className, onPress } = props
    const history = useHistory()
    const location = useLocation()
    return (
        <button
            type="button"
            className={className}
            onClick={() => {
                if (onPress !== undefined) onPress()
                history.push(location.pathname)
            }}
        >
            {name}
        </button>
    )
}

export default CancelButton
