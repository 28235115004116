import numeral from 'numeral'
import React from 'react'
import { IncomeCompModelData, ICEstateTaxData } from '../..'

interface ICEstateTaxProps {
  data: IncomeCompModelData
}

interface ICEstateTaxTableProps {
  data: IncomeCompModelData,
  dataRows: ICEstateTaxData[],
  tableType: "current" | "after"
}

const EstateTaxTable = ({ data, tableType, dataRows }: ICEstateTaxTableProps) => {
  const highlightAges = [85, 90]

  return (
    <table className="ic-estatetax-table">
    <thead>
      <tr>
        <th colSpan={7}></th>
        <th colSpan={2} className="text-bold text-center ic-4-borders ic-blue-bgnd">
          Current Laws
        </th>
        <th colSpan={2} className="text-bold text-center ic-4-borders ic-orange-bgnd">
          Rollback in 2026
        </th>
      </tr>
      <tr className="text-bottom ic-4-borders">
        <th className="text-bold ic-blue-bgnd">Year</th>
        <th className="text-bold ic-blue-bgnd">Age of<br/>Death</th>
        <th className="text-bold ic-blue-bgnd">Estimated IRA<br/>@ {data.growthRateType === 'fixed' ? numeral(data.growthRate/100).format('0,0%') : 'Historical'}</th>
        <th className="text-bold ic-blue-bgnd">Estimated<br/>NQ Assets<br/>@ {data.growthRateType === 'fixed' ? numeral(data.growthRate/100).format('0,0%') : 'Historical'}</th>
        <th className="text-bold ic-blue-bgnd">Reinvested<br/>Net RMD<br/>@ {data.growthRateType === 'fixed' ? numeral(data.growthRate/100).format('0,0%') : 'Historical'}</th>
        <th className="text-bold ic-blue-bgnd">Estimated<br/>Property<br/>Value @ {data.propertyValueGrowthRate}%</th>
        <th className="text-bold ic-blue-bgnd">Estimated<br/>Estate Value</th>
        <th className="text-bold ic-blue-bgnd ic-left-border">Taxable<br/>Estate<br/>({data.customerFilingStatusText})</th>
        <th className="text-bold ic-blue-bgnd">
          Estate Tax<br/>({data.customerFilingStatusText}) @<br/>{data.estateTaxRate}%
        </th>
        <th className="text-bold ic-orange-bgnd ic-left-border">
          Taxable Estate<br/>({data.customerFilingStatusText})
        </th>
        <th className="text-bold ic-orange-bgnd">
          Estate Tax<br/>({data.customerFilingStatusText}) @<br/>{data.estateTaxRate}%
        </th>
      </tr>
    </thead>
    <tbody>
      <tr className="empty-row ic-left-border ic-right-border">
        <td colSpan={5}></td>
        <td colSpan={2} className="ic-right-border"></td>
        <td colSpan={2} className="ic-right-border ic-blue-bgnd"></td>
        <td colSpan={2} className="ic-right-border ic-orange-bgnd"></td>
      </tr>
      {dataRows.map((dataRow: ICEstateTaxData, idx: number) => {
        return (
            <tr key={`${tableType}-${idx}`}>
              <td
                className={`text-number ic-left-border ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {dataRow.taxYear}
              </td>
              <td
                className={`text-number ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {dataRow.eoyAge}
              </td>
              <td
                className={`text-number ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.planBalance).format('(0,0)')}
              </td>
              <td
                className={`text-number ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.nonQualifiedAssetsBalance).format('(0,0)')}
              </td>
              <td
                className={`text-number ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.reinvestedNetRmdAmount).format('(0,0)')}
              </td>
              <td
                className={`text-number ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.estimatedPropertyValueBalance).format('(0,0)')}
              </td>
              <td
                className={`text-number ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.estimatedEstateValue).format('(0,0)')}
              </td>
              <td
                className={`text-number ic-left-border  ic-blue-bgnd ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.taxableEstateAmount).format('(0,0)')}
              </td>
              <td
                className={`text-number ic-blue-bgnd ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.estateTax).format('(0,0)')}
              </td>
              <td
                className={`text-number ic-left-border ic-orange-bgnd ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.rollbackTaxableEstateAmount).format('(0,0)')}
              </td>
              <td
                className={`text-number ic-right-border ic-orange-bgnd ${highlightAges.includes(dataRow.eoyAge) ? "text-bold" : ""} ${dataRow.eoyAge === 90 ? "ic-bottom-border" : ""}`}
              >
                {numeral(dataRow.rollbackEstateTax).format('(0,0)')}
              </td>
            </tr>
          )
        }
      )}
    </tbody>
  </table>
  )
}

export const ICEstateTax = (props: ICEstateTaxProps) => {
  const { data } = props
  return (
    <div>
      <h1 className="text-blue text-center text-h1-spacing">Estate Tax Analysis</h1>
      <h2 className="text-blue text-center">Initial Qualified Plan Balance: {numeral(data.qualifiedPlanBalance).format('$0,000')}</h2>
      <br/>
      <div className="table-text-subheader">Current Qualified Plan</div>
      <EstateTaxTable data={data} dataRows={data.estateCalcData.current} tableType='current' />
      <div style={{ marginTop: 30 }}>
        <div>Assumes {data.customerFilingStatusText} filing status for estate tax exemption</div>
        <div>
          Current exemption:: {numeral(data.estateTaxExemptionAmount).format('$0,0')}
        </div>
        <div>
          After rollback in 2026: {numeral(data.estateTaxExemptionRollbackAmount).format('$0,0')}
        </div>
      </div>
    </div>
  )
}
