import { Col, Row } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { requestProposals } from '../actions'
import AgencyWidget from '../components/dashboard/agencyWidget'
import CustomerWidget from '../components/dashboard/customerWidget'

class DashboardPage extends Component {
    render() {
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <CustomerWidget />
                </Col>

                <Col span={12}>
                    <AgencyWidget />
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.proposals,
    }
}

export default connect(mapStateToProps, { requestProposals })(DashboardPage)
