/*
 * Vinod <vmohanan@coretechs.com>
 * Thu Aug 15 2019
 */
import { Card, Col, Row, Table } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { default_lost_sheet, vola_calc } from './calc'
import Chart from './chart'
import Form from './form'

const columns = [
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: 'Return',
        dataIndex: 'return',
        key: 'return',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: (text * 100).toFixed(3) + '%',
            }
        },
    },
    {
        title: 'Gain/Loss',
        dataIndex: 'gain_loss',
        key: 'gain_loss',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
    {
        title: 'Gross Income',
        dataIndex: 'gross_income',
        key: 'gross_income',
        render: (text, record) => {
            return {
                props: {
                    style: { textAlign: 'right' },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
    {
        title: 'IRA Account Value',
        dataIndex: 'ira_value',
        key: 'ira_value',
        render: (text, record) => {
            return {
                props: {
                    style: {
                        textAlign: 'right',
                        backgroundColor: text < 0 ? '#FF9999' : '#99FFAA',
                    },
                },
                children: `$ ${text.toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                ),
            }
        },
    },
]

class VolatilityPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sheet: [],
            default_values: {
                starting: 300000,
                net_income: 38000,
                tax_rate: 30,
                gross_income: 0,
            },
            chart_data: [],
            snp500_data: [],
        }
    }

    componentWillMount() {
        const { new_sheet, chart_data, snp500_data } = vola_calc(
            this.state.default_values,
            default_lost_sheet
        )
        this.setState({
            sheet: new_sheet,
            chart_data: chart_data,
            snp500_data: snp500_data,
        })
    }

    formSubmit(values) {
        const { new_sheet, chart_data, snp500_data } = vola_calc(
            values,
            default_lost_sheet
        )
        this.setState({
            sheet: new_sheet,
            chart_data: chart_data,
            snp500_data: snp500_data,
        })
    }

    render() {
        return (
            <Card
                bordered={false}
                extra={<Form onSubmit={(values) => this.formSubmit(values)} />}
                title="Volatility - Lost"
            >
                <Row gutter={10}>
                    <Col span={15}>
                        <Table
                            pagination={false}
                            dataSource={this.state.sheet}
                            columns={columns}
                        />
                    </Col>
                    <Col span={9}>
                        <Row>
                            <Card
                                headStyle={{
                                    backgroundColor: 'lightgrey',
                                    textAlign: 'center',
                                }}
                                bordered={false}
                                title={'Account Value'}
                            >
                                <Chart id={2} data={this.state.chart_data} />
                            </Card>
                        </Row>
                        <Row>
                            <Card
                                headStyle={{
                                    backgroundColor: 'lightgrey',
                                    textAlign: 'center',
                                }}
                                bordered={false}
                                title={'S&P 500'}
                            >
                                <Chart id={1} data={this.state.snp500_data} />
                            </Card>
                        </Row>
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default connect()(VolatilityPage)
