
export const incomeFactors =
    [{ age: 15, factor: 30 },
    { age: 16, factor: 30 },
    { age: 17, factor: 30 },
    { age: 18, factor: 30 },
    { age: 19, factor: 30 },
    { age: 20, factor: 30 },
    { age: 21, factor: 30 },
    { age: 22, factor: 30 },
    { age: 23, factor: 30 },
    { age: 24, factor: 30 },
    { age: 25, factor: 30 },
    { age: 26, factor: 30 },
    { age: 27, factor: 30 },
    { age: 28, factor: 30 },
    { age: 29, factor: 30 },
    { age: 30, factor: 30 },
    { age: 31, factor: 25 },
    { age: 32, factor: 25 },
    { age: 33, factor: 25 },
    { age: 34, factor: 25 },
    { age: 35, factor: 25 },
    { age: 36, factor: 25 },
    { age: 37, factor: 25 },
    { age: 38, factor: 25 },
    { age: 39, factor: 25 },
    { age: 40, factor: 25 },
    { age: 41, factor: 20 },
    { age: 42, factor: 20 },
    { age: 43, factor: 20 },
    { age: 44, factor: 20 },
    { age: 45, factor: 20 },
    { age: 46, factor: 20 },
    { age: 47, factor: 20 },
    { age: 48, factor: 20 },
    { age: 49, factor: 20 },
    { age: 50, factor: 20 },
    { age: 51, factor: 15 },
    { age: 52, factor: 15 },
    { age: 53, factor: 15 },
    { age: 54, factor: 15 },
    { age: 55, factor: 15 },
    { age: 56, factor: 10 },
    { age: 57, factor: 10 },
    { age: 58, factor: 10 },
    { age: 59, factor: 10 },
    { age: 60, factor: 10 },
    { age: 61, factor: 10 },
    { age: 62, factor: 10 },
    { age: 63, factor: 10 },
    { age: 64, factor: 10 },
    { age: 65, factor: 10 },
    { age: 66, factor: 8 },
    { age: 67, factor: 8 },
    { age: 68, factor: 8 },
    { age: 69, factor: 7 },
    { age: 70, factor: 7 },
    { age: 71, factor: 6 },
    { age: 72, factor: 6 },
    { age: 73, factor: 5 },
    { age: 74, factor: 5 },
    { age: 75, factor: 5 },
    { age: 76, factor: 2 },
    { age: 77, factor: 2 },
    { age: 78, factor: 2 },
    { age: 79, factor: 2 },
    { age: 80, factor: 2 },
    { age: 81, factor: 2 },
    { age: 82, factor: 2 },
    { age: 83, factor: 2 },
    { age: 84, factor: 2 },
    { age: 85, factor: 2 }];

export const estateFactors = [
    { age: 15, years: 25, rate: 6 },
    { age: 16, years: 25, rate: 6 },
    { age: 17, years: 25, rate: 6 },
    { age: 18, years: 25, rate: 6 },
    { age: 19, years: 25, rate: 6 },
    { age: 20, years: 25, rate: 6 },
    { age: 21, years: 25, rate: 6 },
    { age: 22, years: 25, rate: 6 },
    { age: 23, years: 25, rate: 6 },
    { age: 24, years: 25, rate: 6 },
    { age: 25, years: 25, rate: 6 },
    { age: 26, years: 25, rate: 6 },
    { age: 27, years: 25, rate: 6 },
    { age: 28, years: 25, rate: 6 },
    { age: 29, years: 25, rate: 6 },
    { age: 30, years: 25, rate: 6 },
    { age: 31, years: 25, rate: 6 },
    { age: 32, years: 25, rate: 6 },
    { age: 33, years: 25, rate: 6 },
    { age: 34, years: 25, rate: 6 },
    { age: 35, years: 25, rate: 6 },
    { age: 36, years: 25, rate: 6 },
    { age: 37, years: 25, rate: 6 },
    { age: 38, years: 25, rate: 6 },
    { age: 39, years: 25, rate: 6 },
    { age: 40, years: 25, rate: 6 },
    { age: 41, years: 20, rate: 6 },
    { age: 42, years: 20, rate: 6 },
    { age: 43, years: 20, rate: 6 },
    { age: 44, years: 20, rate: 6 },
    { age: 45, years: 20, rate: 6 },
    { age: 46, years: 20, rate: 6 },
    { age: 47, years: 20, rate: 6 },
    { age: 48, years: 20, rate: 6 },
    { age: 49, years: 20, rate: 6 },
    { age: 50, years: 20, rate: 6 },
    { age: 51, years: 15, rate: 6 },
    { age: 52, years: 15, rate: 6 },
    { age: 53, years: 15, rate: 6 },
    { age: 54, years: 15, rate: 6 },
    { age: 55, years: 15, rate: 6 },
    { age: 56, years: 15, rate: 6 },
    { age: 57, years: 15, rate: 6 },
    { age: 58, years: 15, rate: 6 },
    { age: 59, years: 15, rate: 6 },
    { age: 60, years: 15, rate: 6 },
    { age: 61, years: 15, rate: 6 },
    { age: 62, years: 15, rate: 6 },
    { age: 63, years: 15, rate: 6 },
    { age: 64, years: 15, rate: 6 },
    { age: 65, years: 15, rate: 6 },
    { age: 66, years: 10, rate: 6 },
    { age: 67, years: 10, rate: 6 },
    { age: 68, years: 10, rate: 6 },
    { age: 69, years: 10, rate: 6 },
    { age: 70, years: 10, rate: 6 },
    { age: 71, years: 8, rate: 6 },
    { age: 72, years: 8, rate: 6 },
    { age: 73, years: 8, rate: 6 },
    { age: 74, years: 8, rate: 6 },
    { age: 75, years: 8, rate: 6 },
    { age: 76, years: 6, rate: 4 },
    { age: 77, years: 6, rate: 4 },
    { age: 78, years: 6, rate: 4 },
    { age: 79, years: 6, rate: 4 },
    { age: 80, years: 6, rate: 4 },
    { age: 81, years: 4, rate: 4 },
    { age: 82, years: 4, rate: 4 },
    { age: 83, years: 4, rate: 4 },
    { age: 84, years: 4, rate: 4 },
    { age: 85, years: 4, rate: 4 }];

export const lnwPremFactors = [
    { age: 15, rate: 40 },
    { age: 16, rate: 40 },
    { age: 17, rate: 40 },
    { age: 18, rate: 40 },
    { age: 19, rate: 40 },
    { age: 20, rate: 40 },
    { age: 21, rate: 40 },
    { age: 22, rate: 40 },
    { age: 23, rate: 40 },
    { age: 24, rate: 40 },
    { age: 25, rate: 40 },
    { age: 26, rate: 40 },
    { age: 27, rate: 40 },
    { age: 28, rate: 40 },
    { age: 29, rate: 40 },
    { age: 30, rate: 40 },
    { age: 31, rate: 40 },
    { age: 32, rate: 40 },
    { age: 33, rate: 40 },
    { age: 34, rate: 40 },
    { age: 35, rate: 40 },
    { age: 36, rate: 40 },
    { age: 37, rate: 40 },
    { age: 38, rate: 40 },
    { age: 39, rate: 40 },
    { age: 40, rate: 40 },
    { age: 41, rate: 40 },
    { age: 42, rate: 40 },
    { age: 43, rate: 40 },
    { age: 44, rate: 40 },
    { age: 45, rate: 40 },
    { age: 46, rate: 40 },
    { age: 47, rate: 40 },
    { age: 48, rate: 40 },
    { age: 49, rate: 40 },
    { age: 50, rate: 40 },
    { age: 51, rate: 40 },
    { age: 52, rate: 40 },
    { age: 53, rate: 40 },
    { age: 54, rate: 40 },
    { age: 55, rate: 40 },
    { age: 56, rate: 40 },
    { age: 57, rate: 40 },
    { age: 58, rate: 40 },
    { age: 59, rate: 40 },
    { age: 60, rate: 30 },
    { age: 61, rate: 30 },
    { age: 62, rate: 30 },
    { age: 63, rate: 30 },
    { age: 64, rate: 30 },
    { age: 65, rate: 30 },
    { age: 66, rate: 30 },
    { age: 67, rate: 30 },
    { age: 68, rate: 30 },
    { age: 69, rate: 30 },
    { age: 70, rate: 20 },
    { age: 71, rate: 20 },
    { age: 72, rate: 20 },
    { age: 73, rate: 20 },
    { age: 74, rate: 20 },
    { age: 75, rate: 20 },
    { age: 76, rate: 20 },
    { age: 77, rate: 20 },
    { age: 78, rate: 20 },
    { age: 79, rate: 20 },
    { age: 80, rate: 20 },
    { age: 81, rate: 20 },
    { age: 82, rate: 20 },
    { age: 83, rate: 20 },
    { age: 84, rate: 20 },
    { age: 85, rate: 20 }];