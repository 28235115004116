/*
 * Created by Sina Khelil on 2/1/16.
 */

import React from 'react'
import ListIllustrations from '../shared_components/list_illustrations'
import ErrorBoundary from '../shared_components/error_boundary'

import 'react-table/react-table.css'
import { Card } from 'antd'

const IllustrationList = (props) => {
    const {
        data,
        perPage,
        count,
        sorted,
        page,
        loading,
        error,
        pageChangeHandler,
    } = props
    if (error) {
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">Illustrations</h3>
                </div>
                <div className="panel-body">
                    <div>Something broke | {error.message}</div>
                </div>
            </div>
        )
    } else {
        return (
            <Card
                bodyStyle={{
                    padding: 0,
                    margin: 0,
                }}
                bordered={false}
                title={''}
            >
                <ErrorBoundary>
                    <ListIllustrations
                        data={data}
                        page={page}
                        loading={loading}
                        count={count}
                        perPage={perPage}
                        minRows={0}
                        sorted={sorted}
                        pageChangeHandler={pageChangeHandler}
                    />
                </ErrorBoundary>
            </Card>
        )
    }
}

export default IllustrationList
