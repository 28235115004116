import React from 'react'
import { connect } from 'react-redux'

import Cashflow from '../components/cashflow'

const CashflowPage = () => {
    return <Cashflow />
}

export default connect()(CashflowPage)
