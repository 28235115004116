import React from 'react'
import NumberInput from '../../../../components/shared_components/numberinput'
import { BucketType } from '../../../../index.d'
import { UseMutationResult } from 'react-query'
import { AxiosError } from 'axios'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

interface BucketGlobalsProps {
    bucket: BucketType
    updateBucket: UseMutationResult<
        BucketType | undefined,
        AxiosError,
        BucketType
    >
}

const BucketGlobals = (props: BucketGlobalsProps) => {
    const { bucket, updateBucket } = props

    const globalsSchema = Yup.object().shape({
        assumedInflation: Yup.number()
            .min(0, 'Minimum ${min}')
            .max(100, 'Maximum ${max}')
            .required('Required'),
        returnedFunds: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        returnedDeferral: Yup.number()
            .min(0, 'Minimum ${min}')
            .required('Required'),
        baseRateOfReturn: Yup.number()
            .min(0, 'Minimum ${min}')
            .max(100, 'Maximum ${max}')
            .required('Required'),
    })

    return (
        <Formik
            initialValues={bucket}
            validationSchema={globalsSchema}
            onSubmit={(values) => {
                updateBucket.mutate({ ...bucket, ...values })
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <Form>
                    <div className="col-md-6" style={{ paddingBottom: '2px' }}>
                        Assumed Inflation %
                    </div>
                    <div className="col-md-6 input-group">
                        <NumberInput
                            className="form-control input-sm"
                            error={errors.assumedInflation}
                            format="0.000"
                            name="assumedInflation"
                            value={values.assumedInflation}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setFieldValue(
                                    'assumedInflation',
                                    parseFloat(event.target.value)
                                )
                            }
                            onBlur={() => {
                                handleSubmit()
                            }}
                        />
                    </div>
                    <div className="col-md-6" style={{ paddingBottom: '2px' }}>
                        Returned Funds
                    </div>
                    <div className="col-md-6 input-group">
                        <NumberInput
                            className="form-control input-sm"
                            error={errors.returnedFunds}
                            format="$0,0"
                            name="returnedFunds"
                            value={values.returnedFunds}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setFieldValue(
                                    'returnedFunds',
                                    parseInt(event.target.value)
                                )
                            }
                            onBlur={() => {
                                handleSubmit()
                            }}
                        />
                    </div>
                    <div className="col-md-6" style={{ paddingBottom: '2px' }}>
                        Return Deferral Years
                    </div>
                    <div className="col-md-6 input-group">
                        <NumberInput
                            className="form-control input-sm"
                            error={errors.returnedDeferral}
                            format="0"
                            name="returnedDeferral"
                            value={values.returnedDeferral}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setFieldValue(
                                    'returnedDeferral',
                                    parseInt(event.target.value)
                                )
                            }
                            onBlur={() => {
                                handleSubmit()
                            }}
                        />
                    </div>
                    <div className="col-md-6" style={{ paddingBottom: '2px' }}>
                        Rate of Return %
                    </div>
                    <div className="col-md-6 input-group">
                        <NumberInput
                            className="form-control input-sm"
                            error={errors.baseRateOfReturn}
                            format="0.000"
                            name="returnRateOfReturn"
                            value={values.baseRateOfReturn}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setFieldValue(
                                    'baseRateOfReturn',
                                    parseFloat(event.target.value)
                                )
                            }
                            onBlur={() => {
                                handleSubmit()
                            }}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

BucketGlobals.displayName = 'BucketGlobals'

export default BucketGlobals
