import { LoadingOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import numeral from 'numeral'
import React, { ChangeEvent, useEffect, useReducer, useState } from 'react'
import {
    IncomeCompModelData,
    TaxCalcStatus,
    TaxCalcData,
    CalculatedTaxCalcData,
} from '../..'

import { commonService } from '../../services/common.service'
import { CALCULATED_TAX_CALC_DATA } from '../shared_components/constants'

interface ICTaxCalcProps {
    data: IncomeCompModelData
    taxCalcStatus: TaxCalcStatus
    updateTaxCalcHandler: Function
}

const taxDataReducer = (
    state: TaxCalcData,
    newState: TaxCalcData
): TaxCalcData => ({
    ...state,
    ...newState,
})

const taxCalcDataReducer = (
    state: CalculatedTaxCalcData,
    newState: CalculatedTaxCalcData
): CalculatedTaxCalcData => ({
    ...state,
    ...newState,
})

const loadingReducer = (
    state: { [key: string]: boolean },
    newState: { [key: string]: boolean }
): { [key: string]: boolean } => ({
    ...state,
    ...newState,
})

export const ICTaxCalc = (props: ICTaxCalcProps) => {
    const { data, updateTaxCalcHandler, taxCalcStatus } = props

    const [stateTaxRate, setStateTaxRate] = useState(10)
    const [taxData, setTaxData] = useReducer(taxDataReducer, data.taxCalcData)
    const [taxCalcData, setTaxCalcData] = useReducer(
        taxCalcDataReducer,
        CALCULATED_TAX_CALC_DATA
    )
    const [loading, setLoading] = useReducer(loadingReducer, {
        doNothing: true,
        reallocate: true,
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const [category, name] = e.currentTarget.name.split('.')
        const newValue = e.currentTarget.valueAsNumber
        const categoryData = taxData[category]
        const newTaxData = {
            [category]: {
                ...categoryData,
                [name]: newValue,
            },
        } as TaxCalcData
        setTaxData(newTaxData)
    }

    const processChange = async (category: string): Promise<void> => {
        setLoading({ [category]: true })
        const categoryData = taxData[category]
        let newTotalIncome = 0
        const totalIncomeFields = [
            'wages1',
            'iraDistrib4B',
            'pensions4D',
            'socialSecurity5B',
            'irs7702Distribs',
        ]
        totalIncomeFields.forEach((field) => {
            newTotalIncome = newTotalIncome + categoryData[field]
        })
        const newAdjustedIncome =
            newTotalIncome -
            categoryData.adjustments8A -
            categoryData.irs7702Distribs
        const newAddLines =
            categoryData.standardDeduc9 + categoryData.qualBizIncome10
        const newTaxableAmount = newAdjustedIncome - newAddLines
        const newStateTaxAmount =
            newTaxableAmount * (categoryData.stateTaxRate / 100)
        const {
            data: {
                data: { taxAmount },
            },
        } = await commonService.getTaxAmount(
            newTaxableAmount,
            data.customerFilingStatus,
            data.taxCalcStartYear
        )

        const effectiveTaxRate =
            (((taxAmount + newStateTaxAmount) / newTaxableAmount) * 10000) /
            10000

        setTaxCalcData({
            [category]: {
                totalIncome7B: newTotalIncome,
                adjustedIncome8B: newAdjustedIncome,
                addLines11A: newAddLines,
                taxableIncome11B: newTaxableAmount,
                federalTax12A: taxAmount,
                stateTax: newStateTaxAmount,
                effectiveTaxRate: effectiveTaxRate,
                netIncome: newTotalIncome - (taxAmount + newStateTaxAmount),
                cashFlowTaxRate:
                    (((taxAmount + newStateTaxAmount) / newTotalIncome) *
                        10000) /
                    10000,
                preTaxEquivalentTotalIncome:
                    newTotalIncome / (1 - effectiveTaxRate),
                afterTaxIncome:
                    newTaxableAmount * (1 - effectiveTaxRate) +
                    categoryData.irs7702Distribs,
            },
        } as CalculatedTaxCalcData)
        setLoading({ [category]: false })
    }

    useEffect(() => {
        ;(async () => {
            setTaxData({
                doNothing: {
                    ...taxData.doNothing,
                    year: data.taxCalcFutureYear,
                },
                reallocate: {
                    ...taxData.reallocate,
                    year: data.taxCalcFutureYear,
                },
            })
        })()
    }, [])

    const updateTaxCalc = async (payload: TaxCalcData) => {
        await updateTaxCalcHandler({ data: payload })
    }

    const updateTaxCalcHandlerDebounced = AwesomeDebouncePromise(
        updateTaxCalc,
        1000
    )

    useEffect(() => {
        ;(async () => {
            await processChange('doNothing')
            await updateTaxCalcHandlerDebounced(taxData)
        })()
    }, [taxData.doNothing])

    useEffect(() => {
        ;(async () => {
            await processChange('reallocate')
            await updateTaxCalcHandlerDebounced(taxData)
        })()
    }, [taxData.reallocate])

    useEffect(() => {
        setTaxData({
            ['doNothing']: {
                ...taxData['doNothing'],
                ['stateTaxRate']: stateTaxRate,
            },
            ['reallocate']: {
                ...taxData['reallocate'],
                ['stateTaxRate']: stateTaxRate,
            },
        } as TaxCalcData)
    }, [stateTaxRate])

    const taxRateData = [
        {
            name: 'effective',
            doNothing: taxCalcData.doNothing.effectiveTaxRate,
            reallocate: taxCalcData.reallocate.effectiveTaxRate,
        },
        {
            name: 'cashflow',
            doNothing: taxCalcData.doNothing.cashFlowTaxRate,
            reallocate: taxCalcData.reallocate.cashFlowTaxRate,
        },
    ]

    const totalYears = 101 - data.incomeStartYear
    const totalNetIncome = taxData.reallocate.irs7702Distribs * totalYears

    return (
        <div>
            <h1 className="text-center text-blue text-h1-spacing">
                Income Tax Comparison
            </h1>
            <h2 className="text-center text-blue">
                Initial Qualified Plan Balance:{' '}
                {numeral(data.qualifiedPlanBalance).format('$0,0')}
            </h2>
            <h3 className="text-center text-blue">
                <i>
                    What happens when you reallocate tax‐deferred retirement
                    assets to tax‐advantaged positions?
                </i>
            </h3>
            <div
                className={
                    taxCalcStatus.isInError
                        ? 'status-bar status-bar-error'
                        : taxCalcStatus.isSaving
                        ? 'status-bar status-bar-saving'
                        : 'status-bar'
                }
            />
            <table className="ic-taxcalc-table">
                <thead>
                    <tr>
                        <th
                            rowSpan={2}
                            colSpan={2}
                            className="ic-mercury-bgnd ic-4-borders"
                        >
                            TAX CALCULATOR - Values that influence YOUR bottom
                            line Key Items on your 1040 Form
                        </th>
                        <th className="ic-very-light-gray-bgnd ic-top-border ic-right-border">
                            {data.taxCalcFutureYear}
                        </th>
                        <th className="ic-light-green-bgnd ic-top-border ic-right-border">
                            {data.taxCalcFutureYear}
                        </th>
                    </tr>
                    <tr>
                        <th className="text-bottom ic-very-light-gray-bgnd ic-bottom-border ic-right-border">
                            DO NOTHING
                        </th>
                        <th className="text-bottom ic-light-green-bgnd ic-bottom-border ic-right-border">
                            REALLOCATE
                            <br />
                            QUALIFIED
                            <br />
                            ASSETS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            1
                        </td>
                        <td className=" ic-mercury-bgnd ic-right-border">
                            Wages, salaries, tips, etc. (Forms W-2)
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.doNothing.wages1}
                                name="doNothing.wages1"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={12}
                            />
                        </td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.wages1}
                                name="reallocate.wages1"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={23}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            4b
                        </td>
                        <td className=" ic-mercury-bgnd ic-right-border">
                            IRA distributions - taxable amount
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.doNothing.iraDistrib4B}
                                name="doNothing.iraDistrib4B"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={15}
                            />
                        </td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.iraDistrib4B}
                                name="reallocate.iraDistrib4B"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={26}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            4d
                        </td>
                        <td className=" ic-mercury-bgnd ic-right-border">
                            Pensions and annuities - taxable amount
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.doNothing.pensions4D}
                                name="doNothing.pensions4D"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={16}
                            />
                        </td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.pensions4D}
                                name="reallocate.pensions4D"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={27}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            5b
                        </td>
                        <td className=" ic-mercury-bgnd ic-right-border">
                            Social Security benefits - taxable amount
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.doNothing.socialSecurity5B}
                                name="doNothing.socialSecurity5B"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={17}
                            />
                        </td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.socialSecurity5B}
                                name="reallocate.socialSecurity5B"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={28}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="ic-mercury-bgnd ic-left-border ic-right-border"></td>
                        <td className="text-blue text-bold ic-mercury-bgnd ic-right-border">
                            IRS Section 7702 Distributions (Reallocation Only)
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border"></td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.irs7702Distribs}
                                name="reallocate.irs7702Distribs"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={31}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            7b
                        </td>
                        <td className="text-bold ic-mercury-bgnd ic-right-border">
                            Total income
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.doNothing.totalIncome7B
                                ).format('0,0')
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.totalIncome7B
                                ).format('0,0')
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            8a
                        </td>
                        <td className="ic-mercury-bgnd ic-right-border">
                            Adjustments to income from Schedule 1, line 22
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.doNothing.adjustments8A}
                                name="doNothing.adjustments8A"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={20}
                            />
                        </td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.adjustments8A}
                                name="reallocate.adjustments8A"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={32}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            8b
                        </td>
                        <td className="text-bold  ic-mercury-bgnd ic-right-border">
                            Adjusted gross income
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.doNothing.adjustedIncome8B
                                ).format('0,0')
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.adjustedIncome8B
                                ).format('0,0')
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            9
                        </td>
                        <td className=" ic-mercury-bgnd ic-right-border">
                            Standard deduction or itemized deductions
                            (fromSchedule A)
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.doNothing.standardDeduc9}
                                name="doNothing.standardDeduc9"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={21}
                            />
                        </td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.standardDeduc9}
                                name="reallocate.standardDeduc9"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={33}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            10
                        </td>
                        <td className=" ic-mercury-bgnd ic-right-border">
                            Qualified business income deduction
                        </td>
                        <td className="ic-very-light-gray-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.doNothing.qualBizIncome10}
                                name="doNothing.qualBizIncome10"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={22}
                            />
                        </td>
                        <td className="ic-light-green-bgnd ic-right-border">
                            <Input
                                onChange={handleChange}
                                value={taxData.reallocate.qualBizIncome10}
                                name="reallocate.qualBizIncome10"
                                type="number"
                                size="small"
                                className="ic-taxcalc-input"
                                tabIndex={34}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            11a
                        </td>
                        <td className=" ic-mercury-bgnd ic-right-border">
                            Add lines 9 and 10
                        </td>
                        <td className="text-number ic-very-light-gray-bgnd ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.doNothing.addLines11A
                                ).format('0,0')
                            )}
                        </td>
                        <td className="text-number ic-light-green-bgnd ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.addLines11A
                                ).format('0,0')
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            11b
                        </td>
                        <td className="text-bold  ic-mercury-bgnd ic-right-border">
                            Taxable Income
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.doNothing.taxableIncome11B
                                ).format('0,0')
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.taxableIncome11B
                                ).format('0,0')
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border">
                            12a
                        </td>
                        <td className="text-bold ic-mercury-bgnd ic-right-border">
                            Federal Tax
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.doNothing.federalTax12A
                                ).format('0,0')
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.federalTax12A
                                ).format('0,0')
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-centered text-bold ic-mercury-bgnd ic-left-border ic-right-border ic-bottom-border" />
                        <td className="text-bold ic-mercury-bgnd ic-bottom-border ic-right-border">
                            State Tax
                            <Input
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setStateTaxRate(
                                        e.currentTarget.valueAsNumber
                                    )
                                }
                                value={stateTaxRate}
                                name="stateTax"
                                type="number"
                                size="small"
                                maxLength={5}
                                className="ic-state-tax-input"
                                tabIndex={35}
                            />
                            <span className="percent">%</span>
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(taxCalcData.doNothing.stateTax).format(
                                    '0,0'
                                )
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(taxCalcData.reallocate.stateTax).format(
                                    '0,0'
                                )
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={2}
                            className="text-bold ic-left-border ic-top-border ic-right-border"
                        >
                            NET INCOME
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-top-border ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(taxCalcData.doNothing.netIncome).format(
                                    '0,0'
                                )
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-top-border ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.netIncome
                                ).format('0,0')
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={2}
                            className="text-bold ic-left-border ic-right-border"
                        >
                            EFFECTIVE TAX RATE
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-right-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.doNothing.effectiveTaxRate
                                ).format('0,0.00%')
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-right-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.effectiveTaxRate
                                ).format('0,0.00%')
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={2}
                            className="text-bold ic-left-border ic-right-border ic-bottom-border"
                        >
                            CASH FLOW TAX RATE
                        </td>
                        <td className="text-number text-bold ic-very-light-gray-bgnd ic-right-border ic-bottom-border">
                            {loading.doNothing ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.doNothing.cashFlowTaxRate
                                ).format('0,0.00%')
                            )}
                        </td>
                        <td className="text-number text-bold ic-light-green-bgnd ic-right-border ic-bottom-border">
                            {loading.reallocate ? (
                                <LoadingOutlined />
                            ) : (
                                numeral(
                                    taxCalcData.reallocate.cashFlowTaxRate
                                ).format('0,0.00%')
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <h2 className="text-center text-blue">Impact on Net Income</h2>
            <div className="row text-center center-content">
                <div className="col-md-4 text-bubble-white-on-blue">
                    Before:
                    <br />
                    {numeral(taxCalcData.doNothing.netIncome).format('$0,0')}
                </div>
                <div className="col-md-4 text-bubble-white-on-blue">
                    After:
                    <br />
                    {numeral(taxCalcData.reallocate.netIncome).format('$0,0')}
                </div>
            </div>
            <div className="row text-center center-content">
                <div className="col-md-6 text-bubble-white-on-blue">
                    Net Income Difference
                    <br />
                    with Reallocation:
                    <br />
                    {numeral(
                        taxCalcData.reallocate.netIncome -
                            taxCalcData.doNothing.netIncome
                    ).format('$0,0')}
                </div>
            </div>
            <div className="row text-center center-content">
                <div className="col-md-12 text-bubble-blue-on-white">
                    Annual projected net income from reallocation:{' '}
                    {numeral(taxData.reallocate.irs7702Distribs).format('$0,0')}
                    <br />
                    Total projected net income from reallocation ({
                        totalYears
                    }{' '}
                    years): {numeral(totalNetIncome).format('$0,0')}
                    <br />
                </div>
            </div>
        </div>
    )
}
