import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
    Select,
    Row,
    List,
    Spin,
    Card,
    Button,
    Col,
    Divider,
    Pagination,
} from 'antd'
import { Link } from 'react-router-dom'
import { Agent, Client, Paged } from '../..'
import { AxiosResponse } from 'axios'
import { get } from '../../services/api'

const { Option } = Select

const CustomerWidget = () => {
    const [page, setPage] = useState(1)
    const agents = useQuery<Agent[]>(['agents'], () =>
        get('/api/v1/agents/').then(
            (res: AxiosResponse) => res.data.data as Agent[]
        )
    )

    const [selectedAgent, setSelectedAgent] = useState<string>()

    const clients = useQuery<Paged<Client>>(
        ['agents', selectedAgent, 'clients', page],
        () =>
            get(`/api/v1/agents/${selectedAgent}/clients/`, {
                params: { page: page - 1 },
            }).then((res: AxiosResponse) => res.data as Paged<Client>),
        { enabled: !!selectedAgent, keepPreviousData: true }
    )

    return (
        <Card
            title="Customers"
            style={{ minHeight: 300 }}
            extra={
                <Row>
                    <Link to={'/clients/add'}>
                        <Button
                            type="default"
                            shape="round"
                            icon="plus"
                        ></Button>
                    </Link>
                    <Divider type="vertical" />
                    {agents.isLoading ? (
                        <Spin />
                    ) : (
                        <Button
                            type="default"
                            shape="round"
                            icon="reload"
                            onClick={() => agents.refetch()}
                        />
                    )}
                </Row>
            }
        >
            <Row>
                {agents.data && (
                    <Select
                        showSearch
                        loading={agents.isLoading}
                        style={{ width: '100%' }}
                        placeholder="Select or search for an agent"
                        optionFilterProp="children"
                        onChange={setSelectedAgent}
                        filterOption={(input, option) =>
                            option.props.children
                                ? option.props.children
                                      .toString()
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                : false
                        }
                    >
                        {agents.data.map((agent) => {
                            return (
                                <Option
                                    key={agent.id.toString()}
                                    value={agent.id}
                                >
                                    {agent.agency
                                        ? `${agent.fullName} - ${agent.agency.company}`
                                        : agent.fullName}
                                </Option>
                            )
                        })}
                    </Select>
                )}
            </Row>
            <Row style={{ height: 20 }}>
                <Col
                    style={{
                        textAlign: 'center',
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    {clients.isLoading && <Spin tip="Loading..." />}
                </Col>
            </Row>
            <Row>
                {clients.data && selectedAgent && (
                    <>
                        <List
                            loading={clients.isLoading}
                            size="small"
                            dataSource={clients.data.data || []}
                            renderItem={(client) => (
                                <List.Item
                                    key={client.id.toString()}
                                    actions={[
                                        <Link to={`/clients/${client.id}`}>
                                            View
                                        </Link>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={
                                            <Link to={`/clients/${client.id}`}>
                                                {client.fullName}
                                            </Link>
                                        }
                                        description={`${client.currentAge} ${client.genderText}`}
                                    />
                                </List.Item>
                            )}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p>
                                Showing{' '}
                                {Math.min(
                                    clients.data.perPage *
                                        (clients.data.page + 1),
                                    clients.data.count
                                )}{' '}
                                of {clients.data.count}
                            </p>
                            <Pagination
                                current={clients.data.page + 1}
                                onChange={setPage}
                                pageSize={clients.data.perPage}
                                pageSizeOptions={['10', '25', '50', '100']}
                                total={clients.data.count}
                            />
                        </div>
                    </>
                )}
            </Row>
        </Card>
    )
}

export default CustomerWidget
