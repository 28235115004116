import { isEmpty } from 'lodash'
import { BucketTypePaged, BucketType, BucketDetailType } from '../index.d'

import * as api from './api'

export const bucketService = {
    getBuckets,
    getBucket,
    createBucket,
    cloneBucket,
    updateBucket,
    getBucketDetails,
    createBucketDetail,
    getBucketDetail,
    updateBucketDetail,
    deleteBucketDetail,
}

async function getBuckets(
    page: number | undefined,
    perPage: number | undefined,
    sorted: string,
    filters: string
): Promise<BucketTypePaged | undefined> {
    if (isEmpty(sorted)) {
        sorted = '-modified_on'
    }
    try {
        const response = await api.get('/api/v1/buckets/', {
            params: {
                page: page,
                per_page: perPage,
                sorted: sorted,
                filters: filters,
            },
        })
        return response.data as BucketTypePaged
    } catch (error) {
        Promise.reject(error)
    }
}

async function getBucket(bucketId: string): Promise<BucketType | undefined> {
    try {
        const response = await api.get(`/api/v1/buckets/${bucketId}/`)
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function createBucket(
    clientId: number | string
): Promise<BucketType | undefined> {
    try {
        const response = await api.post('/api/v1/buckets/', {
            customer_id: clientId,
        })
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function cloneBucket(
    bucketId: number | string
): Promise<BucketType | undefined> {
    try {
        const response = await api.post(`/api/v1/buckets/${bucketId}/`)
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function updateBucket(
    bucketId: number | string,
    payload: BucketType
): Promise<BucketType | undefined> {
    try {
        const response = await api.put(`/api/v1/buckets/${bucketId}/`, payload)
        return response.data as BucketType
    } catch (error) {
        Promise.reject(error)
    }
}

async function getBucketDetails(
    bucketId: number | string
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api.get(
            `/api/v1/buckets/${bucketId}/bucket-details/`
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}

async function createBucketDetail(
    bucketId: number | string,
    payload: BucketDetailType
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api.post(
            `/api/v1/buckets/${bucketId}/bucket-details/`,
            {
                ...payload,
            }
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}

async function getBucketDetail(
    bucketId: number | string,
    bucketDetailId: number | string
): Promise<BucketDetailType | undefined> {
    try {
        const response = await api.get(
            `/api/v1/buckets/${bucketId}/bucket-details/${bucketDetailId}/`
        )
        return response.data as BucketDetailType
    } catch (error) {
        Promise.reject(error)
    }
}
async function updateBucketDetail(
    bucketId: number | string,
    bucketDetailId: number | string,
    payload: BucketDetailType
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api.put(
            `/api/v1/buckets/${bucketId}/bucket-details/${bucketDetailId}/`,
            payload
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}

async function deleteBucketDetail(
    bucketId: number | string,
    bucketDetailId: number | string
): Promise<BucketDetailType[] | undefined> {
    try {
        const response = await api._delete(
            `/api/v1/buckets/${bucketId}/bucket-details/${bucketDetailId}/`
        )
        return response.data as BucketDetailType[]
    } catch (error) {
        Promise.reject(error)
    }
}
