/*
 * Created by Sina Khelil on 21/04/20.
 */
import { Card, Col, Row, Spin, Switch } from 'antd'
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { IncomeCompModelData, IncomeCompCreate, CustomerModel } from '../..'

import BSField from '../shared_components/bs_field'
import CancelButton from '../shared_components/route_button'

const thisYear = moment().year()

const pctValidate = yup
    .number()
    .typeError('Not a valid number, please correct')
    .min(0, 'must be greater than or equal to 0')
    .max(100, 'cannot be greater than 100')
    .required('This field is required')

const ageValidate = yup
    .number()
    .typeError('Not a valid number, please correct')
    .min(18, 'must be greater than or equal to 18')
    .max(100, 'must be less than or equal to 100')
    .required('This field is required')

const posNumValidate = yup
    .number()
    .typeError('Not a valid number, please correct')
    .min(0, 'must be greater than or equal to 0')
    .required('This field is required')

const valSchema = yup.object().shape({
    taxCalcStartYear: yup
        .number()
        .typeError('Not a valid number, please correct')
        .min(2019, 'Start year must be greater than or equal to 2019.')
        .required('This field is required'),
    taxCalcFutureYear: yup
        .number()
        .typeError('Not a valid number, please correct')
        .min(
            thisYear + 1,
            `Future year must be greater than or equal to ${thisYear + 1}`
        )
        .required('This field is required'),
    taxBracket: pctValidate,
    qualifiedPlanBalance: posNumValidate,
    growthRate: pctValidate,
    amountToReallocate: posNumValidate,
    spreadReallocYears: posNumValidate,
    startReallocYears: posNumValidate,
    incomeStartYear: pctValidate,
    annualTaxFreeIncome: posNumValidate,
    taxFreeDbAge_100: posNumValidate,
    nonQualifiedAssets: posNumValidate,
    propertyValue: posNumValidate,
    propertyValueGrowthRate: pctValidate,
    customerFilingStatus: yup
        .number()
        .oneOf([1, 2], 'please select a filing status')
        .required('This field is required'),
    estateTaxExemption: yup
        .number()
        .oneOf([1, 2], 'please select an exemption')
        .required('This field is required'),
    estateTaxRate: pctValidate,
    estateTaxExemptionAmount: posNumValidate,
    estateTaxExemptionRollbackAmount: posNumValidate,
    inheritedIraTaxRate: pctValidate,
    inheritedIraGrowthRate: pctValidate,
    growthRateType: yup
        .string()
        .oneOf(['fixed', 'historical'], 'Please select an option')
        .required('This field is required'),
    stretchIraAgeAtDeath: ageValidate,
    stretchIraSpouseAge: ageValidate,
    stretchIraSpouseAgeAtDeath: ageValidate,
    stretchIraNonSpouseBeneficiaryAge: ageValidate,
})

interface IncomeCompFormProps {
    title?: string
    data: IncomeCompModelData | IncomeCompCreate
    loading: boolean
    clients: {
        loading: boolean
        data: CustomerModel[]
    }
    submitHandler: Function
}

interface ExemptionFieldProps {
    name: string
    className: string
}

const IncomeCompForm = (props: IncomeCompFormProps) => {
    const history = useHistory()
    const { title, data, loading, submitHandler } = props

    return (
        <Card
            title={title || 'Income Comparison Form'}
            style={{ minHeight: 300 }}
        >
            <Formik
                validationSchema={valSchema}
                initialValues={{
                    customer: data.customer,
                    customerId: data.customer!.id,
                    customerAge: data.customerAge || data.customer!.age,
                    customerFilingStatus:
                        data.customerFilingStatus ||
                        data.customer!.filingStatus,
                    taxCalcStartYear: data.taxCalcStartYear,
                    taxCalcFutureYear: data.taxCalcFutureYear,
                    taxBracket: data.taxBracket,
                    qualifiedPlanBalance:
                        data.qualifiedPlanBalance ||
                        data.customer!.qualifiedPlanBalance,
                    growthRate: data.growthRate,
                    growthRateType: data.growthRateType,
                    amountToReallocate: data.amountToReallocate,
                    spreadReallocYears: data.spreadReallocYears,
                    startReallocYears: data.startReallocYears,
                    incomeStartYear: data.incomeStartYear,
                    annualTaxFreeIncome: data.annualTaxFreeIncome,
                    taxFreeDbAge_100: data.taxFreeDbAge100,
                    showReinvestedTaxFreeLoan: data.showReinvestedTaxFreeLoan,
                    nonQualifiedAssets: data.nonQualifiedAssets,
                    propertyValue: data.propertyValue,
                    propertyValueGrowthRate: data.propertyValueGrowthRate,
                    estateTaxExemption:
                        data.estateTaxExemption || data.customer!.filingStatus,
                    estateTaxRate: data.estateTaxRate,
                    estateTaxExemptionAmount: data.estateTaxExemptionAmount,
                    estateTaxExemptionRollbackAmount:
                        data.estateTaxExemptionRollbackAmount,
                    inheritedIraTaxRate: data.inheritedIraTaxRate,
                    inheritedIraGrowthRate: data.inheritedIraGrowthRate,
                    stretchIraAgeAtDeath: data.stretchIraAgeAtDeath,
                    stretchIraSpouseAge: data.stretchIraSpouseAge,
                    stretchIraSpouseAgeAtDeath: data.stretchIraSpouseAgeAtDeath,
                    stretchIraNonSpouseBeneficiaryAge:
                        data.stretchIraNonSpouseBeneficiaryAge,
                }}
                onSubmit={(values: FormikValues, actions) => {
                    actions.validateForm().then(() => {
                        submitHandler({ ...values, history })
                        actions.setSubmitting(false)
                    })
                }}
            >
                {({ values, touched, errors }) => {
                    return (
                        <Form>
                            <Row gutter={20}>
                                <Col span={6}>
                                    <div className="form-fields-header">
                                        TAX CALCULATOR INPUT
                                    </div>
                                    <label
                                        className="control-label"
                                        htmlFor="selectedClient"
                                    >
                                        Client
                                    </label>
                                    <div>{values.customer!.name}</div>
                                    <BSField
                                        touched={touched.customerAge}
                                        error={errors.customerAge}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="customerAge"
                                        >
                                            Age
                                        </label>
                                        <Field
                                            name="customerAge"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.customerFilingStatus}
                                        error={errors.customerFilingStatus}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="customerFilingStatus"
                                        >
                                            Filing Status
                                        </label>
                                        <Field
                                            name="customerFilingStatus"
                                            className="form-control"
                                            component="select"
                                        >
                                            {!values.estateTaxExemption && (
                                                <option key={0} value="0">
                                                    ---
                                                </option>
                                            )}
                                            <option key={1} value={1}>
                                                Single
                                            </option>
                                            <option key={2} value={2}>
                                                Married / Joint
                                            </option>
                                        </Field>
                                    </BSField>
                                    <BSField
                                        touched={touched.taxCalcStartYear}
                                        error={errors.taxCalcStartYear}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="taxCalcStartYear"
                                        >
                                            Tax Calc Start Year
                                        </label>
                                        <Field
                                            name="taxCalcStartYear"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.taxCalcFutureYear}
                                        error={errors.taxCalcFutureYear}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="taxCalcFutureYear"
                                        >
                                            Tax Calc Future Year
                                        </label>
                                        <Field
                                            name="taxCalcFutureYear"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                </Col>
                                <Col span={6}>
                                    <div className="form-fields-header">
                                        COST BENEFIT / RMD INPUT
                                    </div>
                                    <BSField
                                        className="input-percent"
                                        touched={touched.taxBracket}
                                        error={errors.taxBracket}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="taxBracket"
                                        >
                                            Tax Bracket
                                        </label>
                                        <Field
                                            name="taxBracket"
                                            className="form-control input-num-percent"
                                            maxLength={5}
                                        />
                                        <span className="percent">%</span>
                                    </BSField>
                                    <BSField
                                        touched={touched.qualifiedPlanBalance}
                                        error={errors.qualifiedPlanBalance}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="qualifiedPlanBalance"
                                        >
                                            Qualified Plan Balance
                                        </label>
                                        <Field
                                            name="qualifiedPlanBalance"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        className="input-percent"
                                        touched={touched.growthRateType}
                                        error={errors.growthRateType}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="growthRateType"
                                        >
                                            Growth Rate Type
                                        </label>
                                        <Field
                                            name="growthRateType"
                                            className="form-control"
                                            component="select"
                                        >
                                            {!values.growthRateType && (
                                                <option key={0} value="0">
                                                    ---
                                                </option>
                                            )}
                                            <option key={1} value="fixed">
                                                Fixed
                                            </option>
                                            <option key={2} value="historical">
                                                Historical
                                            </option>
                                        </Field>
                                    </BSField>
                                    <BSField
                                        className="input-percent"
                                        touched={touched.growthRate}
                                        error={errors.growthRate}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="growthRate"
                                        >
                                            Growth Rate
                                        </label>
                                        <Field
                                            name="growthRate"
                                            className="form-control input-num-percent"
                                            maxLength={5}
                                            disabled={
                                                values.growthRateType ===
                                                'historical'
                                            }
                                        />
                                        <span className="percent">%</span>
                                    </BSField>
                                    <BSField
                                        touched={touched.amountToReallocate}
                                        error={errors.amountToReallocate}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="amountToReallocate"
                                        >
                                            Total Amount to Reallocate
                                        </label>
                                        <Field
                                            name="amountToReallocate"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.spreadReallocYears}
                                        error={errors.spreadReallocYears}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="spreadReallocYears"
                                        >
                                            Spread Reallocation Years
                                        </label>
                                        <Field
                                            name="spreadReallocYears"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.startReallocYears}
                                        error={errors.startReallocYears}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="startReallocYears"
                                        >
                                            Start Reallocation in Year
                                        </label>
                                        <Field
                                            name="startReallocYears"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.incomeStartYear}
                                        error={errors.incomeStartYear}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="incomeStartYear"
                                        >
                                            Income Start Age
                                        </label>
                                        <Field
                                            name="incomeStartYear"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.annualTaxFreeIncome}
                                        error={errors.annualTaxFreeIncome}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="annualTaxFreeIncome"
                                        >
                                            Annual Tax Free Income
                                        </label>
                                        <Field
                                            name="annualTaxFreeIncome"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.taxFreeDbAge_100}
                                        error={errors.taxFreeDbAge_100}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="taxFreeDbAge_100"
                                        >
                                            Tax Free DB Age 100
                                        </label>
                                        <Field
                                            name="taxFreeDbAge_100"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={
                                            touched.showReinvestedTaxFreeLoan
                                        }
                                        error={errors.showReinvestedTaxFreeLoan}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="showReinvestedTaxFreeLoan"
                                        >
                                            Show Reinvested Tax Free Loan
                                        </label>
                                        <Field
                                            name="showReinvestedTaxFreeLoan"
                                            className="form-control"
                                        >
                                            {({
                                                field, // { name, value, onChange, onBlur }
                                            }: FieldProps) => (
                                                <Switch
                                                    className="form-control"
                                                    checked={
                                                        values.showReinvestedTaxFreeLoan
                                                    }
                                                    {...field}
                                                    onChange={(checked) =>
                                                        field.onChange({
                                                            target: {
                                                                name: 'showReinvestedTaxFreeLoan',
                                                                value: checked,
                                                            },
                                                        })
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </BSField>
                                </Col>
                                <Col span={6}>
                                    <div className="form-fields-header">
                                        ESTATE TAX INPUT
                                    </div>
                                    <BSField
                                        touched={touched.nonQualifiedAssets}
                                        error={errors.nonQualifiedAssets}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="nonQualifiedAssets"
                                        >
                                            Non Qualified Assets
                                        </label>
                                        <Field
                                            name="nonQualifiedAssets"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.propertyValue}
                                        error={errors.propertyValue}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="propertyValue"
                                        >
                                            Property Value
                                        </label>
                                        <Field
                                            name="propertyValue"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        className="input-percent"
                                        touched={
                                            touched.propertyValueGrowthRate
                                        }
                                        error={errors.propertyValueGrowthRate}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="propertyValueGrowthRate"
                                        >
                                            Property Value Growth Rate
                                        </label>
                                        <Field
                                            name="propertyValueGrowthRate"
                                            className="form-control input-num-percent"
                                            maxLength={5}
                                        />
                                        <span className="percent">%</span>
                                    </BSField>
                                    <BSField
                                        touched={touched.estateTaxExemption}
                                        error={errors.estateTaxExemption}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="estateTaxExemption"
                                        >
                                            Estate Tax Exemption
                                        </label>
                                        <Field
                                            name="estateTaxExemption"
                                            className="form-control"
                                            component="select"
                                        >
                                            {!values.estateTaxExemption && (
                                                <option key={0} value="0">
                                                    ---
                                                </option>
                                            )}
                                            <option key={1} value={1}>
                                                Single
                                            </option>
                                            <option key={2} value={2}>
                                                Joint
                                            </option>
                                        </Field>
                                    </BSField>
                                    <BSField
                                        className="input-percent"
                                        touched={touched.estateTaxRate}
                                        error={errors.estateTaxRate}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="estateTaxRate"
                                        >
                                            Estate Tax Rate
                                        </label>
                                        <Field
                                            name="estateTaxRate"
                                            className="form-control input-num-percent"
                                            maxLength={5}
                                        />
                                        <span className="percent">%</span>
                                    </BSField>
                                    <BSField
                                        touched={
                                            touched.estateTaxExemptionAmount
                                        }
                                        error={errors.estateTaxExemptionAmount}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="estateTaxExemptionAmount"
                                        >
                                            Estate Tax Exemption{' '}
                                            {String(
                                                values.estateTaxExemption
                                            ) === '1'
                                                ? 'Single'
                                                : String(
                                                      values.estateTaxExemption
                                                  ) === '2'
                                                ? 'Married / Joint'
                                                : ''}
                                        </label>
                                        <Field
                                            name="estateTaxExemptionAmount"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={
                                            touched.estateTaxExemptionRollbackAmount
                                        }
                                        error={
                                            errors.estateTaxExemptionRollbackAmount
                                        }
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="estateTaxExemptionRollbackAmount"
                                        >
                                            Estate Tax Exemption Rollback{' '}
                                            {String(
                                                values.estateTaxExemption
                                            ) === '1'
                                                ? 'Single'
                                                : String(
                                                      values.estateTaxExemption
                                                  ) === '2'
                                                ? 'Married / Joint'
                                                : ''}
                                        </label>
                                        <Field
                                            name="estateTaxExemptionRollbackAmount"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                </Col>
                                <Col span={6}>
                                    <div className="form-fields-header">
                                        INHERITED IRA INPUT
                                    </div>
                                    <BSField
                                        className="input-percent"
                                        touched={touched.inheritedIraTaxRate}
                                        error={errors.inheritedIraTaxRate}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="inheritedIraTaxRate"
                                        >
                                            Inherited IRA Tax Rate
                                        </label>
                                        <Field
                                            name="inheritedIraTaxRate"
                                            className="form-control input-num-percent"
                                            maxLength={5}
                                        />
                                        <span className="percent">%</span>
                                    </BSField>
                                    <BSField
                                        className="input-percent"
                                        touched={touched.inheritedIraGrowthRate}
                                        error={errors.inheritedIraGrowthRate}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="inheritedIraGrowthRate"
                                        >
                                            Inherited IRA Growth Rate
                                        </label>
                                        <Field
                                            name="inheritedIraGrowthRate"
                                            className="form-control input-num-percent"
                                            maxLength={5}
                                        />
                                        <span className="percent">%</span>
                                    </BSField>
                                    <BSField
                                        touched={touched.stretchIraAgeAtDeath}
                                        error={errors.stretchIraAgeAtDeath}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="stretchIraAgeAtDeath"
                                        >
                                            Stretch IRA Age at Death
                                        </label>
                                        <Field
                                            name="stretchIraAgeAtDeath"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={touched.stretchIraSpouseAge}
                                        error={errors.stretchIraSpouseAge}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="stretchIraSpouseAge"
                                        >
                                            Stretch IRA Spouse Age
                                        </label>
                                        <Field
                                            name="stretchIraSpouseAge"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={
                                            touched.stretchIraSpouseAgeAtDeath
                                        }
                                        error={
                                            errors.stretchIraSpouseAgeAtDeath
                                        }
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="stretchIraSpouseAgeAtDeath"
                                        >
                                            Stretch IRA Spouse Age at Death
                                        </label>
                                        <Field
                                            name="stretchIraSpouseAgeAtDeath"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                    <BSField
                                        touched={
                                            touched.stretchIraNonSpouseBeneficiaryAge
                                        }
                                        error={
                                            errors.stretchIraNonSpouseBeneficiaryAge
                                        }
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="stretchIraNonSpouseBeneficiaryAge"
                                        >
                                            Stretch IRA Non-Spouse Beneficiary
                                            Age
                                        </label>
                                        <Field
                                            name="stretchIraNonSpouseBeneficiaryAge"
                                            className="form-control input-num"
                                        />
                                    </BSField>
                                </Col>
                            </Row>
                            <div className="form-group">
                                <CancelButton
                                    path="/income-comps"
                                    name="Cancel"
                                    className="btn btn-danger"
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary pull-right"
                                    disabled={loading}
                                >
                                    <Spin spinning={loading} />
                                    Save
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Card>
    )
}

export default IncomeCompForm
