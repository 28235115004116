import React from 'react'
import numeral from 'numeral'
import { Card } from 'antd'

const getIndexValue = (indexValues, idx) => {
    const indexValuesCount = Object.keys(indexValues).length
    const indexValuesIdx =
        idx >= indexValuesCount
            ? idx - indexValuesCount * Math.floor(idx / indexValuesCount)
            : idx
    return parseFloat(indexValues[indexValuesIdx]) || 0
}

const VsAssetLongevityRoth = (props) => {
    const { proposal, marketValues, roth, iraRoi, illustration } = props
    return (
        <div className="center">
            <Card
                className="container800"
                title={`Asset Longevity Comparison - Roth`}
                bordered={false}
                headStyle={{
                    color: '#003e80',
                    fontWeight: 'bold',
                    fontSize: 24,
                    textAlign: 'center',
                    borderBottomColor: '#003e80',
                }}
            >
                <div className="center">
                    <table className="table table-800 table-hover table-noborder">
                        <thead>
                            <tr>
                                <th colSpan="5">
                                    <i>Roth Account</i>
                                </th>
                                <th className="table-th-space">&nbsp;</th>
                                <th colSpan="5">
                                    <i>Alternative Tax Free</i>
                                </th>
                            </tr>
                            <tr>
                                <th className="table-th-dk-gray table-th-bordertopleft">
                                    Age
                                </th>
                                {!proposal.illustration && (
                                    <th className="table-th-dk-gray">Year</th>
                                )}
                                {proposal.illustration && (
                                    <th className="table-th-dk-gray table-th-bordertop">
                                        Market Returns
                                    </th>
                                )}
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Roth Deposits /
                                    <br />
                                    Withdrawals
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    {proposal.illustration && (
                                        <span>Crediting</span>
                                    )}
                                    {!proposal.illustration && (
                                        <span>{iraRoi}</span>
                                    )}
                                </th>
                                <th className="table-th-dk-gray table-th-bordertop">
                                    Account Value
                                </th>
                                <th className="table-th-space table-th-bordertopright">
                                    &nbsp;
                                </th>
                                {proposal.illustration && (
                                    <th className="table-th-blue table-th-bordertop">
                                        Index Returns
                                    </th>
                                )}
                                {proposal.illustration && (
                                    <th className="table-th-blue table-th-bordertop">
                                        Crediting
                                    </th>
                                )}
                                <th className="table-th-blue table-th-bordertop">
                                    Tax Free
                                    <br />
                                    Income
                                </th>
                                {!proposal.illustration && (
                                    <th className="table-th-blue table-th-bordertop">
                                        Accumulation
                                    </th>
                                )}
                                <th className="table-th-blue table-th-bordertop">
                                    Cash Value*
                                </th>
                                <th className="table-th-blue table-th-bordertopright">
                                    Death Benefit
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {roth.rows.map((dataRow, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="currency table-td-borderleft">
                                            {dataRow.age}
                                        </td>
                                        {!proposal.illustration && (
                                            <td className="currency">
                                                {dataRow.year}
                                            </td>
                                        )}
                                        {proposal.illustration && (
                                            <td className="currency">
                                                {numeral(
                                                    getIndexValue(
                                                        marketValues,
                                                        index
                                                    ) / 100
                                                ).format('0,0.00%')}
                                            </td>
                                        )}
                                        <td className="currency">
                                            {dataRow.transaction}
                                        </td>
                                        <td className="currency">
                                            {proposal.illustration && (
                                                <span>
                                                    {dataRow.illInterest}
                                                </span>
                                            )}
                                            {!proposal.illustration && (
                                                <span>{dataRow.interest}</span>
                                            )}
                                        </td>
                                        <td className="currency">
                                            {proposal.illustration && (
                                                <span>
                                                    {dataRow.illAccountValue}
                                                </span>
                                            )}
                                            {!proposal.illustration && (
                                                <span>
                                                    {dataRow.accountValue}
                                                </span>
                                            )}
                                        </td>
                                        <td className="currency table-td-borderright">
                                            &nbsp;
                                        </td>
                                        {proposal.illustration && (
                                            <td className="currency">
                                                {dataRow.atfIndexReturns}
                                            </td>
                                        )}
                                        {proposal.illustration && (
                                            <td className="currency">
                                                {dataRow.atfInterest}
                                            </td>
                                        )}
                                        <td className="currency">
                                            {dataRow.withdraw}
                                        </td>
                                        {!proposal.illustration && (
                                            <td className="currency">
                                                {dataRow.accumulation}
                                            </td>
                                        )}
                                        <td className="currency">
                                            {dataRow.surrender}
                                        </td>
                                        <td className="currency table-td-borderright">
                                            {dataRow.familyBenefit}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="3" className="currency">
                                    Total Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    {numeral(roth.iraTotalDist).format('$0,0')}
                                </td>
                                <td colSpan="4" className="currency">
                                    Total Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    {numeral(roth.tfpTotalDist).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="currency">
                                    Total Taxes:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    {numeral(roth.iraTotalTax).format('$0,0')}
                                </td>
                                <td colSpan="4" className="currency">
                                    Total Taxes:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    {numeral(roth.tfpTotalTax).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="currency">
                                    Net Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    {numeral(roth.iraNetDist2).format('$0,0')}
                                </td>
                                <td colSpan="4" className="currency">
                                    Net Distribution:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    {numeral(roth.tfpTotalDist).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="currency">
                                    Family Benefit:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-dk-gray currency bold"
                                >
                                    $0
                                </td>
                                <td colSpan="4" className="currency">
                                    Family Benefit:
                                </td>
                                <td
                                    colSpan="2"
                                    className="table-th-blue currency bold"
                                >
                                    {numeral(roth.tfpFamBeni).format('$0,0')}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </Card>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="disclaimer-800">
                    <p>
                        Market returns assume {illustration.marketRateName}{' '}
                        Annual Returns Years 2001-2018 plus 1.5% management fee,
                        excluding dividends
                    </p>
                    <p>
                        Index returns assume{' '}
                        {illustration.marketIndexDesc.trim()}.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VsAssetLongevityRoth
