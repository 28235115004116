import React, { useState, FC } from 'react'
import { useQuery } from 'react-query'
import { bucketService } from '../../services/bucket.service'
import { BucketType, BucketTypePaged } from '../..'
import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table'
import BucketList from '../../components/bucket/list'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

const BucketsPage: FC = () => {
    const [page, setPage] = useState<number | undefined>(0)
    const [perPage, setPerPage] = useState<number | undefined>(25)
    const [filter, setFilter] = useState<string>('')
    const [order, setOrder] = useState<string>('-modified_on')

    const buckets = useQuery<BucketTypePaged | undefined, AxiosError>(
        ['buckets'],
        () => bucketService.getBuckets(page, perPage, order, filter),
        {
            onError: (error: AxiosError) => {
                toast.error(
                    `An error occured while loading buckets: ${error.message}`
                )
            },
        }
    )

    const handleTableChange = (
        pagination: PaginationConfig,
        filters: Partial<Record<keyof BucketType, string[]>>,
        sorter: SorterResult<BucketType>
    ) => {
        let sorted: string = '-modified_on'
        if (sorter.columnKey !== undefined)
            sorted = `${sorter.order === 'descend' ? '-' : ''}${
                sorter.columnKey
            }`

        setPage((pagination.current || 1) - 1)
        setPerPage(pagination.pageSize)
        setOrder(sorted)
    }

    if (!buckets.data) {
        return null
    }

    return (
        <BucketList
            data={buckets.data.data}
            loading={buckets.isLoading}
            page={buckets.data.page}
            count={buckets.data.count}
            handleTableChange={handleTableChange}
        />
    )
}

export default BucketsPage
