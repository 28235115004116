import { Card, Col, Row } from 'antd'
import numeral from 'numeral'
import React from 'react'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { IncomeCompModelData } from '../..'

import ArrowLine from '../shared_components/arrow-line'

interface ICStretchIraProps {
    data: IncomeCompModelData
}

export const ICStretchIra = (props: ICStretchIraProps) => {
    const { data } = props

    let stretchTaxData: { year: string; taxAmount: number }[] = []
    let secureTaxData: { year: string; taxAmount: number }[] = []
    let maxTax = 0
    let minOtherYear = 0
    data.stretchResult.baseCalculations.forEach((baseCalculation) => {
        if (baseCalculation.secure.taxAmount > 0) {
            if (minOtherYear === 0) minOtherYear = baseCalculation.year - 1
            stretchTaxData.push({
                year: baseCalculation.year.toString(),
                taxAmount: baseCalculation.stretch.taxAmount,
            })
            maxTax = Math.max(
                maxTax,
                baseCalculation.stretch.taxAmount,
                baseCalculation.secure.taxAmount
            )
            secureTaxData.push({
                year: baseCalculation.year.toString(),
                taxAmount: baseCalculation.secure.taxAmount,
            })
        }
    })

    // try to normalize the top end number
    maxTax = (Math.trunc(maxTax / 50000) + 1) * 50000
    const securePadding =
        ((data.stretchResult.secure.otherStartYear || minOtherYear) -
            minOtherYear) *
            20 +
        80

    return (
        <div>
            <h1 className="text-blue text-center text-h1-spacing">
                Stretch IRA vs. SECURE Act
            </h1>
            <h2 className="text-blue text-center">
                Existing IRA Account (No Reallocation)
            </h2>
            <Row style={{ marginTop: 20 }}>
                <Col span={12}>
                    <span className="text-bold">Before ‐ Stretch IRA</span>
                    <br />
                    IRA passed to spouse, then to heirs
                    <br />
                    Distributed according to life expectancy factors
                </Col>
                <Col span={12}>
                    <span className="text-bold">
                        After ‐ 10‐Year Distribution
                    </span>
                    <br />
                    Non‐spousal heir must distribute entire IRA balance in just
                    10 years
                    <br />
                    No rules for distribution method
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <Col span={6}></Col>
                <Col span={12}>
                    <span className="text-bold">Impact</span>
                    <br />
                    Stretch IRA allowed for distribution over multiple lifetimes
                    <br />
                    Lower and extended distributions = lower taxes
                    <br />
                    SECURE Act 10‐year distribution = potential massive income
                    tax hit
                </Col>
                <Col span={6}></Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Card
                        title="Income Tax Comparison"
                        style={{ width: 640, marginTop: 20 }}
                    >
                        <div style={{ paddingLeft: 80 }}>
                            <ArrowLine width={600 - 110} />
                        </div>
                        <BarChart
                            width={600}
                            height={300}
                            data={stretchTaxData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis
                                type="category"
                                minTickGap={1}
                                dataKey="year"
                                interval={0}
                            />
                            <YAxis
                                type="number"
                                dataKey="taxAmount"
                                scale="linear"
                                tickFormatter={(value) =>
                                    numeral(value).format('0,0')
                                }
                                domain={[0, maxTax]}
                            />
                            <Tooltip
                                formatter={(value: number) =>
                                    numeral(value).format('0,0')
                                }
                            />
                            <Legend />
                            <Bar
                                dataKey="taxAmount"
                                fill="#2f5597"
                                name="Stretch IRA Tax"
                            />
                        </BarChart>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title="Income Tax Comparison"
                        style={{ width: 640, marginTop: 20 }}
                    >
                        <div style={{ paddingLeft: securePadding }}>
                            <ArrowLine
                                width={600 - (securePadding + 30)}
                                color="#ffc000"
                            />
                        </div>
                        <BarChart
                            width={600}
                            height={300}
                            data={secureTaxData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis
                                type="category"
                                dataKey="year"
                                interval={0}
                            />
                            <YAxis
                                type="number"
                                dataKey="taxAmount"
                                scale="linear"
                                tickFormatter={(value) =>
                                    numeral(value).format('0,0')
                                }
                                domain={[0, maxTax]}
                            />
                            <Tooltip
                                formatter={(value: number) =>
                                    numeral(value).format('0,0')
                                }
                            />
                            <Legend />
                            <Bar
                                dataKey="taxAmount"
                                fill="#ff0000"
                                name="SECURE ACT Tax"
                            />
                        </BarChart>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col span={6}></Col>
                <Col span={12}>
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    <b>Assumptions:</b>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: 200 }}>Owner age:</td>
                                <td>{data.customerAge}</td>
                                <td style={{ width: 200 }}>Age at death:</td>
                                <td>{data.stretchIraAgeAtDeath}</td>
                            </tr>
                            <tr>
                                <td>Spouse age:</td>
                                <td>{data.stretchIraSpouseAge}</td>
                                <td>Age at death:</td>
                                <td>{data.stretchIraSpouseAgeAtDeath}</td>
                            </tr>
                            <tr>
                                <td>Beneficiary age:</td>
                                <td>
                                    {data.stretchIraNonSpouseBeneficiaryAge}
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col span={6}></Col>
            </Row>
        </div>
    )
}
