import React from 'react'
import { Link, useParams } from 'react-router-dom'

import ListClients from '../shared_components/list_clients'
import ListProposals from '../shared_components/list_proposals'
import LoadingField from '../shared_components/loading_field'

const AgentView = (props) => {
    const {
        data,
        loading,
        error,
        customers,
        proposals,
        customersPageChangeHandler,
        proposalsPageChangeHandler,
    } = props

    const { agencyId } = useParams()

    if (error) {
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <Link to={`/agencies/${agencyId}`} className="pull-left">
                        &lt; Agency
                    </Link>
                </div>
                <div className="panel-body">{error}</div>
            </div>
        )
    }
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <Link to={`/agencies/${agencyId}`} className="pull-left">
                    &lt; Agency
                </Link>
                <h3 className="panel-title text-center">
                    {!loading && data.fullName}
                    {loading && <LoadingField width={60} />}
                </h3>
            </div>
            <div className="panel-body">
                <table className="table table-condensed">
                    <tbody>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Fax</th>
                        </tr>
                        <tr>
                            <td>
                                {!loading && data.firstName}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td>
                                {!loading && data.lastName}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td>
                                {!loading && data.email}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td>
                                {!loading && data.phone}
                                {loading && <LoadingField width={60} />}
                            </td>
                            <td>
                                {!loading && data.fax}
                                {loading && <LoadingField width={60} />}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="panel-heading">
                <h4 className="panel-title text-center">Clients</h4>
            </div>
            <div className="panel-body">
                <ListClients
                    data={customers.data}
                    page={customers.page}
                    loading={customers.loading}
                    perPage={customers.perPage}
                    sorted={customers.sorted}
                    count={customers.count}
                    pageChangeHandler={customersPageChangeHandler}
                />
            </div>
            <div className="panel-heading">
                <h4 className="panel-title text-center">Proposals</h4>
            </div>
            <div className="panel-body">
                <ListProposals
                    data={proposals.data}
                    loading={proposals.loading}
                    perPage={proposals.perPage}
                    count={proposals.count}
                    minRows={0}
                    page={proposals.page}
                    sorted={proposals.sorted}
                    pageChangeHandler={proposalsPageChangeHandler}
                />
            </div>
        </div>
    )
}

export default AgentView
