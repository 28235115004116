import { Card, Spin } from 'antd'
import React from 'react'

interface LoadingProps {
    title: string
}

function Loading(props: LoadingProps) {
    const { title } = props

    return (
        <Card title={title || "Income Comparison Form"} style={{ minHeight: 300 }}>
            <div style={{textAlign: 'center'}}><Spin size='large' /> Loading...</div>
        </Card>
    )
}

export default Loading
