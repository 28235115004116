import moment from 'moment'
import {
    CalculatedTaxCalcData,
    IncomeCompCreate,
    TaxCalcData,
    BucketType,
    BucketNeedEnum,
} from '../../index.d'

const thisYear = moment().year()

// THIS HAS TO MATCH THE BACKEND CONSTANTS
export const DISTRIBUTION_OPTIONS = [
    { value: 'level', name: 'Level' },
    { value: 'lump', name: 'Lump Sum Year 10' },
    { value: 'decr', name: 'Decreasing' },
    { value: 'incr', name: 'Increasing' },
]

export const TAX_CALC_DATA: TaxCalcData = {
    doNothing: {
        year: 2024,
        wages1: 0,
        taxableInterest2B: 1000,
        ordinaryDividends3B: 11375,
        iraDistrib4B: 397028,
        pensions4D: 100000,
        socialSecurity5B: 0,
        capitalGainLoss6: 0,
        otherIncome7A: 162989,
        irs7702Distribs: 0,
        adjustments8A: 7829,
        standardDeduc9: 26600,
        qualBizIncome10: 3061,
        stateTaxRate: 10,
    },
    reallocate: {
        year: 2024,
        wages1: 0,
        taxableInterest2B: 1000,
        ordinaryDividends3B: 11375,
        iraDistrib4B: 179278,
        pensions4D: 100000,
        socialSecurity5B: 0,
        capitalGainLoss6: 0,
        otherIncome7A: 162989,
        irs7702Distribs: 190000,
        adjustments8A: 7829,
        standardDeduc9: 26600,
        qualBizIncome10: 3061,
        stateTaxRate: 10,
    },
}

export const CALCULATED_TAX_CALC_DATA: CalculatedTaxCalcData = {
    doNothing: {
        totalIncome7B: 751982,
        adjustedIncome8B: 744153,
        addLines11A: 29661,
        taxableIncome11B: 714492,
        federalTax12A: 38968,
        stateTax: 0,
        netIncome: 0,
        effectiveTaxRate: 0.1848,
        cashFlowTaxRate: 0.1534,
        preTaxEquivalentTotalIncome: 0,
        afterTaxIncome: 0,
    },
    reallocate: {
        totalIncome7B: 724233,
        adjustedIncome8B: 526404,
        addLines11A: 29661,
        taxableIncome11B: 496743,
        federalTax12A: 38968,
        stateTax: 0,
        netIncome: 0,
        effectiveTaxRate: 0.1848,
        cashFlowTaxRate: 0.1534,
        preTaxEquivalentTotalIncome: 0,
        afterTaxIncome: 0,
    },
}

export const BASE_BUCKET_DATA: BucketType = {
    id: 0,
    customer: {
        id: 0,
        firstName: '',
        lastName: '',
        fullName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        postal: '',
        phone: '',
        fax: '',
        email: '',
        currentAge: 0,
        agent: 0,
        filingStatus: 1,
        filingStatusText: 'Single',
        gender: 0,
        genderText: 'Male',
        createdOn: '',
        loading: false,
        selectedAgent: { value: 0, label: '' },
        spouseAge: 0,
        spouseFirstName: '',
        spouseLastName: '',
        spouseFullName: '',
    },
    assumedInflation: 0,
    returnedFunds: 0,
    returnedInvest: 0,
    returnedDeferral: 0,
    baseRateOfReturn: 0,
    needType: BucketNeedEnum.Income,
    needAssetValue: 0,
    needMonthlyIncome: 0,
    socialSecurityIncome: 0,
    socialSecurityIncomeDeduct: false,
    pensionIncome: 0,
    pensionIncomeDeduct: false,
    employmentIncome: 0,
    employmentIncomeDeduct: false,
    qualifiedPlanIncome: 0,
    qualifiedPlanIncomeDeduct: false,
    savingsInvestmentsIncome: 0,
    savingsInvestmentsIncomeDeduct: false,
    definedContributionPlanIncome: 0,
    definedContributionPlanIncomeDeduct: false,
    createdOn: new Date(),
    modifiedOn: new Date(),
    proposalDate: new Date(),
    isActive: true,
    buckets: [],
}

export const BASE_IC_DATA: IncomeCompCreate = {
    amountToReallocate: 0,
    annualTaxFreeIncome: 0,
    customer: undefined,
    customerId: 0,
    customerAge: 0,
    customerFilingStatus: 1,
    distributionOption: '',
    estateTaxExemption: 1,
    estateTaxExemptionAmount: 0,
    estateTaxExemptionRollbackAmount: 0,
    estateTaxRate: 40,
    growthRate: 6,
    growthRateType: 'fixed',
    incomeStartYear: 0,
    inheritedIraTaxRate: 30,
    inheritedIraGrowthRate: 6,
    nonQualifiedAssets: 0,
    propertyValue: 0,
    propertyValueGrowthRate: 2,
    qualifiedPlanBalance: 0,
    showReinvestedTaxFreeLoan: false,
    spreadReallocYears: 3,
    startReallocYears: 2,
    stretchIraAgeAtDeath: 90,
    stretchIraNonSpouseBeneficiaryAge: 90,
    stretchIraSpouseAge: 70,
    stretchIraSpouseAgeAtDeath: 90,
    taxBracket: 45,
    taxCalcFutureYear: thisYear + 5,
    taxCalcStartYear: thisYear,
    taxFreeDbAge100: 0,
}
