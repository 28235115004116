import React from "react";
import AgencyForm from "./form";

const AgencyAdd = props => {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">Agency - Add</h3>
      </div>
      <div className="panel-body">
        <AgencyForm {...props} />
      </div>
    </div>
  );
};

export default AgencyAdd;
