/*
 * Created by Sina Khelil on 2/1/16.
 */

import React from "react";
import ReactTable from "react-table";
import TableRowActions from "../shared_components/table-row-actions";

export default class AgentList extends React.Component {
  render() {
    const columns = [
      { Header: "Name", id: "lastName", accessor: "fullName" },
      { Header: "Phone", sortable: false, accessor: "phone" },
      { Header: "Fax", sortable: false, accessor: "fax" },
      { Header: "Email", sortable: false, accessor: "email" },
      {
        Header: "Actions",
        Cell: row => (
          <TableRowActions
            viewLink={`/agencies/${this.props.agencyId}/agents/${row.original.id}`}
            editLink={`/agencies/${this.props.agencyId}/agents/edit/${row.original.id}`}
          />
        ),
        sortable: false
      }
    ];

    const { agents, pageChangeHandler } = this.props;
    if (agents.error) {
      return <div className="has-error text-danger">Oops, looks like something went wrong. {agents.error}</div>;
    } else {
      return (
        <ReactTable
          manual
          data={agents.data}
          loading={agents.loading}
          pages={Math.ceil(agents.count / agents.perPage)}
          pageSizeOptions={[25, 50, 100]}
          defaultPageSize={25}
          minRows={0}
          sorted={agents.sorted}
          columns={columns}
          onFetchData={pageChangeHandler}
          multiSort={false}
          className="-striped -highlight"
        />
      );
    }
  }
}
