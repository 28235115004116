import React from 'react'
import sumBy from 'lodash/sumBy'
import numeral from 'numeral'
import { BucketDetailType, BucketType } from '../../../../index.d'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { bucketService } from '../../../../services/bucket.service'
import { toast } from 'react-toastify'

interface BucketSummaryProps {
    bucket: BucketType
}

const BucketSummary = (props: BucketSummaryProps) => {
    const { bucket } = props

    const bucketDetails = useQuery<BucketDetailType[] | undefined, AxiosError>(
        ['buckets', bucket.id.toString(), 'details'],
        () => bucketService.getBucketDetails(bucket.id.toString()),
        {
            onError: (error: AxiosError) => {
                toast.error(
                    `An error occured while loading bucket details: ${error.message}`
                )
            },
        }
    )

    if (!bucketDetails.data) {
        return null
    }

    const totalRequired = numeral(
        sumBy(bucketDetails.data, 'startingValue') + bucket.returnedInvest
    ).format('$0,0')
    const totalReturned = numeral(bucket.returnedFunds).format('$0,0')
    const yearsOfIncome = sumBy(bucketDetails.data, 'duration')
    let totalIncomeReceived = 0
    bucketDetails.data.map((bucketDetail) => {
        totalIncomeReceived +=
            bucketDetail.duration * 12 * bucketDetail.inflationAdjusted
    })
    const startingWithdrawalRate = numeral(0.01).format('0.000%')

    return (
        <>
            <table className="table table-striped table-condensed">
                <tbody>
                    <tr>
                        <td>Total Required</td>
                        <td className="currency">{totalRequired}</td>
                    </tr>
                    <tr>
                        <td>Ending Balance</td>
                        <td className="currency">{totalReturned}</td>
                    </tr>
                    <tr>
                        <td>Years of Income</td>
                        <td className="currency">{yearsOfIncome}</td>
                    </tr>
                    <tr>
                        <td>Total Income Received</td>
                        <td className="currency">
                            {numeral(totalIncomeReceived).format('$0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td>Starting Withdrawal Rate</td>
                        <td className="currency">{startingWithdrawalRate}</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

BucketSummary.displayName = 'BucketSummary'

export default BucketSummary
