import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    clearIllustration,
    requestCreateIllustration,
    requestClients,
    requestFileParse,
    clearParseIllustration,
} from '../actions'
import ParseFileForm from '../components/illustration/parseFile'
import { Spin } from 'antd'
import { useParams } from 'react-router-dom'

const IllustrationAddPage = (props) => {
    const params = useParams()

    useEffect(() => {
        props.clearIllustration()
        props.requestClients()
        return () => {
            props.clearIllustration()
        }
    }, [])

    if (!props.clients.data) return <Spin />
    return <ParseFileForm clientId={params.clientId} {...props} />
}

const mapStateToProps = (state) => {
    return {
        ...state.illustration,
        loading: false,
        clients: state.clients,
    }
}

export default connect(mapStateToProps, {
    clearIllustration,
    clearParseIllustration,
    requestClients,
    requestCreateIllustration,
    requestFileParse,
})(IllustrationAddPage)
