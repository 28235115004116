import React, { Component } from 'react'
import { Col, Row, InputNumber, Divider } from 'antd'

class OtherFactors extends Component {
    state = {
        years: this.props.numPremPay,
        inforce: this.props.insInforce,
    }

    render() {
        return (
            <div>
                <Divider
                    style={{
                        backgroundColor: '#000000',
                        height: 3,
                        margin: 0,
                        padding: 0,
                    }}
                />
                <Row
                    gutter={8}
                    style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: 'rgba(52, 52, 52, 0.02)',
                    }}
                >
                    <Col span={12} style={{ paddingLeft: 10 }}>
                        <b>Other Factors</b>
                    </Col>
                </Row>
                <Row gutter={8} style={{ paddingBottom: 10 }}>
                    <Divider
                        style={{
                            backgroundColor: '#e8e8e8',
                            height: 1,
                            margin: 0,
                            padding: 0,
                        }}
                    />
                </Row>
                <Row gutter={8} style={{ paddingBottom: 10 }}>
                    <Col span={12}>
                        Total Household Life Insurance Inforce amount (not
                        replacing)
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <InputNumber
                            style={{ width: 140 }}
                            defaultValue={this.props.insInforce}
                            onChange={(n) => {
                                this.setState({ inforce: n })
                                this.props.onChange(this.state.years, n)
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={8} style={{ paddingBottom: 10 }}>
                    <Col span={12}>Number of annual premium payments</Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <InputNumber
                            style={{ width: 140 }}
                            defaultValue={this.props.numPremPay}
                            onChange={(n) => {
                                this.setState({ years: n })
                                this.props.onChange(n, this.state.inforce)
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default OtherFactors
