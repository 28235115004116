import { Button, Card, Col, Row, Tabs } from 'antd'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Container from '../base/container'
import HouseholdData from './view/household_data'
import HypotheticalTaxFree from './view/hypothetical_tax_free'
import MaxBenefitAssetPlan from './view/max_benefit_asset_plan'
import ProposalActions from './view/proposal_actions'
import ProposalClientPanel from './view/proposal_client_panel'
import RequiredMinDist from './view/required_min_dist'
import TaxFreeIllustration from './view/tax_free_illustration'
import VsAssetLongevityNonQual from './view/vs_asset_longevity_non_qual'
import VsAssetLongevityQual from './view/vs_asset_longevity_qual'
import VsAssetLongevityRoth from './view/vs_asset_longevity_roth'
import VsIndexedAnnuity from './view/vs_indexed_annuity'

const TabPane = Tabs.TabPane

const ProposalView = (props) => {
    const {
        data,
        auth,
        loading,
        error,
        downloadPDF,
        downloadPDFv2,
        downloadStaticPDF,
        requestProposalLinkByEmail,
        proposalEmails,
        pdfs,
    } = props
    const { proposalId } = useParams()
    if (error) return <div>Error {error || 'oops...'}</div>
    return (
        <Container>
            <Card
                title={
                    <Row>
                        <Col span={8}>
                            <Link to="/proposals" className="pull-left">
                                <Button icon="arrow-left">Proposals</Button>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <div style={{ textAlign: 'center' }}>
                                Proposal View
                            </div>
                        </Col>
                        <Col span={8}>
                            <Link
                                to={`/proposals/edit/${proposalId}`}
                                className="pull-right"
                            >
                                <Button type="primary" icon="edit">
                                    Edit
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                }
            >
                <ProposalClientPanel {...data} loading={loading} />
                <ProposalActions
                    {...data}
                    emails={proposalEmails}
                    pdfs={pdfs}
                    auth={auth}
                    downloadPDF={downloadPDF}
                    downloadPDFv2={downloadPDFv2}
                    downloadStaticPDF={downloadStaticPDF}
                    requestProposalLinkByEmail={requestProposalLinkByEmail}
                    loading={loading}
                />
                <Tabs
                    defaultActiveKey={'1'}
                    id="proposal-view"
                    size={'small'}
                    type="card"
                >
                    <TabPane key={1} tab="Household Data">
                        <HouseholdData {...data} loading={loading} />
                    </TabPane>
                    <TabPane
                        key={2}
                        tab="Hypothetical Tax-Free Illustration"
                        disabled={loading}
                    >
                        {!loading && <HypotheticalTaxFree {...data} />}
                    </TabPane>
                    {!loading && data.illustration.qualified && (
                        <TabPane
                            key={'3'}
                            tab="Max. Benefit Tax Free Asset Plan"
                            disabled={loading}
                        >
                            {!loading && <MaxBenefitAssetPlan {...data} />}
                        </TabPane>
                    )}
                    <TabPane
                        key={4}
                        tab="Alt. Tax-Free Illustrations - Income"
                        disabled={loading}
                    >
                        {!loading && (
                            <TaxFreeIllustration
                                {...data}
                                planType={'Income'}
                                file="incomeFile"
                            />
                        )}
                    </TabPane>
                    <TabPane
                        key={5}
                        tab="Alt. Tax-Free Illustrations - Accumulation"
                        disabled={loading}
                    >
                        {!loading && (
                            <TaxFreeIllustration
                                {...data}
                                planType={'Accumulation'}
                                file="accumulationFile"
                            />
                        )}
                    </TabPane>
                    <TabPane
                        key={6}
                        tab="vs. Indexed Annuity"
                        disabled={loading}
                    >
                        {!loading && <VsIndexedAnnuity {...data} />}
                    </TabPane>
                    {!loading && data.illustration.qualified && (
                        <TabPane
                            key={7}
                            tab="vs. Asset Longevity - Qualified"
                            disabled={loading}
                        >
                            {!loading && <VsAssetLongevityQual {...data} />}
                        </TabPane>
                    )}
                    {!loading && !data.illustration.qualified && (
                        <TabPane
                            key={8}
                            tab="vs. Asset Longevity - Non-Qualified"
                            disabled={loading}
                        >
                            {!loading && <VsAssetLongevityNonQual {...data} />}
                        </TabPane>
                    )}
                    {!loading && data.illustration.qualified && (
                        <TabPane
                            key={9}
                            tab="vs. Asset Longevity - Roth"
                            disabled={loading}
                        >
                            {!loading && <VsAssetLongevityRoth {...data} />}
                        </TabPane>
                    )}
                    {!loading && data.illustration.qualified && (
                        <TabPane
                            key={10}
                            tab="Required Min. Distribution"
                            disabled={loading}
                        >
                            {!loading && <RequiredMinDist {...data} />}
                        </TabPane>
                    )}
                </Tabs>
                <div className="row">
                    <div
                        className="col-md-12 text-center"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <div className="disclaimer-800">
                            <p>
                                Hypothetical example is for illustrative
                                purposes only. This is not a prediction or
                                guarantee of actual results. Actual results will
                                vary from those described and should be
                                accompanied by a carrier illustration.
                            </p>
                            <p>
                                ARIA Financial Software, LLC does not provide
                                tax, legal or accounting advice, nor is it
                                designed—nor intended—to be applicable to any
                                person’s individual circumstances. This material
                                has been prepared for information purposes only,
                                and is not intended to provide, and should not
                                be relied on for, tax, legal or accounting
                                advice. You should consult your own qualified
                                tax, legal and accounting advisors before
                                engaging in any transaction.
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
        </Container>
    )
}

export default ProposalView
