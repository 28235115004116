/*
 * Vinod <vmohanan@coretechs.com>
 * Thu Aug 16 2019
 */
import { Card, Col, Form, Icon, InputNumber, Row, Statistic, Table } from 'antd'
import numeral from 'numeral'
import React from 'react'
import './style.css'

const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

class EditableCell extends React.Component {
    state = {
        editing: true,
    }

    toggleEdit = () => {
        const editing = !this.state.editing
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus()
            }
        })
    }

    save = (e) => {
        const { record, handleSave } = this.props
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return
            }
            // this.toggleEdit();
            console.debug(record)
            handleSave({ ...record, ...values })
        })
    }

    renderCell = (form) => {
        this.form = form
        const { children, dataIndex, record, title } = this.props
        const { editing } = this.state
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(
                    <InputNumber
                        formatter={(value) => `${(value * 100).toFixed(2)}%`}
                        parser={(value) => value.replace('%', '')}
                        step={0.0001}
                        ref={(node) => (this.input = node)}
                        onChange={this.save}
                        onBlur={this.save}
                    />
                )}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={this.toggleEdit}
            >
                {children}
            </div>
        )
    }

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>
                        {this.renderCell}
                    </EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        )
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                title: 'Year',
                key: 'arbitrageYear',
                dataIndex: 'arbitrageYear',
            },
            {
                title: "Moody's Avg. Bond Rate",
                key: 'moodysRate',
                dataIndex: 'moodysRate',
                editable: true,
                align: 'right',
            },
            {
                title: 'S&P Return - Historical Caps',
                key: 'spReturn',
                dataIndex: 'spReturn',
                editable: true,
                align: 'right',
            },
            {
                title: 'Annual Arbitrage',
                key: 'arbitrage',
                dataIndex: 'arbitrage',
                align: 'right',
                render: (text, record) =>
                    ((record.spReturn - record.moodysRate) * 100).toFixed(2) +
                    '%',
            },
            {
                title: 'Loan Amount',
                key: 'loanAmount',
                dataIndex: 'loanAmount',
                align: 'right',
                render: (text, record) =>
                    this.state.loanAmount
                        ? '$ ' + numeral(this.state.loanAmount).format('0,0')
                        : '...',
            },
            {
                title: 'Profit/Charge',
                key: 'profit_loss',
                dataIndex: 'profit_loss',
                align: 'right',
                render: (text, record) => {
                    let value = (
                        this.state.loanAmount *
                        (record.spReturn - record.moodysRate)
                    ).toFixed(2)
                    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                },
            },
        ]

        this.state = {
            dataSource: this.props.dataSource || [],
            count: this.props.dataSource ? this.props.dataSource.length : 0,
            loanAmount: this.props.loanAmount || 0,
            avgArbitrage: 0,
            totalProfitCharge: 0,
        }
    }

    _calculateTotal = () => {
        let avgArbitrage = 0
        let totalProfitCharge = 0
        const { loanAmount, dataSource } = this.state
        dataSource.forEach((row) => {
            const arbitrage = row.spReturn - row.moodysRate
            avgArbitrage += arbitrage
            totalProfitCharge += loanAmount * arbitrage
        })
        avgArbitrage = avgArbitrage / dataSource.length
        this.setState({ avgArbitrage, totalProfitCharge })
    }

    componentDidUpdate(pProps, pState) {
        if (pProps !== this.props) {
            this.setState(
                {
                    dataSource: this.props.dataSource || [],
                    count: this.props.dataSource
                        ? this.props.dataSource.length
                        : 0,
                    loanAmount: this.props.loanAmount,
                },
                () => this._calculateTotal()
            )
        }
    }

    handleSave = (row) => {
        const newData = [...this.state.dataSource]
        const index = newData.findIndex(
            (item) => row.arbitrageYear === item.arbitrageYear
        )
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row,
        })
        this.setState({ dataSource: newData }, () => {
            this._calculateTotal()
        })
    }

    render() {
        const { dataSource } = this.state
        const { loading } = this.props
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        }
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            }
        })
        return (
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered={false}
                className="arbitrage"
                size="medium"
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                pagination={false}
                footer={() => (
                    <div style={{ background: '', paddingBottom: '20px' }}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title="Average Annual Arbitrage"
                                        value={Math.abs(
                                            this.state.avgArbitrage * 100
                                        )}
                                        precision={2}
                                        valueStyle={{
                                            color:
                                                this.state.avgArbitrage > 0
                                                    ? '#3f8600'
                                                    : '#cf1322',
                                        }}
                                        prefix={
                                            <Icon
                                                type={
                                                    this.state.avgArbitrage > 0
                                                        ? 'plus'
                                                        : 'minus'
                                                }
                                            />
                                        }
                                        suffix="%"
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title="Total Profit Charge"
                                        value={Math.abs(
                                            this.state.totalProfitCharge
                                        )}
                                        precision={2}
                                        valueStyle={{
                                            color:
                                                this.state.totalProfitCharge > 0
                                                    ? '#3f8600'
                                                    : '#cf1322',
                                        }}
                                        prefix={
                                            <Icon
                                                type={
                                                    this.state
                                                        .totalProfitCharge > 0
                                                        ? 'plus'
                                                        : 'minus'
                                                }
                                            />
                                        }
                                        suffix="$"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
            />
        )
    }
}

export default EditableTable
